import styled from "@emotion/styled";
import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import GuardLayout from "../../layouts/Guard";
import Page403 from "../../pages/auth/Page403";
import Loader from "../Loader";

interface AuthGuardType {
  children: React.ReactNode;
}

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { isAdmin, isAuthenticated, isInitialized } = useAuth();

  // Show 'Loading' state
  if (!isInitialized) {
    return (
      <GuardLayout>
        <Wrapper>
          <Loader />
        </Wrapper>
      </GuardLayout>
    );
  }

  // Redirect to sign-in
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Show 'Forbidden' error
  if (!isAdmin) {
    return (
      <GuardLayout>
        <Page403 />
      </GuardLayout>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
