import { FormControlLabel, Switch } from "@mui/material";
import {
  arrayRemove,
  arrayUnion,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Vehicle, VehicleCapability } from "../../types/vehicle.db";

function CapabilitiesCard({
  vehicle,
}: {
  vehicle: WithDocRef<Vehicle>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [capabilities, setCapabilities] = useState(vehicle.capabilities);

  useEffect(() => {
    setCapabilities(vehicle.capabilities);
  }, [vehicle]);

  const updateCapability = (
    capability: VehicleCapability,
    newValue: boolean
  ) => {
    let updated: string[];

    if (newValue) {
      updated = [...capabilities, capability];
    } else {
      updated = capabilities.filter((value) => {
        if (capability === "SMARTCAR_CONNECT") {
          return !value.startsWith("SMARTCAR");
        }
        return value !== capability;
      });
    }

    setCapabilities(updated);
    updateDoc(vehicle.docRef, {
      capabilities: updated,
      "timestamps.updated_at": serverTimestamp(),
    })
      .then(() => {
        console.log("Document write successful.");
      })
      .catch((err: any) => {
        console.error(err);
        enqueueSnackbar("Failed to update capabilities", {
          variant: "error",
        });
      });
  };

  return (
    <PartitionedCard title={"Capabilities"} spacing={4}>
      <PartitionedCardItem title={"Plug and Charge"} alignItems={"center"}>
        <FormControlLabel
          control={
            <Switch
              checked={capabilities.includes("PLUG_AND_CHARGE")}
              onChange={(event) => {
                updateCapability("PLUG_AND_CHARGE", event.target.checked);
              }}
            />
          }
          label={
            capabilities.includes("PLUG_AND_CHARGE") ? "Enabled" : "Disabled"
          }
        />
      </PartitionedCardItem>

      <PartitionedCardItem
        title={"Smartcar"}
        caption={"Connect"}
        alignItems={"center"}
      >
        <FormControlLabel
          control={
            <Switch
              checked={capabilities.includes("SMARTCAR_CONNECT")}
              onChange={(event) => {
                updateCapability("SMARTCAR_CONNECT", event.target.checked);
              }}
            />
          }
          label={
            capabilities.includes("SMARTCAR_CONNECT") ? "Enabled" : "Disabled"
          }
        />
      </PartitionedCardItem>

      <PartitionedCardItem
        title={"Smartcar"}
        caption={"Control Charge"}
        alignItems={"center"}
      >
        <FormControlLabel
          control={
            <Switch
              checked={capabilities.includes("SMARTCAR_CONTROL_CHARGE")}
              onChange={(event) => {
                updateCapability(
                  "SMARTCAR_CONTROL_CHARGE",
                  event.target.checked
                );
              }}
              disabled={!capabilities.includes("SMARTCAR_CONNECT")}
            />
          }
          label={
            capabilities.includes("SMARTCAR_CONTROL_CHARGE")
              ? "Enabled"
              : "Disabled"
          }
        />
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default CapabilitiesCard;
