import { FormControlLabel, Switch } from "@mui/material";
import { serverTimestamp, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { User } from "../../types/user.db";

function SettingsCard({ user }: { user: WithDocRef<User> }): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const updateAutoApproveStationPhotos = (newValue: boolean) => {
    updateDoc(user.docRef, {
      auto_approve_station_photos: newValue,
      "timestamps.updated_at": serverTimestamp(),
    })
      .then(() => {
        console.log("Document write successful.");
      })
      .catch((err: any) => {
        console.error(err);
        enqueueSnackbar("Failed to update station photos setting", {
          variant: "error",
        });
      });
  };

  const updateAutoApproveStationReviews = (newValue: boolean) => {
    updateDoc(user.docRef, {
      auto_approve_station_reviews: newValue,
      "timestamps.updated_at": serverTimestamp(),
    })
      .then(() => {
        console.log("Document write successful.");
      })
      .catch((err: any) => {
        console.error(err);
        enqueueSnackbar("Failed to update station reviews setting", {
          variant: "error",
        });
      });
  };

  return (
    <PartitionedCard title={"Settings"} spacing={4}>
      <PartitionedCardItem title={"Auto approve photos"} alignItems={"center"}>
        <FormControlLabel
          control={
            <Switch
              checked={user.auto_approve_station_photos === true}
              onChange={(event) => {
                updateAutoApproveStationPhotos(event.target.checked);
              }}
            />
          }
          label={
            user.auto_approve_station_photos === true ? "Enabled" : "Disabled"
          }
        />
      </PartitionedCardItem>

      <PartitionedCardItem title={"Auto approve reviews"} alignItems={"center"}>
        <FormControlLabel
          control={
            <Switch
              checked={user.auto_approve_station_reviews === true}
              onChange={(event) => {
                updateAutoApproveStationReviews(event.target.checked);
              }}
            />
          }
          label={
            user.auto_approve_station_reviews === true ? "Enabled" : "Disabled"
          }
        />
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default SettingsCard;
