import styled from "@emotion/styled";
import React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import GuardLayout from "../../layouts/Guard";
import Loader from "../Loader";

interface GuestGuardType {
  children: React.ReactNode;
}

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

// For routes that can only be accessed by unauthenticated users
function GuestGuard({ children }: GuestGuardType) {
  const { isAuthenticated, isInitialized } = useAuth();

  // Show 'Loading' state
  if (!isInitialized) {
    return (
      <GuardLayout>
        <Wrapper>
          <Loader />
        </Wrapper>
      </GuardLayout>
    );
  }

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default GuestGuard;
