import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import type { ReactNode } from "react";
import { createContext, useEffect, useState } from "react";

import useFirebase from "../hooks/useFirebase";

type AuthUser = {
  readonly id: string;
  readonly displayName: string | null;
  readonly email: string | null;
  readonly emailVerified: boolean;
  readonly photoUrl: string | null;
  readonly provider: string;
  readonly creationTime?: string;
  readonly lastSignInTime?: string;
} | null;

type AuthState = {
  isAdmin: boolean;
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: AuthUser;
  searchApiKey: string | null;
};

const initialState: AuthState = {
  isAdmin: false,
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  searchApiKey: null,
};

const noop = async () => {
  // do nothing
};

function signOut() {
  return getAuth().signOut();
}

const AuthContext = createContext({
  ...initialState,
  signOut: noop,
});

function AuthProvider({ children }: { children: ReactNode }) {
  const { auth, database: db } = useFirebase();
  const [state, setState] = useState<AuthState>(initialState);

  useEffect(() => {
    return onAuthStateChanged(auth, async (user) => {
      console.info("Auth state changed.");

      if (!user) {
        setState({ ...initialState, isInitialized: true });
      } else {
        const authUser = {
          id: user.uid,
          displayName: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          photoUrl: user.photoURL,
          provider: user.providerData[0]?.providerId || user.providerId,
          creationTime: user.metadata.creationTime,
          lastSignInTime: user.metadata.lastSignInTime,
        };

        try {
          const docRef = doc(db, "admins", user.uid);
          const snapshot = await getDoc(docRef);

          // Check to see if this user has been granted the admin role.
          if (!snapshot.exists()) {
            throw new Error("Missing admin role.");
          } else {
            setState({
              isAdmin: true,
              isAuthenticated: true,
              isInitialized: true,
              user: authUser,
              searchApiKey: snapshot.data()?.search_api_key ?? null,
            });
          }
        } catch (err) {
          console.error(err);
          setState({
            ...initialState,
            isAuthenticated: true,
            isInitialized: true,
            user: authUser,
          });
        }
      }
    });
  }, [auth, db]);

  useEffect(() => {
    const userId = state.user?.id;

    if (userId) {
      const docRef = doc(db, "admins", userId);

      const onPermissionsChanged = ({ isAdmin }: { isAdmin: boolean }) => {
        if (state.isAdmin !== isAdmin) {
          setState({ ...state, isAdmin });
        }
      };

      return onSnapshot(
        docRef,
        (snapshot) => {
          console.log("Admin permissions changed.");
          onPermissionsChanged({ isAdmin: snapshot.exists() });
        },
        (error) => {
          console.log("Admin permissions missing or revoked.");
          onPermissionsChanged({ isAdmin: false });
        }
      );
    }
  }, [state, db]);

  // Assertions
  if (state.isAuthenticated && !state.user) {
    console.error("Auth succeeded but user object is null!", state);
  }

  return (
    <AuthContext.Provider value={{ ...state, signOut }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
