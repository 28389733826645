import React from "react";

import AuthGuard from "./components/guards/AuthGuard";
import GuestGuard from "./components/guards/GuestGuard";
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import Account from "./pages/account";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import BeaconProfile from "./pages/BeaconProfile";
import Beacons from "./pages/Beacons";
import ChargerProfile from "./pages/ChargerProfile";
import Chargers from "./pages/Chargers";
import Dealers from "./pages/Dealers";
import ElectricRates from "./pages/ElectricRates";
import FuelPrices from "./pages/FuelPrices";
import Home from "./pages/Home";
import IncentiveProfile from "./pages/IncentiveProfile";
import Incentives from "./pages/Incentives";
import NetworkProfile from "./pages/NetworkProfile";
import Networks from "./pages/Networks";
import PhotoProfile from "./pages/PhotoProfile";
import Photos from "./pages/Photos";
import PromotionProfile from "./pages/PromotionProfile";
import Promotions from "./pages/Promotions";
import ReviewProfile from "./pages/ReviewProfile";
import Reviews from "./pages/Reviews";
import SponsorProfile from "./pages/SponsorProfile";
import Sponsors from "./pages/Sponsors";
import StationProfile from "./pages/StationProfile";
import Stations from "./pages/Stations";
import Subscriptions from "./pages/Subscriptions";
import TrackedLinks from "./pages/TrackedLinks";
import UserProfile from "./pages/UserProfile";
import Users from "./pages/Users";
import Utilities from "./pages/Utilities";
import UtilityProfile from "./pages/UtilityProfile";
import VehicleProfile from "./pages/VehicleProfile";
import Vehicles from "./pages/Vehicles";

/** Note: Sidebar nav items are set in navItems.tsx. */

const main = [
  {
    path: "networks",
    children: [
      {
        index: true,
        element: <Networks />,
      },
      {
        path: ":networkId",
        element: <NetworkProfile />,
      },
    ],
  },
  {
    path: "photos",
    children: [
      {
        index: true,
        element: <Photos />,
      },
      {
        path: ":docId",
        element: <PhotoProfile />,
      },
    ],
  },
  {
    path: "reviews",
    children: [
      {
        index: true,
        element: <Reviews />,
      },
      {
        path: ":docId",
        element: <ReviewProfile />,
      },
    ],
  },
  {
    path: "stations",
    children: [
      {
        index: true,
        element: <Stations />,
      },
      {
        path: ":docId",
        element: <StationProfile />,
      },
    ],
  },
  {
    path: "subscriptions",
    children: [
      {
        index: true,
        element: <Subscriptions />,
      },
      {
        path: ":docId",
        element: <UserProfile />,
      },
    ],
  },
  {
    path: "users",
    children: [
      {
        index: true,
        element: <Users />,
      },
      {
        path: ":docId",
        element: <UserProfile />,
      },
    ],
  },
  {
    path: "vehicles",
    children: [
      {
        index: true,
        element: <Vehicles />,
      },
      {
        path: ":vehicleId",
        element: <VehicleProfile />,
      },
    ],
  },
];

const beacons = [
  {
    path: "beacons",
    children: [
      {
        index: true,
        element: <Beacons />,
      },
      {
        path: ":beaconId",
        element: <BeaconProfile />,
      },
    ],
  },
  {
    path: "chargers",
    children: [
      {
        index: true,
        element: <Chargers />,
      },
      {
        path: ":chargerId",
        element: <ChargerProfile />,
      },
    ],
  },
  {
    path: "incentives",
    children: [
      {
        index: true,
        element: <Incentives />,
      },
      {
        path: ":incentiveId",
        element: <IncentiveProfile />,
      },
    ],
  },
  {
    path: "rates/electric-rates",
    element: <ElectricRates />,
  },
  {
    path: "rates/fuel-prices",
    element: <FuelPrices />,
  },
  {
    path: "sponsors",
    children: [
      {
        index: true,
        element: <Sponsors />,
      },
      {
        path: ":sponsorId",
        element: <SponsorProfile />,
      },
    ],
  },
  {
    path: "utilities",
    children: [
      {
        index: true,
        element: <Utilities />,
      },
      {
        path: ":utilityId",
        element: <UtilityProfile />,
      },
    ],
  },
];

const other = [
  {
    path: "dealers",
    element: <Dealers />,
  },
  {
    path: "promotions",
    children: [
      {
        index: true,
        element: <Promotions />,
      },
      {
        path: ":docId",
        element: <PromotionProfile />,
      },
    ],
  },
  {
    path: "tracked-links",
    element: <TrackedLinks />,
  },
];

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "account",
        element: <Account />,
      },
      ...main,
      ...beacons,
      ...other,
    ],
  },
  {
    path: "/login",
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: (
          <GuestGuard>
            <SignIn />
          </GuestGuard>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
