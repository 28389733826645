import { deleteField, updateDoc } from "firebase/firestore";

import type { WithDocRef } from "../../../hooks/useQuery";
import type { Station } from "../../../types/station.db";
import { updatedAt } from "../../../util/firebase";

/**
 * Remove connectors from the given station.
 *
 * @param station
 * @param selected The connector ids to remove.
 */
const removeStationConnectors = async ({
  station,
  selected,
}: {
  station: WithDocRef<Station>;
  selected: string[];
}) => {
  const docData: any = {};

  for (const id of selected) {
    docData[`connectors.${id}`] = deleteField();
    docData[`connector_status.${id}`] = deleteField();
  }

  await updateDoc(station.docRef, {
    ...docData,
    ...updatedAt,
  });
};

export default removeStationConnectors;
