import styled from "@emotion/styled";
import { Drawer as MuiDrawer, ListItemButton } from "@mui/material";
import { darken, lighten } from "polished";
import React from "react";
import { NavLink } from "react-router-dom";

import type { SidebarItemsType } from "../../types/sidebar";

import SidebarAccount from "./SidebarAccount";
import SidebarNav from "./SidebarNav";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  .MuiPaper-root {
    border-right: ${(props) => props.theme.sidebar.border};
    background-color: ${(props) => props.theme.sidebar.background};
  }
`;

const Brand = styled(ListItemButton)<{
  component?: React.ReactNode;
  to?: string;
}>`
  padding: ${(props) => props.theme.spacing(6)};
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.footer.color};
  font-family: ${(props) => props.theme.typography.fontFamily};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  &:hover {
    background-color: ${(props) =>
      props.theme.palette.mode === "dark"
        ? lighten(0.03, props.theme.sidebar.background)
        : darken(0.03, props.theme.sidebar.background)} !important;
  }
`;

const ChargewayLogo = styled.img`
  height: 40px;
`;

export type SidebarProps = {
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
  items: {
    title: string;
    pages: SidebarItemsType[];
  }[];
  showFooter?: boolean;
};

const Sidebar: React.FC<SidebarProps> = ({ items, ...rest }) => {
  return (
    <Drawer variant="permanent" {...rest}>
      <SidebarAccount />
      <SidebarNav items={items} />
      <Brand component={NavLink} to="/">
        <ChargewayLogo
          src="/static/img/chargeway/Chargeway_Logo_White.png"
          alt="Chargeway"
        />
      </Brand>
    </Drawer>
  );
};

export default Sidebar;
