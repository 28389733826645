import { LaunchRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Link,
  Typography,
} from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import { serverTimestamp, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";

import type { WithDocRef } from "../../hooks/useQuery";
import type { StationPhoto } from "../../types/station-photo.db";
import type { User } from "../../types/user.db";

type PhotoCardOptions = {
  showHeader: boolean;
  fixedHeight: boolean;
};

function PhotoCard({
  user,
  photo,
  href,
  target,
  options = {
    showHeader: true,
    fixedHeight: true,
  },
}: {
  user?: User | null;
  photo: WithDocRef<StationPhoto>;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  options?: PhotoCardOptions;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const [approving, setApproving] = useState(false);
  const [rejecting, setRejecting] = useState(false);

  const onApprove = useCallback(async () => {
    setApproving(true);
    try {
      await updateDoc(photo.docRef, {
        approved: true,
        needs_review: false,
        "timestamps.updated_at": serverTimestamp(),
      });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to approve station photo", { variant: "error" });
    } finally {
      setApproving(false);
    }
  }, [photo.docRef, enqueueSnackbar]);
  const onReject = useCallback(async () => {
    setRejecting(true);
    try {
      await updateDoc(photo.docRef, {
        approved: false,
        needs_review: false,
        "timestamps.updated_at": serverTimestamp(),
      });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to reject station photo", { variant: "error" });
    } finally {
      setRejecting(false);
    }
  }, [photo.docRef, enqueueSnackbar]);

  const createdAt = photo.timestamps.created_at.toDate();

  return (
    <Card sx={{ width: "100%" }}>
      <CardActionArea href={href || "/photos/" + photo.id} target={target}>
        {options.showHeader && (
          <CardHeader
            avatar={
              <Avatar
                alt={user?.name}
                src={user?.photo_url ?? ""}
                sx={{ width: 32, height: 32 }}
              />
            }
            title={
              user?.name ? (
                <Link href={"/users/" + user?.id} color={"inherit"}>
                  {user?.name}
                </Link>
              ) : (
                <>&nbsp;</>
              )
            }
            subheader={formatDistanceToNowStrict(createdAt, {
              addSuffix: true,
            })}
            titleTypographyProps={{ variant: "subtitle2", lineHeight: 1 }}
            subheaderTypographyProps={{
              variant: "caption",
              lineHeight: 1,
              marginTop: 1,
            }}
          />
        )}

        <CardMedia
          component="img"
          height={options.fixedHeight ? 300 : undefined}
          image={photo.photo_url}
        />

        <CardContent>
          <Typography
            variant={"body2"}
            color={"text.secondary"}
            noWrap={options.fixedHeight}
          >
            {photo.caption ? photo.caption : "No caption"}
          </Typography>
        </CardContent>
      </CardActionArea>

      <CardActions sx={{ justifyContent: "end" }}>
        <LoadingButton
          color={"info"}
          onClick={onReject}
          loading={rejecting}
          disabled={!(photo.approved || photo.needs_review)}
        >
          Reject
        </LoadingButton>
        <LoadingButton
          variant={"outlined"}
          onClick={onApprove}
          loading={approving}
          disabled={photo.approved}
        >
          {photo.approved ? "Published" : "Approve"}
        </LoadingButton>
      </CardActions>
    </Card>
  );
}

export default PhotoCard;
