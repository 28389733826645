import { Grid, Stack } from "@mui/material";
import React from "react";

import { PageHeader } from "../components/PageHeader";

import ActiveUserCountsCard from "./home/ActiveUserCountsCard";
import AppVersionsCard from "./home/AppVersionsCard";
import MediaCountsCard from "./home/MediaCountsCard";
import StationCountsCard from "./home/StationCountsCard";
import SubscriptionCountsCard from "./home/SubscriptionCountsCard";
import UserCountsCard from "./home/UserCountsCard";
import VehicleCountsCard from "./home/VehicleCountsCard";

function Home(): JSX.Element {
  return (
    <React.Fragment>
      <PageHeader title={"Home"} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6} xl={4}>
          <Stack spacing={6}>
            <StationCountsCard />
            <UserCountsCard />
            <ActiveUserCountsCard />
            <MediaCountsCard />
            <SubscriptionCountsCard />
            <VehicleCountsCard />
            <AppVersionsCard />
          </Stack>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Home;
