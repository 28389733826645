import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { FormEventHandler } from "react";

function FormDialog({
  title,
  open,
  onCancel,
  onSubmit,
  isSubmitting = false,
  readyToSubmit = true,
  submitButtonLabel = "Save",
  children,
}: {
  title: string;
  open: boolean;
  onCancel: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  isSubmitting?: boolean;
  readyToSubmit?: boolean;
  submitButtonLabel?: string;
  children: JSX.Element | JSX.Element[];
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      fullWidth
    >
      <form onSubmit={onSubmit}>
        <DialogTitle sx={{ padding: "24px 24px 12px" }}>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting || !readyToSubmit}>
            {submitButtonLabel}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default FormDialog;
