import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreIcon,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { collection, doc, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDebounce } from "use-debounce";

import { usdFormatter } from "../components/data-grid/columnTypes";
import QuickSearch from "../components/data-grid/QuickSearch";
import { DeleteDialog } from "../components/dialogs/DeleteDialog";
import { EditZipcodesDialog } from "../components/dialogs/EditZipcodesDialog";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import { StatCard, StatCardItem } from "../components/StatCard";
import ZipcodesCard from "../components/ZipcodesCard";
import useDoc from "../hooks/useDoc";
import useFirebase from "../hooks/useFirebase";
import type { WithDocRef } from "../hooks/useQuery";
import type { Incentive, IncentiveVehicle } from "../types/incentive.db";
import { compareVehicles } from "../util/comparators";
import { FirestoreMenuItem, updatedAt } from "../util/firebase";

import Page404 from "./auth/Page404";
import EditIncentiveDialog from "./incentives/EditIncentiveDialog";
import VehicleList from "./incentives/VehicleList";

function IncentiveMenuButton({
  incentive,
}: {
  incentive: WithDocRef<Incentive>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { database } = useFirebase();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);
  const collectionRef = collection(database, "vehicle-incentives");
  const docRef = doc(collectionRef, incentive.id);

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorEl ? "menu-incentive" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        size="medium"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="menu-incentive"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
      >
        <FirestoreMenuItem docRef={incentive.docRef} />
        <Divider />
        <MenuItem
          onClick={() => {
            setOpenEditDialog(true);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <EditIncentiveDialog
        open={openEditDialog}
        handleClose={() => setOpenEditDialog(false)}
        fullScreen={fullScreen}
        incentive={incentive}
      />
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        docRef={docRef}
        displayName={incentive.grantor + " " + incentive.name}
      />
    </React.Fragment>
  );
}

function IncentiveProfile(): JSX.Element {
  const { incentiveId } = useParams();
  const [incentive, loading] = useDoc<Incentive>(
    "vehicle-incentives",
    incentiveId!
  );
  const [vehicles, setVehicles] = useState<IncentiveVehicle[]>([]);
  const [filtered, setFiltered] = useState<IncentiveVehicle[]>(vehicles);
  const [search, setSearch] = useState<string>("");
  const [debounced] = useDebounce(search, 200);

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { database } = useFirebase();

  const [openEditIncentiveDialog, setOpenEditIncentiveDialog] = useState(false);
  const [openEditZipcodesDialog, setOpenEditZipcodesDialog] = useState(false);

  useEffect(() => {
    if (incentive) {
      const vehicles = incentive.criteria.vehicles
        ? Object.values(incentive.criteria.vehicles)
        : [];
      setVehicles(vehicles.sort(compareVehicles));
    }
  }, [incentive]);

  // Filter vehicles on quick search input
  useEffect(() => {
    if (debounced) {
      const regex = new RegExp(debounced, "i");
      setFiltered(
        vehicles.filter(
          (v) =>
            regex.test(v.make) ||
            regex.test(v.model) ||
            regex.test(v.trim ?? "")
        )
      );
    } else {
      setFiltered(vehicles);
    }
  }, [vehicles, debounced]);

  const toggleActive = useCallback(async () => {
    if (incentive) {
      const collectionRef = collection(database, "vehicle-incentives");
      const incentiveRef = doc(collectionRef, incentiveId);
      try {
        await updateDoc(incentiveRef, {
          active: !incentive.active,
          ...updatedAt,
        });
      } catch (err: any) {
        console.error(err);
        enqueueSnackbar("Failed to update incentive", {
          variant: "error",
        });
      }
    }
  }, [incentiveId, incentive, database, enqueueSnackbar]);

  const updateZipcodes = useCallback(
    async (zipcodes: string[]) => {
      const collectionRef = collection(database, "vehicle-incentives");
      const incentiveRef = doc(collectionRef, incentiveId);
      await updateDoc(incentiveRef, {
        "criteria.zipcodes": zipcodes,
        ...updatedAt,
      });
    },
    [incentiveId, database]
  );

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : incentive ? (
        <>
          <PageHeader
            overline={incentive.grantor}
            title={incentive.name}
            description={incentive.description}
            action={
              <>
                <FormControlLabel
                  label="Active"
                  checked={incentive.active}
                  control={
                    <Switch onChange={(event, checked) => toggleActive()} />
                  }
                />
                <IncentiveMenuButton incentive={incentive} />
              </>
            }
            href={incentive.details_url}
            sectionIsId={true}
          />

          <Grid container spacing={fullScreen ? 4 : 6}>
            <Grid item xs={12} lg={6}>
              <StatCard
                title="Details"
                action={
                  <Button
                    variant="text"
                    onClick={() => setOpenEditIncentiveDialog(true)}
                  >
                    Edit
                  </Button>
                }
              >
                <StatCardItem
                  value={usdFormatter.format(incentive.amount)}
                  caption={"Amount"}
                />
                {incentive.typical_amount && (
                  <StatCardItem
                    value={incentive.typical_amount}
                    caption={"Typical Amount"}
                  />
                )}
                <StatCardItem
                  value={incentive.grantor_type}
                  caption={"Grantor Type"}
                />
              </StatCard>
            </Grid>

            <Grid item xs={12} lg={6}>
              <ZipcodesCard
                zips={incentive.criteria?.zipcodes ?? []}
                subheader={
                  (incentive.criteria?.zipcodes ?? []).length === 0
                    ? "Visible in all regions unless a zipcode is added."
                    : ""
                }
                action={
                  <Button
                    variant="text"
                    onClick={() => setOpenEditZipcodesDialog(true)}
                  >
                    Edit
                  </Button>
                }
              />
              <EditZipcodesDialog
                open={openEditZipcodesDialog}
                handleClose={() => setOpenEditZipcodesDialog(false)}
                handleSave={updateZipcodes}
                fullScreen={fullScreen}
                zipcodes={incentive.criteria?.zipcodes ?? []}
              />
            </Grid>

            {vehicles && (
              <Grid item xs={12}>
                <QuickSearch value={search} onChange={setSearch} />
                <VehicleList {...{ vehicles: filtered, incentive }} />
              </Grid>
            )}
          </Grid>

          <EditIncentiveDialog
            open={openEditIncentiveDialog}
            handleClose={() => setOpenEditIncentiveDialog(false)}
            fullScreen={fullScreen}
            incentive={incentive}
          />
        </>
      ) : (
        <Page404 />
      )}
    </React.Fragment>
  );
}
export default IncentiveProfile;
