import type { DocumentReference, Firestore } from "firebase/firestore";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";

import type { TrackedUrl } from "../../types/tracked.db";

const createTrackedUrl = async ({
  hostname = "https://r.chargeway.net",
  database,
  destination_url,
  source,
  medium,
  campaign,
}: {
  hostname?: string;
  database: Firestore;
  destination_url: string;
  source: string;
  medium: string;
  campaign: string;
}): Promise<[string, DocumentReference]> => {
  const docRef = doc(collection(database, "tracked-urls"));
  const docData: Omit<TrackedUrl, "id"> = {
    tracked_url: [hostname, "to", docRef.id].join("/"),
    destination_url: destination_url.trim(),
    ctm: { source, medium, campaign },
    visits: 0,
    timestamps: { created_at: serverTimestamp() },
  };
  await setDoc(docRef, docData);
  return [docData.tracked_url, docRef];
};

export { createTrackedUrl };
