import {
  Card,
  Divider,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";

import { DeleteDialog } from "../../components/dialogs/DeleteDialog";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";

import MoveNrelDialog from "./MoveNrelDialog";
import MovePhotosReviewsDialog from "./MovePhotosReviewsDialog";

function ManageCard({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const [openMoveNrelDialog, setOpenMoveNrelDialog] = useState(false);
  const [openMovePhotosReviewsDialog, setOpenMovePhotosReviewsDialog] =
    useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  return (
    <>
      <Card>
        <List disablePadding>
          <ListItemButton
            onClick={() => setOpenMoveNrelDialog(true)}
            disabled={!station.nrel_id}
            sx={{ color: "primary.main" }}
          >
            <ListItemText primary={"Move NREL ID"} />
          </ListItemButton>

          <ListItemButton
            onClick={() => setOpenMovePhotosReviewsDialog(true)}
            disabled={!station.photo_count && !station.review_count}
            sx={{ color: "primary.main" }}
          >
            <ListItemText primary={"Move Photos & Reviews"} />
          </ListItemButton>

          <Divider component={"li"} />

          <ListItemButton
            onClick={() => setOpenDeleteDialog(true)}
            sx={{ color: "error.main" }}
          >
            <ListItemText primary={"Delete Station"} />
          </ListItemButton>
        </List>
      </Card>

      {openMoveNrelDialog && (
        <MoveNrelDialog
          open={true}
          handleClose={() => setOpenMoveNrelDialog(false)}
          station={station}
        />
      )}
      {openMovePhotosReviewsDialog && (
        <MovePhotosReviewsDialog
          open={true}
          handleClose={() => setOpenMovePhotosReviewsDialog(false)}
          station={station}
        />
      )}
      {openDeleteDialog && (
        <DeleteDialog
          open={true}
          handleClose={() => setOpenDeleteDialog(false)}
          docRef={station.docRef}
          displayName={station.name}
          text={
            "This will permanently delete this station and any associated photos or reviews."
          }
        />
      )}
    </>
  );
}

export default ManageCard;
