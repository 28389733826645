import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import {
  collection,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import useFirebase from "../../hooks/useFirebase";
import type { Vehicle } from "../../types/vehicle.db";
import { isNumber } from "../../util/stdlib";

function EditFuelIdDialog({
  open,
  handleClose,
  fullScreen,
  vehicle,
}: {
  open: boolean;
  handleClose: () => void;
  fullScreen: boolean;
  vehicle: Vehicle;
}): JSX.Element {
  const { database } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Reset the form each time the dialog is presented
  useEffect(() => {
    if (open) {
      reset({
        fuel_id: vehicle.fuel_vehicle_id || "",
      });
    }
  }, [open, reset, vehicle]);

  const onCancel = () => handleClose();
  const onSubmit = async (data: any) => {
    try {
      const collectionRef = collection(database, "vehicles");
      const docRef = doc(collectionRef, vehicle.id);
      await updateDoc(docRef, {
        fuel_vehicle_id: data.fuel_id || null,
        "timestamps.updated_at": serverTimestamp(),
      });
      enqueueSnackbar("Updated FUEL ID");
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update FUEL ID", { variant: "error" });
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ padding: "24px 24px 0" }}>Edit Vehicle</DialogTitle>
        <DialogContent>
          <Stack spacing={4} mt={6}>
            <Controller
              name="fuel_id"
              control={control}
              rules={{
                validate: (v) => v === "" || isNumber(v) || "Must be a number.",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoComplete={"off"}
                  autoFocus={true}
                  label="FUEL ID"
                  helperText={
                    error?.message ||
                    "Enter the FUEL ID or paste the full URL of the FUEL detail page."
                  }
                  error={Boolean(error)}
                  onChange={(event) => {
                    let value = event.target.value;
                    const prefix = "https://stock.fuelapi.com/vehicles/detail/";
                    if (value.startsWith(prefix)) {
                      value = value.replace(prefix, "");
                    }
                    field.onChange(value);
                  }}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
export default EditFuelIdDialog;
