import { LaunchRounded } from "@mui/icons-material";
import { Chip, Link, Stack, Tooltip, Typography } from "@mui/material";
import { format, formatDistanceToNowStrict } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import React from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { User } from "../../types/user.db";
import StatusChip from "../subscriptions/StatusChip";

function SubscriptionCard({ user }: { user: WithDocRef<User> }): JSX.Element {
  const subscription = user.subscription;
  if (!subscription) return <></>;

  // Note: Timestamps may be null due to pending writes of serverTimestamp.
  const createdAt = (
    user.timestamps.subscription_created_at as Timestamp
  )?.toDate();
  const expiredAt = (
    user.timestamps.subscription_expired_at as Timestamp
  )?.toDate();

  const playConsoleUrl = subscription.order_id
    ? `https://play.google.com/console/u/0/developers/6078350330731383492/orders/${subscription.order_id}`
    : null;

  return (
    <PartitionedCard title={"Subscription"} spacing={4}>
      <PartitionedCardItem title={"Billing"}>
        {subscription.transaction_id && (
          <Chip
            label={"Apple"}
            variant={"outlined"}
            color={"default"}
            size={"small"}
          />
        )}
        {subscription.order_id && (
          <Chip
            label={"Google"}
            variant={"outlined"}
            color={"default"}
            size={"small"}
          />
        )}
      </PartitionedCardItem>

      <PartitionedCardItem title={"Status"}>
        <StatusChip subscription={subscription} />
      </PartitionedCardItem>

      {subscription.transaction_id && (
        <PartitionedCardItem title={"Transaction ID"}>
          {subscription.transaction_id}
        </PartitionedCardItem>
      )}

      {subscription.order_id && (
        <PartitionedCardItem title={"Order ID"}>
          <Link href={playConsoleUrl ?? ""} target={"_blank"}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              <Typography>{subscription.order_id}</Typography>
              <LaunchRounded fontSize={"inherit"} />
            </Stack>
          </Link>
        </PartitionedCardItem>
      )}

      {subscription.environment !== "Production" && (
        <PartitionedCardItem title={"Environment"}>
          {subscription.environment}
        </PartitionedCardItem>
      )}

      <PartitionedCardItem title={"Subscribed"}>
        <Tooltip title={format(createdAt, "PPPPpp")} enterDelay={500}>
          <Typography>
            {formatDistanceToNowStrict(createdAt, { addSuffix: true })}
          </Typography>
        </Tooltip>
      </PartitionedCardItem>

      {expiredAt && (
        <PartitionedCardItem title={"Expired"}>
          <Tooltip title={format(expiredAt, "PPPPpp")} enterDelay={500}>
            <Typography>
              {formatDistanceToNowStrict(expiredAt, { addSuffix: true })}
            </Typography>
          </Tooltip>
        </PartitionedCardItem>
      )}
    </PartitionedCard>
  );
}

export default SubscriptionCard;
