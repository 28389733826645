import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import type { ReactNode } from "react";
import { createContext } from "react";

const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

const auth = getAuth(app);
auth.tenantId = process.env.REACT_APP_FIREBASE_TENANT_ID || null;

const database = getFirestore(app);
const storageDefault = getStorage(app);
const storagePublic = getStorage(
  app,
  process.env.REACT_APP_FIREBASE_PUBLIC_STORAGE_BUCKET
);

const initialState = {
  app,
  auth,
  database,
  storage: {
    default: storageDefault,
    public: storagePublic,
  },
};

const FirebaseContext = createContext(initialState);

function FirebaseProvider({ children }: { children: ReactNode }) {
  return (
    <FirebaseContext.Provider value={initialState}>
      {children}
    </FirebaseContext.Provider>
  );
}

export { FirebaseContext, FirebaseProvider };
