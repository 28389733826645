import { subDays } from "date-fns";
import {
  collection,
  getCountFromServer,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

import { StatCard, StatCardItem } from "../../components/StatCard";
import useLoading from "../../hooks/useLoading";

function ActiveUserCountsCard(): JSX.Element {
  const [total, setTotal] = useState<number | null>(null);
  const [last30, setLast30] = useState<number | null>();
  const [last14, setLast14] = useState<number | null>();
  const [loading, setLoading] = useLoading();

  const countActiveSince = async (startAt: Date) => {
    const db = getFirestore();
    const collectionRef = collection(db, "users");

    return getCountFromServer(
      query(
        collectionRef,
        where("timestamps.last_seen", ">=", startAt),
        where("is_anonymous", "==", false)
      )
    );
  };

  useEffect(() => {
    setLoading(true);

    getCountFromServer(
      query(
        collection(getFirestore(), "users"),
        where("timestamps.last_seen", "!=", null),
        where("is_anonymous", "==", false)
      )
    )
      .then((snapshot) => {
        setTotal(snapshot.data().count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    setLoading(true);

    const startAt = subDays(new Date(), 30);
    countActiveSince(startAt)
      .then((snapshot) => {
        setLast30(snapshot.data().count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    setLoading(true);

    const startAt = subDays(new Date(), 14);
    countActiveSince(startAt)
      .then((snapshot) => {
        setLast14(snapshot.data().count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  const formatter = new Intl.NumberFormat("en-US");

  return (
    <StatCard title={"Active Users"} loading={loading}>
      <StatCardItem
        value={total ? formatter.format(total) : 0}
        caption={"Total"}
      />
      <StatCardItem
        value={last30 ? formatter.format(last30) : 0}
        caption={"Last 30 days"}
      />
      <StatCardItem
        value={last14 ? formatter.format(last14) : 0}
        caption={"Last 14 days"}
      />
    </StatCard>
  );
}

export default ActiveUserCountsCard;
