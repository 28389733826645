import { CacheProvider } from "@emotion/react";
import { useMediaQuery, useTheme } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SnackbarProvider } from "notistack";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useRoutes } from "react-router-dom";

import "./i18n";

import useThemeName from "./hooks/useTheme";
import routes from "./routes";
import createTheme from "./theme";
import createEmotionCache from "./util/createEmotionCache";

const clientSideEmotionCache = createEmotionCache();

function App({ emotionCache = clientSideEmotionCache }) {
  const content = useRoutes(routes);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { theme: themeName } = useThemeName();

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet titleTemplate="%s | Chargeway" defaultTitle="Chargeway" />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={createTheme(themeName)}>
            <SnackbarProvider
              anchorOrigin={
                fullScreen
                  ? { vertical: "top", horizontal: "center" }
                  : { vertical: "bottom", horizontal: "right" }
              }
            >
              {content}
            </SnackbarProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
