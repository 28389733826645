import type { Connector } from "../types/station.db";

type VehicleStub = {
  year: number;
  make: string;
  model: string;
  trim?: string;
};

const compareVehicles = (a: VehicleStub, b: VehicleStub): number =>
  a.make.localeCompare(b.make) ||
  a.model.localeCompare(b.model) ||
  (a.trim ? a.trim.localeCompare(b.trim ?? "") : 0) ||
  (b.trim ? -b.trim.localeCompare(a.trim ?? "") : 0) ||
  b.year - a.year;

const compareConnectors = (a: Connector, b: Connector): number => {
  let result: number;

  result = a.color.localeCompare(b.color, "en-US");
  if (result !== 0) return result;

  result = a.name.localeCompare(b.name, "en-US");
  if (result !== 0) return result;

  result = a.level - b.level;
  if (result !== 0) return result;

  return a.id.localeCompare(b.id, "en-US");
};

export { compareVehicles, compareConnectors };
