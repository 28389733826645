import {
  Delete as DeleteIcon,
  MoreVert as MoreIcon,
  Star as StarIcon,
} from "@mui/icons-material";
import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { DeleteDialog } from "../components/dialogs/DeleteDialog";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import StationCard from "../components/StationCard";
import UserCard from "../components/UserCard";
import useDoc from "../hooks/useDoc";
import type { WithDocRef } from "../hooks/useQuery";
import type { StationPhoto } from "../types/station-photo.db";
import { FirestoreMenuItem } from "../util/firebase";

import Page404 from "./auth/Page404";
import GeneralCard from "./station-photos/GeneralCard";
import { MakeFeaturedDialog } from "./station-photos/MakeFeaturedDialog";
import PhotoCard from "./station-photos/PhotoCard";

function MoreOptionsMenu({
  photo,
}: {
  photo: WithDocRef<StationPhoto>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openMakeFeaturedDialog, setOpenMakeFeaturedDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  return (
    <React.Fragment>
      {fullScreen ? (
        <Button
          aria-owns={anchorEl ? "menu-photo" : undefined}
          aria-haspopup="true"
          variant={"outlined"}
          color={"primary"}
          size={"large"}
          fullWidth
          onClick={toggleMenu}
        >
          Menu
        </Button>
      ) : (
        <IconButton
          aria-owns={anchorEl ? "menu-photo" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="medium"
        >
          <MoreIcon />
        </IconButton>
      )}
      <Menu
        id="menu-photo"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
      >
        <FirestoreMenuItem docRef={photo.docRef} />
        <Divider />
        <MenuItem
          onClick={() => {
            setOpenMakeFeaturedDialog(true);
            closeMenu();
          }}
          disabled={photo.featured}
        >
          <ListItemIcon>
            <StarIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Make Featured</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      {openMakeFeaturedDialog && (
        <MakeFeaturedDialog
          open={true}
          handleClose={() => setOpenMakeFeaturedDialog(false)}
          photo={photo}
        />
      )}
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        docRef={photo.docRef}
        displayName={"Station Photo"}
      />
    </React.Fragment>
  );
}

function PhotoProfile(): JSX.Element {
  const { docId } = useParams();
  const [photo, loading] = useDoc<StationPhoto>("station-photos", docId!);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : photo ? (
        <>
          <PageHeader
            title={"Station Photo"}
            action={
              <>
                {photo.featured && photo.approved && (
                  <Chip
                    label={"Featured"}
                    variant={"outlined"}
                    color={"warning"}
                  />
                )}
                {!photo.featured && photo.approved && (
                  <Chip
                    label={"Published"}
                    variant={"outlined"}
                    color={"info"}
                  />
                )}
                <MoreOptionsMenu photo={photo} />
              </>
            }
            sectionIsId={true}
          />

          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} xl={4}>
              <Stack spacing={6}>
                <PhotoCard
                  photo={photo}
                  href={photo.photo_url}
                  target={"_blank"}
                  options={{
                    showHeader: false,
                    fixedHeight: false,
                  }}
                />
                <StationCard stationId={photo.station_id} />
                <UserCard userId={photo.user_id} />
              </Stack>
            </Grid>

            <Grid item xs={12} lg={6} xl={8}>
              <GeneralCard photo={photo} />
            </Grid>
          </Grid>
        </>
      ) : (
        <Page404 />
      )}
    </React.Fragment>
  );
}

export default PhotoProfile;
