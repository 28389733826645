import { Button, Tooltip, Typography } from "@mui/material";
import { format, formatDistanceToNowStrict } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import React, { useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Network } from "../../types/network.db";
import EditNetworkDialog from "./EditNetworkDialog";

function GeneralCard({
  network,
}: {
  network: WithDocRef<Network>;
}): JSX.Element {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const numberFormatter = Intl.NumberFormat("en-US");

  // Note: Timestamps may be null due to pending writes of serverTimestamp.
  const createdAt = (network.timestamps.created_at as Timestamp)?.toDate();
  const updatedAt = (network.timestamps.updated_at as Timestamp)?.toDate();

  return (
    <>
      <PartitionedCard
        title={"General"}
        action={
          <Button
            onClick={() => {
              setOpenEditDialog(true);
            }}
          >
            Edit
          </Button>
        }
        spacing={4}
      >
        <PartitionedCardItem title={"Name"}>{network.name}</PartitionedCardItem>

        <PartitionedCardItem title={"NREL ID"}>
          {network.nrel_id ?? "-"}
        </PartitionedCardItem>

        <PartitionedCardItem title={"Stations"}>
          {numberFormatter.format(network.station_count)}
        </PartitionedCardItem>

        <PartitionedCardItem title={"First Created"}>
          {createdAt ? (
            <Tooltip title={format(createdAt, "PPPPpp")} enterDelay={500}>
              <Typography>
                {formatDistanceToNowStrict(createdAt, { addSuffix: true })}
              </Typography>
            </Tooltip>
          ) : (
            <Typography>-</Typography>
          )}
        </PartitionedCardItem>

        <PartitionedCardItem title={"Last Updated"}>
          {updatedAt ? (
            <Tooltip title={format(updatedAt, "PPPPpp")} enterDelay={500}>
              <Typography>
                {formatDistanceToNowStrict(updatedAt, { addSuffix: true })}
              </Typography>
            </Tooltip>
          ) : (
            <Typography>-</Typography>
          )}
        </PartitionedCardItem>
      </PartitionedCard>
      {openEditDialog && (
        <EditNetworkDialog
          open={true}
          handleClose={() => setOpenEditDialog(false)}
          network={network}
        />
      )}
    </>
  );
}

export default GeneralCard;
