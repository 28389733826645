import { Schedule as ScheduleIcon } from "@mui/icons-material";
import { Chip, Tooltip } from "@mui/material";
import {
  addDays,
  format,
  formatDistanceToNowStrict,
  isPast,
  isWithinInterval,
} from "date-fns";
import React from "react";

function ExpiresAt({
  value,
  size = "small",
}: {
  value: Date;
  size?: "small" | "medium";
}): JSX.Element {
  const now = new Date();
  const nowPlus30 = addDays(now, 30);
  const isWithinDays30 = isWithinInterval(value, {
    start: now,
    end: nowPlus30,
  });
  return (
    value && (
      <Tooltip title={format(value, "PPPP")} enterDelay={500}>
        {isPast(value) ? (
          <Chip
            size={size}
            color={"error"}
            variant={"outlined"}
            label={"Expired"}
            icon={<ScheduleIcon />}
          />
        ) : (
          <Chip
            size={size}
            color={isWithinDays30 ? "warning" : "default"}
            variant={"outlined"}
            label={formatDistanceToNowStrict(value, { unit: "day" })}
            icon={<ScheduleIcon />}
          />
        )}
      </Tooltip>
    )
  );
}

export default ExpiresAt;
