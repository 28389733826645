import { Typography } from "@mui/material";
import type {
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import React from "react";

import type { Hit } from "./types";

function highlightResultGetter<T extends Hit>(
  params: GridValueGetterParams<any, T>
) {
  return params.row._highlightResult?.[params.field]?.value ?? params.value;
}

function renderHighlightResult<T extends Hit>(
  params: GridRenderCellParams<any, T>
) {
  return (
    <Typography
      dangerouslySetInnerHTML={{
        __html: params.value ?? "-",
      }}
    ></Typography>
  );
}

export { highlightResultGetter, renderHighlightResult };
