import { FormControlLabel, Switch } from "@mui/material";
import { arrayRemove, arrayUnion, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Promotion } from "../../types/promotion.db";
import type { UserPersona } from "../../types/user.db";
import { updatedAt } from "../../util/firebase";

type PersonaOption = {
  title: string;
  caption: string;
  value: UserPersona;
};

const options: PersonaOption[] = [
  {
    title: "Persona A",
    caption: "Prefers to charge at home; has a dedicated charger",
    value: "PERSONA_A",
  },
  {
    title: "Persona B",
    caption: "Prefers to charge at home; may not have a dedicated charger",
    value: "PERSONA_B",
  },
  {
    title: "Persona C",
    caption: "Prefers to charge away from home",
    value: "PERSONA_C",
  },
  { title: "Persona D", caption: "Can't charge at home", value: "PERSONA_D" },
];

function ManagePromoPersonasCard({
  promotion,
}: {
  promotion: WithDocRef<Promotion>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const updatePersona = (value: UserPersona, enabled: boolean) => {
    updateDoc(promotion.docRef, {
      "criteria.personas": enabled ? arrayUnion(value) : arrayRemove(value),
      ...updatedAt,
    })
      .then(() => {
        console.log("Update completed.");
      })
      .catch((error: any) => {
        console.warn(error);
        enqueueSnackbar("Failed to update promotion", { variant: "error" });
      });
  };

  return (
    <PartitionedCard
      title={"Personas"}
      subheader={"Active for all personas unless one is enabled."}
      spacing={2}
    >
      {options.map((option) => {
        const enabled =
          promotion.criteria?.personas?.includes(option.value) ?? false;
        return (
          <PartitionedCardItem
            key={option.value}
            title={option.title}
            caption={option.caption}
            alignItems={"center"}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={enabled}
                  onChange={(event) => {
                    updatePersona(option.value, event.target.checked);
                  }}
                />
              }
              label={enabled ? "Enabled" : "Disabled"}
            />
          </PartitionedCardItem>
        );
      })}
    </PartitionedCard>
  );
}

export default ManagePromoPersonasCard;
