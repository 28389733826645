import { Check as CheckIcon } from "@mui/icons-material";
import { Card, CardHeader, Chip } from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { formatDistanceToNowStrict } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import { orderBy, where } from "firebase/firestore";
import React, { useMemo } from "react";

import { standardProps } from "../../components/data-grid";
import * as columnTypes from "../../components/data-grid/columnTypes";
import useCollection from "../../hooks/useCollection";
import useNavigateOnRowClick from "../../hooks/useNavigateOnRowClick";
import type { Beacon } from "../../types/beacon.db";
import type { Sponsor } from "../../types/sponsor.db";
import { appVersionRegex } from "../../util/stdlib";
import ExpiresAt from "../beacons/components/ExpiresAt";

function BeaconList({ sponsor }: { sponsor: Sponsor }): JSX.Element {
  const [items, loading] = useCollection<Beacon>(
    "beacons",
    where("sponsor.id", "==", sponsor.id),
    orderBy("name")
  );

  const handleRowClick = useNavigateOnRowClick("/beacons/:id");

  const columns = useMemo<GridColumns<Beacon>>(
    () => [
      {
        field: "name",
        headerName: "Name",
        ...columnTypes.flexDefault,
      },
      {
        field: "title",
        headerName: "Title",
        ...columnTypes.flexPrimary,
        minWidth: 350,
      },
      {
        field: "large_promo_url",
        headerName: "Large Promo",
        ...columnTypes.flexBoolean,
        valueGetter: (params) => !!params.value,
        renderCell: (params) => (params.value ? <CheckIcon /> : ""),
      },
      {
        field: "last_user_agent",
        headerName: "App Version",
        ...columnTypes.flexDefault,
        valueGetter: (params) => params.value?.match(appVersionRegex)[0] ?? "",
        renderCell: (params) =>
          params.value ? (
            <Chip variant={"outlined"} size={"small"} label={params.value} />
          ) : null,
      },
      {
        field: "expires_at",
        headerName: "Expiration",
        ...columnTypes.flexDateTime,
        valueGetter: (params) =>
          (params.row.timestamps.expires_at as Timestamp)?.toDate(),
        renderCell: (params) =>
          params.value ? <ExpiresAt value={params.value} /> : null,
      },
      {
        field: "last_seen",
        headerName: "Last seen",
        ...columnTypes.timestamp,
        valueGetter: (params) =>
          (params.row.timestamps.last_seen as Timestamp)?.toDate(),
        valueFormatter: (params) =>
          params.value
            ? formatDistanceToNowStrict(params.value, { addSuffix: true })
            : null,
      },
      {
        field: "updated_at",
        headerName: "Last updated",
        ...columnTypes.updatedAt,
      },
    ],
    []
  );

  return (
    <Card>
      <CardHeader title={"Beacons"} />
      <DataGrid
        rows={items}
        columns={columns}
        {...standardProps}
        pageSize={20}
        sx={{
          ".MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
        onRowClick={handleRowClick}
        loading={loading}
      />
    </Card>
  );
}

export default BeaconList;
