import { Link, Tooltip, Typography } from "@mui/material";
import { format, formatDistanceToNowStrict } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import { parsePhoneNumber } from "libphonenumber-js";
import React from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { User } from "../../types/user.db";
import { formatPostalCode } from "../../util/stdlib";

function GeneralCard({ user }: { user: WithDocRef<User> }): JSX.Element {
  const phoneNumber = user.phone ? parsePhoneNumber(user.phone, "US") : null;
  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${user?.postal_code}`;

  // Note: Timestamps may be null due to pending writes of serverTimestamp.
  const createdAt = (user.timestamps.created_at as Timestamp)?.toDate();
  const lastSeen = (user.timestamps.last_seen as Timestamp)?.toDate();

  const addressParts = [
    user?.state,
    formatPostalCode(user?.postal_code),
  ].filter((v) => !!v);

  const formatter = new Intl.NumberFormat("en-US");

  return (
    <PartitionedCard title={"General"} spacing={4}>
      <PartitionedCardItem title={"Phone"}>
        <Typography>{phoneNumber?.formatNational() ?? "-"}</Typography>
      </PartitionedCardItem>

      {user.email && (
        <PartitionedCardItem title={"Email"}>
          <Typography>
            {user.email ? (
              <Link href={"mailto:" + user.email}>{user.email}</Link>
            ) : (
              "-"
            )}
          </Typography>
        </PartitionedCardItem>
      )}

      <PartitionedCardItem title={"Address"}>
        {user.postal_code ? (
          <Link href={mapsUrl} target={"_blank"}>
            <Typography>
              {user?.postal_code ? addressParts.join(", ") : "-"}
            </Typography>
          </Link>
        ) : (
          <Typography>-</Typography>
        )}
      </PartitionedCardItem>

      {user.photo_count && (
        <PartitionedCardItem title={"Photos"}>
          <Typography>{formatter.format(user.photo_count ?? 0)}</Typography>
        </PartitionedCardItem>
      )}

      {user.review_count && (
        <PartitionedCardItem title={"Reviews"}>
          <Typography>{formatter.format(user.review_count ?? 0)}</Typography>
        </PartitionedCardItem>
      )}

      <PartitionedCardItem title={"Joined"}>
        {createdAt ? (
          <Tooltip title={format(createdAt, "PPPPpp")} enterDelay={500}>
            <Typography>
              {formatDistanceToNowStrict(createdAt, { addSuffix: true })}
            </Typography>
          </Tooltip>
        ) : (
          <Typography>-</Typography>
        )}
      </PartitionedCardItem>

      <PartitionedCardItem title={"Last seen"}>
        {lastSeen ? (
          <Tooltip title={format(lastSeen, "PPPPpp")} enterDelay={500}>
            <Typography>
              {formatDistanceToNowStrict(lastSeen, { addSuffix: true })}
            </Typography>
          </Tooltip>
        ) : (
          <Typography>-</Typography>
        )}
      </PartitionedCardItem>

      <PartitionedCardItem title={"App version"}>
        <Typography>{user.last_user_agent || "-"}</Typography>
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default GeneralCard;
