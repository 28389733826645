import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { isNumber } from "../../util/stdlib";

function EditRatesDialog({
  open,
  handleClose,
  handleSave,
  fullScreen,
  rates,
}: {
  open: boolean;
  handleClose: () => void;
  handleSave: (data: any) => Promise<void>;
  fullScreen: boolean;
  rates?: Array<number | undefined>;
}): JSX.Element {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  const initialPrimaryRate = rates?.[0] || null;
  const initialSecondaryRate = rates?.[1] || null;

  // Reset form each time the dialog is presented
  useEffect(() => {
    if (open) {
      reset({
        primary_rate: initialPrimaryRate ?? "",
        secondary_rate: initialSecondaryRate ?? "",
      });
    }
  }, [open, reset, initialPrimaryRate, initialSecondaryRate]);

  const onCancel = () => handleClose();
  const onSubmit = async (data: any) => {
    await handleSave(data);
    handleClose();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ padding: "24px 24px 0" }}>Edit rates</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={4} mt={6}>
            <Controller
              name="primary_rate"
              control={control}
              rules={{
                validate: (v) => !v || isNumber(v) || "Must be a number.",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Primary Rate"
                  helperText={error?.message || "In cents per kWh"}
                  error={Boolean(error)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">¢</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              )}
            />
            <Controller
              name="secondary_rate"
              control={control}
              rules={{
                validate: (v) => !v || isNumber(v) || "Must be a number.",
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Secondary Rate"
                  helperText={error?.message || "In cents per kWh"}
                  error={Boolean(error)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">¢</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export { EditRatesDialog };
