import {
  FirstPage as FirstPageIcon,
  NavigateBefore as PreviousIcon,
  NavigateNext as NextIcon,
  LastPage as LastPageIcon,
} from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import {
  useGridApiContext,
  useGridSelector,
  gridPageSelector,
  gridPageCountSelector,
  gridRowsLoadingSelector,
} from "@mui/x-data-grid";
import { useCallback, useEffect } from "react";

function StandardPagination(): JSX.Element {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const loading = useGridSelector(apiRef, gridRowsLoadingSelector);

  const firstPage = 0;
  const lastPage = Math.max(pageCount - 1, 0);

  const hideFirstPageButton = pageCount < 3;
  const hideLastPageButton = pageCount < 3;

  const goToFirst = useCallback(() => apiRef.current.setPage(0), [apiRef]);
  const goToPrev = useCallback(
    () => apiRef.current.setPage(Math.max(page - 1, firstPage)),
    [apiRef, page, firstPage]
  );
  const goToNext = useCallback(
    () => apiRef.current.setPage(Math.min(page + 1, lastPage)),
    [apiRef, page, lastPage]
  );
  const goToLast = useCallback(
    () => apiRef.current.setPage(lastPage),
    [apiRef, lastPage]
  );

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if ((event.target as HTMLElement).tagName === "BODY") {
        switch (event.key) {
          case "ArrowLeft":
            event.stopPropagation();
            goToPrev();
            break;
          case "ArrowRight":
            event.stopPropagation();
            goToNext();
            break;
        }
      }
    };
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [goToPrev, goToNext]);

  return (
    <Stack
      direction="row"
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "0 6px",
        justifyContent: "center",
        flexGrow: "1",
      }}
    >
      {!hideFirstPageButton && (
        <IconButton
          disabled={loading || page === firstPage}
          onClick={goToFirst}
        >
          <FirstPageIcon />
        </IconButton>
      )}
      <IconButton disabled={loading || page === firstPage} onClick={goToPrev}>
        <PreviousIcon />
      </IconButton>

      <Typography sx={{ flexGrow: "1", textAlign: "center" }}>
        {pageCount > 0 && `Page ${page + 1} of ${pageCount}`}
      </Typography>

      <IconButton disabled={loading || page === lastPage} onClick={goToNext}>
        <NextIcon />
      </IconButton>
      {!hideLastPageButton && (
        <IconButton disabled={loading || page === lastPage} onClick={goToLast}>
          <LastPageIcon />
        </IconButton>
      )}
    </Stack>
  );
}

export { StandardPagination };
