import { serverTimestamp, updateDoc } from "firebase/firestore";

import type { WithDocRef } from "../../../hooks/useQuery";
import type {
  Connector,
  ConnectorName,
  Station,
} from "../../../types/station.db";
import {
  calculateChargewayLevel,
  colorFromName,
  voltageFromLevel,
} from "../../../util/chargeway";
import { updatedAt } from "../../../util/firebase";

/**
 * Update connectors for the given station.
 */
const updateStationConnectors = async ({
  station,
  selected,
  label,
  name,
  power,
  offline,
}: {
  station: WithDocRef<Station>;
  selected: string[];
  label: string | null;
  name: ConnectorName;
  power: number;
  offline: boolean;
}) => {
  const powerType = name === "J1772" ? "AC" : "DC";
  const level = calculateChargewayLevel(power);
  const voltage = voltageFromLevel(level);
  const color = colorFromName(name);

  // Apply updates
  const connectors: Connector[] = [];
  for (const id of selected) {
    if (!station.connectors[id]) continue;

    const original = station.connectors[id];
    const patch = {
      ...(selected.length === 1 ? { label } : {}),
      name,
      power_type: powerType,
      power: power,
      voltage,
      amperage: null, // Unused
      color,
      level,
      offline,
    };
    connectors.push(Object.assign(original, patch));
  }

  const docData: any = {};
  for (const value of connectors) {
    docData[`connectors.${value.id}`] = value;
    docData[`connectors.${value.id}.timestamps.updated_at`] = serverTimestamp();
  }
  await updateDoc(station.docRef, {
    ...docData,
    ...updatedAt,
  });
};

export default updateStationConnectors;
