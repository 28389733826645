import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Launch as LaunchIcon,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import {
  collection as firestoreCollection,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { standardProps } from "../../components/data-grid";
import * as columnTypes from "../../components/data-grid/columnTypes";
import useFirebase from "../../hooks/useFirebase";
import type { WithDocRef } from "../../hooks/useQuery";
import useQuery from "../../hooks/useQuery";
import type { Beacon } from "../../types/beacon.db";
import type { Vehicle } from "../../types/vehicle.db";
import { updatedAt } from "../../util/firebase";

import AddVehicleDialog from "./AddVehicleDialog";
import VehicleColorSelect from "./components/VehicleColorSelect";

type UserVehicleStub = {
  id: string;
  parent: Vehicle;
  photo_url: string | null;
};

function RemoveVehicleDialog({
  open,
  handleClose,
  beacon,
  selected,
}: {
  open: boolean;
  handleClose: () => void;
  beacon: WithDocRef<Beacon>;
  selected: WithDocRef<UserVehicleStub>[];
}): JSX.Element {
  const { database } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const first = selected[0];

  const deleteVehicle = async () => {
    setIsSubmitting(true);
    const total = selected.length;

    try {
      let slice: WithDocRef<UserVehicleStub>[];
      while ((slice = selected.splice(0, 500)).length !== 0) {
        const batch = writeBatch(database);
        slice.forEach((v) => batch.delete(v.docRef));
        await batch.commit();
      }
      await updateDoc(beacon.docRef, { ...updatedAt });

      enqueueSnackbar(
        total > 1
          ? `Removed ${total} vehicles`
          : `Removed "${first?.parent.display_name}"`
      );
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(
        total > 1
          ? "Failed to remove vehicles"
          : `Failed to remove "${first?.parent.display_name}"`
      );
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {selected.length > 1
            ? `Delete ${selected.length} vehicles?`
            : `Delete "${first?.parent.display_name}"?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <LoadingButton onClick={deleteVehicle} loading={isSubmitting}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

const columns: GridColumns<WithDocRef<UserVehicleStub>> = [
  {
    field: "year",
    headerName: "Year",
    ...columnTypes.flexDefault,
    minWidth: 100,
    valueGetter: (params) => params.row.parent.year,
  },
  {
    field: "make",
    headerName: "Make",
    ...columnTypes.flexDefault,
    valueGetter: (params) => params.row.parent.make,
  },
  {
    field: "model",
    headerName: "Model",
    ...columnTypes.flexDefault,
    valueGetter: (params) => params.row.parent.model,
  },
  {
    field: "trim",
    headerName: "Trim",
    ...columnTypes.flexDefault,
    minWidth: 250,
    valueGetter: (params) => params.row.parent.trim,
  },
  {
    field: "fuel_type",
    headerName: "Type",
    ...columnTypes.flexDefault,
    minWidth: 100,
    valueGetter: (params) => params.row.parent.fuel_type,
    renderCell: (params) => (
      <Chip label={params.value} variant="outlined" size="small" />
    ),
  },
  {
    field: "color",
    headerName: "Color",
    ...columnTypes.flexDefault,
    minWidth: 250,
    valueGetter: (params) =>
      params.row.parent.trim_colors?.find((v) => v.url === params.row.photo_url)
        ?.url ?? null,
    renderCell: (params) =>
      params.value && (
        <VehicleColorSelect vehicle={params.row} value={params.value} />
      ),
  },
  {
    field: "actions",
    headerName: "",
    ...columnTypes.flexActions,
    renderCell: (params) => (
      <Button
        size={"small"}
        endIcon={<LaunchIcon />}
        onClick={() => {
          window.open(`/vehicles/${params.row.parent.id}`, "_blank");
        }}
      >
        View detail
      </Button>
    ),
  },
];

function VehicleList({ beacon }: { beacon: WithDocRef<Beacon> }): JSX.Element {
  const { database } = useFirebase();
  const collectionRef = firestoreCollection(database, "user-vehicles");
  const [vehicles, loading] = useQuery<UserVehicleStub>(
    query(
      collectionRef,
      where("user_id", "==", beacon.id),
      orderBy("parent.year", "desc"),
      orderBy("parent.make"),
      orderBy("parent.model"),
      orderBy("parent.trim")
    )
  );

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  const isEmpty = vehicles.length === 0;

  return (
    <Card>
      <CardHeader
        title="Vehicles"
        action={
          <Stack direction="row" spacing={2}>
            <Button
              startIcon={<AddIcon />}
              variant="text"
              onClick={() => setOpenAddDialog(true)}
            >
              Add
            </Button>
            <Button
              startIcon={<DeleteIcon />}
              variant="text"
              onClick={() => setOpenRemoveDialog(true)}
              disabled={selected.length === 0}
            >
              Remove
            </Button>
          </Stack>
        }
      />
      {!isEmpty && (
        <DataGrid
          rows={vehicles}
          columns={columns}
          {...standardProps}
          pageSize={20}
          sx={{
            ".MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          checkboxSelection
          disableSelectionOnClick={false}
          onSelectionModelChange={(selectionModel, details) =>
            setSelected(selectionModel as string[])
          }
          selectionModel={selected}
          loading={loading}
        />
      )}
      {openAddDialog && (
        <AddVehicleDialog
          open={true}
          handleClose={() => setOpenAddDialog(false)}
          beacon={beacon}
        />
      )}
      {openRemoveDialog && (
        <RemoveVehicleDialog
          open={true}
          handleClose={() => setOpenRemoveDialog(false)}
          beacon={beacon}
          selected={vehicles.filter((v) => selected.includes(v.id))}
        />
      )}
    </Card>
  );
}

export default VehicleList;
