import { Typography } from "@mui/material";
import { collection, getDocs, getFirestore, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import useLoading from "../../hooks/useLoading";
import type { VehicleMake } from "../../types/vehicle.db";

function VehicleCountsCard(): JSX.Element {
  const [total, setTotal] = useState<number | null>();
  const [makes, setMakes] = useState<VehicleMake[] | null>();
  const [loading, setLoading] = useLoading();
  const limit = 10;

  useEffect(() => {
    setLoading(true);

    const db = getFirestore();
    const collectionRef = collection(db, "vehicle-makes");
    getDocs(query(collectionRef))
      .then((snapshot) => {
        const makes = snapshot.docs.map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as VehicleMake)
        );

        // Sort
        makes
          .sort((a, b) => (a.vehicle_count ?? 0) - (b.vehicle_count ?? 0))
          .reverse();
        setMakes(makes.slice(0, limit));

        // Sum
        const count = makes.reduce(
          (n, make) => n + (make.vehicle_count ?? 0),
          0
        );
        setTotal(count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  const formatter = new Intl.NumberFormat("en-US");

  return (
    <PartitionedCard title={"Top Vehicles"} spacing={2} loading={loading}>
      {makes?.map((make, i) => {
        const title = `${i + 1}. ${make.name}`;
        const count = make.vehicle_count ?? 0;
        const percent = Math.round((count / (total ?? 0)) * 100);
        return (
          <PartitionedCardItem key={make.id} title={title}>
            <Typography variant={"subtitle2"}>
              {`${percent}% (${formatter.format(count)})`}
            </Typography>
          </PartitionedCardItem>
        );
      })}
    </PartitionedCard>
  );
}

export default VehicleCountsCard;
