import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreIcon,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Switch,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { DeleteDialog } from "../components/dialogs/DeleteDialog";
import { EditZipcodesDialog } from "../components/dialogs/EditZipcodesDialog";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import ZipcodesCard from "../components/ZipcodesCard";
import useDoc from "../hooks/useDoc";
import type { WithDocRef } from "../hooks/useQuery";
import type { Promotion } from "../types/promotion.db";
import { FirestoreMenuItem, updatedAt } from "../util/firebase";

import Page404 from "./auth/Page404";
import EditPromotionDialog from "./promotions/EditPromotionDialog";
import ManagePromoBackgroundCard from "./promotions/ManagePromoBackgroundCard";
import ManagePromoConnectorsCard from "./promotions/ManagePromoConnectorsCard";
import ManagePromoPersonasCard from "./promotions/ManagePromoPersonasCard";
import ManagePromoSponsorImageCard from "./promotions/ManagePromoSponsorImageCard";
import VehicleList from "./promotions/VehicleList";

function MoreOptionsMenu({
  promotion,
}: {
  promotion: WithDocRef<Promotion>;
}): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorEl ? "menu-promotion" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        size="medium"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="menu-promotion"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
      >
        <FirestoreMenuItem docRef={promotion.docRef} />
        <Divider />
        <MenuItem
          onClick={() => {
            setOpenEditDialog(true);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <EditPromotionDialog
        open={openEditDialog}
        handleClose={() => setOpenEditDialog(false)}
        promotion={promotion}
      />
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        docRef={promotion.docRef}
        displayName={promotion.name}
      />
    </React.Fragment>
  );
}

function PromotionProfile(): JSX.Element {
  const { docId } = useParams();
  const [promotion, loading] = useDoc<Promotion>("promotions", docId!);
  const [openEditZipcodesDialog, setOpenEditZipcodesDialog] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  const toggleActive = useCallback(async () => {
    if (!promotion) return;
    if (!promotion.image_url) {
      enqueueSnackbar("Promotion image is required", {
        variant: "error",
      });
      return;
    }

    try {
      await updateDoc(promotion.docRef, {
        active: !promotion.active,
        ...updatedAt,
      });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update promotion", {
        variant: "error",
      });
    }
  }, [promotion, enqueueSnackbar]);

  const updateZipcodes = useCallback(
    async (zipcodes: string[]) => {
      if (!promotion) return;
      await updateDoc(promotion.docRef, {
        "criteria.zipcodes": zipcodes,
        ...updatedAt,
      });
    },
    [promotion]
  );

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : promotion ? (
        <>
          <PageHeader
            title={promotion.name}
            subtitle={promotion.title}
            action={
              <>
                <FormControlLabel
                  label="Active"
                  checked={promotion.active}
                  control={
                    <Switch onChange={(event, checked) => toggleActive()} />
                  }
                />
                <MoreOptionsMenu promotion={promotion} />
              </>
            }
            href={promotion.more_info_url}
            sectionIsId={true}
          />

          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} xl={4}>
              <Stack spacing={6}>
                <ManagePromoBackgroundCard promotion={promotion} />
                <ManagePromoSponsorImageCard promotion={promotion} />
                <ManagePromoConnectorsCard promotion={promotion} />
              </Stack>
            </Grid>

            <Grid item xs={12} lg={6} xl={8}>
              <Stack spacing={6}>
                <ZipcodesCard
                  zips={promotion.criteria?.zipcodes ?? []}
                  subheader={"Active in all regions unless a zipcode is added."}
                  action={
                    <Button
                      variant="text"
                      onClick={() => setOpenEditZipcodesDialog(true)}
                    >
                      Edit
                    </Button>
                  }
                />
                <ManagePromoPersonasCard promotion={promotion} />
                <VehicleList promotion={promotion} />
              </Stack>
            </Grid>
          </Grid>

          <EditZipcodesDialog
            open={openEditZipcodesDialog}
            handleClose={() => setOpenEditZipcodesDialog(false)}
            handleSave={updateZipcodes}
            fullScreen={fullScreen}
            zipcodes={promotion.criteria?.zipcodes ?? []}
          />
        </>
      ) : (
        <Page404 />
      )}
    </React.Fragment>
  );
}

export default PromotionProfile;
