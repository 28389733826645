import { Switch } from "@mui/material";
import { updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useCallback } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";
import { updatedAt } from "../../util/firebase";

function SettingsCard({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const updateSetting = useCallback(
    (field: string, value: boolean) => {
      if (!(field in station)) {
        throw Error(`Invalid field name: ${field}`);
      }
      updateDoc(station.docRef, {
        [field]: value,
        ...updatedAt,
      }).catch((err) => {
        console.error(err);
        enqueueSnackbar("Failed to update station.", { variant: "error" });
      });
    },
    [station, enqueueSnackbar]
  );

  return (
    <PartitionedCard title={"Settings"} spacing={2}>
      <PartitionedCardItem
        title={"Restricted Access"}
        caption={"Show this station as having restricted access"}
        alignItems={"center"}
      >
        <Switch
          checked={station.is_access_restricted}
          onChange={(event) => {
            updateSetting("is_access_restricted", event.target.checked);
          }}
        />
      </PartitionedCardItem>

      <PartitionedCardItem
        title={"Coming Soon"}
        caption={"Show this station as coming soon"}
        alignItems={"center"}
      >
        <Switch
          checked={station.is_coming_soon}
          onChange={(event) => {
            updateSetting("is_coming_soon", event.target.checked);
          }}
        />
      </PartitionedCardItem>

      <PartitionedCardItem
        title={"Offline"}
        caption={"Show this station as offline"}
        alignItems={"center"}
      >
        <Switch
          checked={station.is_offline}
          onChange={(event) => {
            updateSetting("is_offline", event.target.checked);
          }}
        />
      </PartitionedCardItem>

      <PartitionedCardItem
        title={"Published"}
        caption={"Show this station in the mobile app"}
        alignItems={"center"}
      >
        <Switch
          checked={station.publish}
          disabled={station.archived}
          onChange={(event) => {
            updateSetting("publish", event.target.checked);
          }}
        />
      </PartitionedCardItem>

      <PartitionedCardItem
        title={"Archived"}
        caption={"Hide this station in the admin"}
        alignItems={"center"}
      >
        <Switch
          checked={station.archived}
          onChange={(event) => {
            updateSetting("archived", event.target.checked);

            if (event.target.checked) {
              updateSetting("publish", false);
            }
          }}
        />
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default SettingsCard;
