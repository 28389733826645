import {
  Autocomplete,
  Button,
  Chip,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { orderBy, serverTimestamp, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import type { FormEventHandler } from "react";
import React, { useCallback, useMemo, useState } from "react";

import FormDialog from "../../components/dialogs/FormDialog";
import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import useCollection from "../../hooks/useCollection";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Network } from "../../types/network.db";
import type { Stub } from "../../types/stub.db";
import type { VehicleMake } from "../../types/vehicle.db";
import { updatedAt } from "../../util/firebase";

const sortByName = (a: Stub, b: Stub): number => {
  return a.name!.localeCompare(b.name!);
};

function EditSettingsDialog({
  open,
  handleClose,
  network,
}: {
  open: boolean;
  handleClose: () => void;
  network: WithDocRef<Network>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [makes] = useCollection<VehicleMake>("vehicle-makes", orderBy("name"));
  const makeOptions = useMemo(
    () => makes.map((v) => ({ id: v.id, name: v.name })) ?? [],
    [makes]
  );
  const [plugAndChargeMakes, setPlugAndChargeMakes] = useState<Stub[]>(
    network.plug_and_charge_makes ?? []
  );
  const [restrictedToMakes, setRestrictedToMakes] = useState<Stub[]>(
    network.restricted_to_makes ?? []
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const updateNetwork: FormEventHandler = async (event) => {
    event.preventDefault();
    try {
      setIsSubmitting(true);
      await updateDoc(network.docRef, {
        plug_and_charge_makes: plugAndChargeMakes.sort(sortByName),
        restricted_to_makes: restrictedToMakes.sort(sortByName),
        "timestamps.updated_at": serverTimestamp(),
      });
      enqueueSnackbar("Updated network");
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update network");
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={"Edit Settings"}
      open={open}
      onCancel={handleClose}
      onSubmit={updateNetwork}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Autocomplete
          multiple
          autoHighlight
          disableCloseOnSelect
          limitTags={4}
          value={plugAndChargeMakes}
          options={makeOptions}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name!}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterSelectedOptions
          onChange={(event, newValue) => setPlugAndChargeMakes(newValue)}
          renderInput={(params) => (
            <TextField {...params} label={"Plug and Charge"} />
          )}
          fullWidth
        />
        <Autocomplete
          multiple
          autoHighlight
          disableCloseOnSelect
          limitTags={4}
          value={restrictedToMakes}
          options={makeOptions}
          getOptionKey={(option) => option.id}
          getOptionLabel={(option) => option.name!}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterSelectedOptions
          onChange={(event, newValue) => setRestrictedToMakes(newValue)}
          renderInput={(params) => (
            <TextField {...params} label={"Restricted To"} />
          )}
          fullWidth
        />
      </Stack>
    </FormDialog>
  );
}

function SettingsCard({
  network,
}: {
  network: WithDocRef<Network>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const plugAndChargeMakes = network.plug_and_charge_makes ?? [];
  const restrictedToMakes = network.restricted_to_makes ?? [];

  const deletePlugAndChargeMake = useCallback(
    async (stub: Stub) => {
      try {
        const makes =
          network.plug_and_charge_makes?.filter((v) => v.id !== stub.id) ?? [];
        await updateDoc(network.docRef, {
          plug_and_charge_makes: makes,
          "timestamps.updated_at": serverTimestamp(),
        });
      } catch (err: any) {
        console.error(err);
        enqueueSnackbar("Failed to update network", { variant: "error" });
      }
    },
    [enqueueSnackbar, network]
  );

  const deleteRestrictedToMake = useCallback(
    async (stub: Stub) => {
      try {
        const makes =
          network.restricted_to_makes?.filter((v) => v.id !== stub.id) ?? [];
        await updateDoc(network.docRef, {
          restricted_to_makes: makes,
          "timestamps.updated_at": serverTimestamp(),
        });
      } catch (err: any) {
        console.error(err);
        enqueueSnackbar("Failed to update network", { variant: "error" });
      }
    },
    [enqueueSnackbar, network]
  );

  const updateIsFeatured = useCallback(
    async (newValue) => {
      try {
        await updateDoc(network.docRef, {
          is_featured_network: newValue,
          "timestamps.updated_at": serverTimestamp(),
        });
      } catch (err: any) {
        console.error(err);
        enqueueSnackbar("Failed to update network", { variant: "error" });
      }
    },
    [enqueueSnackbar, network]
  );

  const togglePublish = useCallback(async () => {
    if (!network) return;
    try {
      await updateDoc(network.docRef, {
        publish: !network.publish,
        ...updatedAt,
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to update network.", { variant: "error" });
    }
  }, [network, enqueueSnackbar]);

  return (
    <>
      <PartitionedCard
        title={"Settings"}
        action={
          <Button
            onClick={() => {
              setOpenEditDialog(true);
            }}
          >
            Edit
          </Button>
        }
        spacing={2}
      >
        <PartitionedCardItem
          title={"Plug and Charge"}
          caption={
            "Vehicle brands that are plug and charge capable on this network"
          }
          alignItems={"center"}
        >
          <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
            {plugAndChargeMakes.length > 0 ? (
              plugAndChargeMakes.map((stub) => (
                <Chip
                  key={stub.id}
                  variant={"filled"}
                  label={stub.name}
                  onDelete={() => deletePlugAndChargeMake(stub)}
                />
              ))
            ) : (
              <Chip variant={"outlined"} label={"None"} />
            )}
          </Stack>
        </PartitionedCardItem>

        <PartitionedCardItem
          title={"Restricted To"}
          caption={"Vehicle brands that are allowed to charge on this network"}
          alignItems={"center"}
        >
          <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
            {restrictedToMakes.length > 0 ? (
              restrictedToMakes.map((stub) => (
                <Chip
                  key={stub.id}
                  variant={"filled"}
                  label={stub.name}
                  onDelete={() => deleteRestrictedToMake(stub)}
                />
              ))
            ) : (
              <Chip variant={"outlined"} label={"Any"} />
            )}
          </Stack>
        </PartitionedCardItem>

        <PartitionedCardItem
          title={"Featured"}
          caption={"Show this as a top network in the mobile app"}
          alignItems={"center"}
        >
          <Switch
            checked={network.is_featured_network}
            onChange={(event) => updateIsFeatured(event.target.checked)}
          />
        </PartitionedCardItem>

        <PartitionedCardItem
          title={"Published"}
          caption={"Show this network in the mobile app"}
          alignItems={"center"}
        >
          <Switch checked={network.publish} onChange={togglePublish} />
        </PartitionedCardItem>
      </PartitionedCard>
      {openEditDialog && (
        <EditSettingsDialog
          open={true}
          handleClose={() => setOpenEditDialog(false)}
          network={network}
        />
      )}
    </>
  );
}

export default SettingsCard;
