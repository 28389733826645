import type { GridRowParams, MuiEvent } from "@mui/x-data-grid";
import type React from "react";
import { useCallback, useMemo } from "react";
import type { Params } from "react-router-dom";
import { generatePath, useLocation, useNavigate } from "react-router-dom";

/**
 * Use as the onRowClick handler for DataGrid.
 *
 * Will handle row clicks by appending the `row.id` to the current `location.pathname`.
 *
 * Launches the location in a new tab when the `metaKey` (e.g. the Command key) is pressed.
 */
const useNavigateOnRowClick = (path?: string, params?: Params | undefined) => {
  const location = useLocation();
  const defaultPath = useMemo(
    () => [location.pathname, ":id"].join("/"),
    [location.pathname]
  );

  const navigate = useNavigate();
  return useCallback(
    (row: GridRowParams, event: MuiEvent<React.MouseEvent>) => {
      const _path = generatePath(path ?? defaultPath, {
        ...(params ? params : {}),
        id: row.id.toString(),
      });

      if (event.metaKey) {
        window.open(_path, "_blank");
      } else {
        navigate(_path);
      }
    },
    [navigate, defaultPath, path, params]
  );
};

export default useNavigateOnRowClick;
