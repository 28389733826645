import { Chip, Grid, Stack } from "@mui/material";
import { subDays } from "date-fns";
import {
  collection as firestoreCollection,
  doc,
  getDoc,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useReducer, useState } from "react";

import Loader from "../components/Loader";
import NothingHere from "../components/NothingHere";
import { PageHeader } from "../components/PageHeader";
import useFirebase from "../hooks/useFirebase";
import useQuery from "../hooks/useQuery";
import type { StationPhoto } from "../types/station-photo.db";
import type { User } from "../types/user.db";

import PhotoCard from "./station-photos/PhotoCard";

const needsReview = [
  where("needs_review", "==", true),
  orderBy("timestamps.created_at", "desc"),
];
const rejected = [
  where("needs_review", "==", false),
  where("approved", "==", false),
  orderBy("timestamps.created_at", "desc"),
];
const recent = [
  where("timestamps.created_at", ">=", subDays(new Date(), 14)),
  orderBy("timestamps.created_at", "desc"),
];

const listReducer = (
  state: User[],
  action: { type: "add"; newItem: User }
): User[] => {
  switch (action.type) {
    case "add":
      return [...state, action.newItem];
  }
  throw Error("Unknown action: " + action.type);
};

function Photos(): JSX.Element {
  const { database } = useFirebase();
  const collectionRef = firestoreCollection(database, "station-photos");

  const [photos, loading, setQuery] = useQuery<StationPhoto>(
    query(collectionRef, ...needsReview)
  );
  const [users, dispatch] = useReducer(listReducer, []);
  const [currentFilter, setCurrentFilter] = useState<
    "needsReview" | "rejected" | "recent"
  >("needsReview");

  useEffect(() => {
    switch (currentFilter) {
      case "needsReview":
        setQuery(query(collectionRef, ...needsReview));
        break;
      case "rejected":
        setQuery(query(collectionRef, ...rejected));
        break;
      case "recent":
        setQuery(query(collectionRef, ...recent));
        break;
    }
  }, [collectionRef, currentFilter, setQuery]);

  // Load users
  useEffect(() => {
    const unique = [...new Set(photos.map((photo) => photo.user_id))];
    for (const userId of unique) {
      getDoc(doc(database, "users", userId)).then((doc) => {
        if (doc.exists()) {
          const user = { id: doc.id, ...doc.data() } as User;
          dispatch({ type: "add", newItem: user });
        }
      });
    }
  }, [database, photos]);

  return (
    <React.Fragment>
      <PageHeader title={"Photos"} />

      <Stack direction={"row"} spacing={2} marginBottom={6}>
        <Chip
          label={"Needs Review"}
          {...(currentFilter === "needsReview"
            ? { variant: "filled", color: "secondary" }
            : { variant: "outlined", color: "info" })}
          onClick={() => setCurrentFilter("needsReview")}
        />
        <Chip
          label={"Rejected"}
          {...(currentFilter === "rejected"
            ? { variant: "filled", color: "secondary" }
            : { variant: "outlined", color: "info" })}
          onClick={() => setCurrentFilter("rejected")}
        />
        <Chip
          label={"Recent"}
          {...(currentFilter === "recent"
            ? { variant: "filled", color: "secondary" }
            : { variant: "outlined", color: "info" })}
          onClick={() => setCurrentFilter("recent")}
        />
      </Stack>

      {loading ? (
        <Loader />
      ) : !photos || photos.length === 0 ? (
        <NothingHere />
      ) : (
        <Grid container spacing={6}>
          {photos.map((photo) => (
            <Grid item key={photo.id} xs={12} sm={6} md={4}>
              <PhotoCard
                photo={photo}
                user={users.find((v) => v.id === photo.user_id)}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </React.Fragment>
  );
}

export default Photos;
