import { useContext } from "react";

import { FirebaseContext } from "../contexts/FirebaseContext";

const useFirebase = () => {
  const context = useContext(FirebaseContext);

  if (!context)
    throw new Error("FirebaseContext must be placed within FirebaseProvider");

  return context;
};

export default useFirebase;
