import styled from "@emotion/styled";
import { Google as GoogleIcon } from "@mui/icons-material";
import {
  Alert as MuiAlert,
  Box,
  Button as MuiButton,
  Collapse,
  Divider as MuiDivider,
  TextField as MuiTextField,
  useTheme,
  Fade,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Controller, useForm } from "react-hook-form";

const Alert = styled(MuiAlert)(spacing);
const Button = styled(MuiButton)(spacing);
const Divider = styled(MuiDivider)(spacing);
const TextField = styled(MuiTextField)(spacing);

function ChargewayLogo({ mode }: { mode: string }) {
  const Logo = styled.img`
    height: 64px;
    margin-bottom: 32px;
  `;

  const variant = mode === "dark" ? "White" : "Gray";

  return (
    <Logo
      src={`/static/img/chargeway/Chargeway_Logo_${variant}.png`}
      alt="Chargeway"
    />
  );
}

function EmailAndPasswordForm({
  submitRef,
}: {
  submitRef: React.RefObject<HTMLButtonElement>;
}) {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const [errorMessage, setError] = useState("");

  const onSubmit = async (data: any) => {
    const { email, password } = data;
    try {
      await signInWithEmailAndPassword(getAuth(), email, password);
    } catch (err: any) {
      console.error(err);

      if (err.code === "auth/user-not-found") {
        setError("User not found");
      } else if (err.code === "auth/wrong-password") {
        setError("Wrong password");
      } else {
        setError(err.message || "An unknown error occurred");
      }
    }
  };

  return (
    <React.Fragment>
      {errorMessage && (
        <Alert mb={5} severity="error">
          {errorMessage}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            pattern: { value: /^.+@.+$/, message: "Must be a valid email" },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              type="email"
              label="Email"
              autoComplete="username"
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
              mb={2}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              type="password"
              label="Password"
              autoComplete="current-password"
              error={Boolean(error)}
              helperText={error?.message}
              fullWidth
            />
          )}
        />

        <Button
          ref={submitRef}
          type="submit"
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          mt={3}
          fullWidth
          sx={{ display: "none" }}
        >
          Sign in
        </Button>
      </form>
    </React.Fragment>
  );
}

function SignIn() {
  const theme = useTheme();
  const submitRef = useRef<HTMLButtonElement>(null);
  const [withEmail, setWithEmail] = useState(false);

  const withGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      hd: "chargeway.net",
      prompt: "select_account",
    });
    if (process.env.NODE_ENV === "production") {
      await signInWithRedirect(getAuth(), provider);
    } else {
      await signInWithPopup(getAuth(), provider);
    }
  };

  const handleWithEmail = async () => {
    if (!withEmail) {
      setWithEmail(true);
    } else {
      submitRef?.current?.click();
    }
  };

  return (
    <React.Fragment>
      <ChargewayLogo mode={theme.palette.mode} />
      <Helmet />

      <Box>
        <Button
          color="primary"
          variant="contained"
          size="large"
          startIcon={<GoogleIcon />}
          p={3}
          onClick={withGoogle}
          fullWidth
        >
          Continue with Google
        </Button>

        <Collapse in={withEmail}>
          <Box>
            <Divider my={4} flexItem />
            <EmailAndPasswordForm submitRef={submitRef} />
          </Box>
        </Collapse>
        <Button
          variant="outlined"
          size="medium"
          mt={4}
          p={3}
          fullWidth
          onClick={handleWithEmail}
        >
          Continue with Email
        </Button>
        <Fade in={withEmail}>
          <Button
            variant="text"
            size="small"
            mt={4}
            p={3}
            fullWidth
            onClick={() => setWithEmail(false)}
          >
            Cancel
          </Button>
        </Fade>
      </Box>
    </React.Fragment>
  );
}

export default SignIn;
