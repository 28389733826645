import { Card, CardHeader, Chip, Stack, Typography } from "@mui/material";
import type {
  GridColumns,
  GridEventListener,
  GridRowParams,
} from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { collection, orderBy, query, where } from "firebase/firestore";
import { useCallback } from "react";

import { standardProps } from "../../components/data-grid";
import * as columnTypes from "../../components/data-grid/columnTypes";
import useFirebase from "../../hooks/useFirebase";
import type { WithDocRef } from "../../hooks/useQuery";
import useQuery from "../../hooks/useQuery";
import { cw } from "../../theme/variants";
import type { UserVehicle } from "../../types/user-vehicle.db";
import type { User } from "../../types/user.db";

const columns: GridColumns<UserVehicle> = [
  {
    field: "name",
    headerName: "Name",
    ...columnTypes.flexPrimary,
    renderCell: (params) => {
      if (params.row.smartcar?.is_connected === true) {
        return (
          <Stack direction={"column"}>
            <Typography variant={"body1"}>{params.row.name}</Typography>
            <Typography variant={"caption"} color={cw.brandGreen}>
              Chargeway+
            </Typography>
          </Stack>
        );
      }
      return params.row.name;
    },
  },
  {
    field: "year",
    headerName: "Year",
    ...columnTypes.flexDefault,
    minWidth: 100,
    valueGetter: (params) => params.row.parent.year,
  },
  {
    field: "make",
    headerName: "Make",
    ...columnTypes.flexDefault,
    valueGetter: (params) => params.row.parent.make,
  },
  {
    field: "model",
    headerName: "Model",
    ...columnTypes.flexDefault,
    minWidth: 250,
    renderCell: (params) => (
      <Stack direction={"column"}>
        <Typography variant={"body1"}>{params.row.parent.model}</Typography>
        <Typography variant={"caption"} color={"text.secondary"}>
          {params.row.parent.trim}
        </Typography>
      </Stack>
    ),
  },
  {
    field: "fuel_type",
    headerName: "Type",
    ...columnTypes.flexDefault,
    minWidth: 100,
    valueGetter: (params) => params.row.parent.fuel_type,
    renderCell: (params) => (
      <Chip label={params.value} variant="outlined" size="small" />
    ),
  },
  {
    field: "updated_at",
    headerName: "Last updated",
    ...columnTypes.updatedAt,
  },
  {
    field: "created_at",
    headerName: "First created",
    ...columnTypes.createdAt,
  },
];

function VehiclesCard({ user }: { user: WithDocRef<User> }): JSX.Element {
  const { database: db } = useFirebase();
  const [items, loading] = useQuery<UserVehicle>(
    query(
      collection(db, "user-vehicles"),
      where("user_id", "==", user.id),
      orderBy("timestamps.created_at", "asc")
    )
  );

  const handleRowClick = useCallback<GridEventListener<"rowClick">>(
    (params: GridRowParams<UserVehicle>) => {
      console.debug(params.row);
    },
    []
  );

  return (
    <Card>
      <CardHeader title="Vehicles" />
      <DataGrid
        rows={items}
        columns={columns}
        {...standardProps}
        pageSize={10}
        sx={{
          ".MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
        onRowClick={handleRowClick}
        loading={loading}
      />
    </Card>
  );
}

export default VehiclesCard;
