import { Autocomplete, InputAdornment, Stack, TextField } from "@mui/material";
import {
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import FormDialog from "../../components/dialogs/FormDialog";
import useFirebase from "../../hooks/useFirebase";
import type { Charger } from "../../types/charger.db";
import { isNumber } from "../../util/stdlib";

function EditChargerDialog({
  open,
  handleClose,
  charger,
}: {
  open: boolean;
  handleClose: () => void;
  charger?: Charger;
}): JSX.Element {
  const { database } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      reset({
        charger_make: charger?.make ?? "",
        charger_model: charger?.model ?? "",
        charger_model_variant: charger?.model_variant ?? "",
        charger_price: charger?.price ?? "",
        charger_amps: charger?.amps ?? "",
        charger_plug_type: charger?.plug_types ?? "J1772",
        charger_details_link: charger?.details_link ?? "",
      });
    }
  }, [open, reset, charger]);

  const onSubmit = async (data: any) => {
    const collectionRef = collection(database, "home-chargers");

    const update: Partial<Charger> = {
      make: data.charger_make,
      model: data.charger_model,
      model_variant: data.charger_model_variant,
      price: data.charger_price,
      amps: data.charger_amps,
      plug_types: data.charger_plug_type,
      details_link: data.charger_details_link,
    };

    try {
      if (charger) {
        await updateDoc(doc(collectionRef, charger.id), {
          ...update,
          "timestamps.updated_at": serverTimestamp(),
        });
      } else {
        await setDoc(doc(collectionRef), {
          ...update,
          timestamps: {
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
          },
        });
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(`Failed to ${charger ? "update" : "add"} charger`, {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={charger ? "Edit Charger" : "Add Charger"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Controller
          name={"charger_make"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Make"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
              autoFocus
            />
          )}
        />
        <Stack direction={"row"} spacing={4}>
          <Controller
            name={"charger_model"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Model"
                helperText={error?.message}
                error={Boolean(error)}
                fullWidth
              />
            )}
          />
          <Controller
            name={"charger_model_variant"}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Variant"
                helperText={error?.message}
                error={Boolean(error)}
                fullWidth
              />
            )}
          />
        </Stack>
        <Controller
          name={"charger_price"}
          control={control}
          rules={{
            validate: (v) => isNumber(v) || "Must be a number.",
            required: true,
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Price"
              helperText={error?.message}
              error={Boolean(error)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              fullWidth
            />
          )}
        />
        <Stack direction={"row"} spacing={4}>
          <Controller
            name={"charger_amps"}
            control={control}
            rules={{
              validate: (v) => isNumber(v) || "Must be a number.",
              required: true,
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Amps"
                helperText={error?.message}
                error={Boolean(error)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">A</InputAdornment>
                  ),
                }}
                fullWidth
              />
            )}
          />
          <Controller
            name={"charger_plug_type"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                autoHighlight
                autoSelect
                options={["J1772", "Tesla"]}
                onChange={(event, newValue) => field.onChange(newValue)}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Plug Type"
                    helperText={error?.message}
                    error={Boolean(error)}
                  />
                )}
              />
            )}
          />
        </Stack>
        <Controller
          name={"charger_details_link"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Link"
              placeholder="https://example.net"
              helperText={
                error?.message ||
                "An optional link to the manufacturer page for this charger."
              }
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

export default EditChargerDialog;
