import styled from "@emotion/styled";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { darken, lighten } from "polished";
import React from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";

const Account = styled(Box)`
  padding: ${(props) => props.theme.spacing(6)};
  background-color: ${(props) =>
    props.theme.sidebar.header.background} !important;

  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      props.theme.palette.mode === "dark"
        ? lighten(0.03, props.theme.sidebar.background)
        : darken(0.03, props.theme.sidebar.background)} !important;
    color: ${(props) => props.theme.sidebar.color};
  }
`;

const AccountText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.header.color};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const AccountSubText = styled(AccountText)`
  font-size: 0.7rem;
  opacity: 0.6;
`;

const SidebarAccount: React.FC = ({ ...rest }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <Account {...rest} onClick={() => navigate("/account")}>
      {!!user && (
        <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
          <Avatar alt={user.displayName || ""} src={user.photoUrl || ""} />
          <Stack sx={{ overflow: "hidden" }}>
            <AccountText variant="body2">{user.displayName}</AccountText>
            <AccountSubText variant="caption">{user.email}</AccountSubText>
          </Stack>
        </Stack>
      )}
    </Account>
  );
};

export default SidebarAccount;
