import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { orderBy } from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import useCollection from "../../hooks/useCollection";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Beacon } from "../../types/beacon.db";
import type { VehicleMake } from "../../types/vehicle.db";
import { range } from "../../util/stdlib";

import addVehiclesByMake from "./addVehiclesByMake";

function AddVehicleDialog({
  open,
  handleClose,
  beacon,
}: {
  open: boolean;
  handleClose: () => void;
  beacon: WithDocRef<Beacon>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  const [makes, loading] = useCollection<VehicleMake>(
    "vehicle-makes",
    orderBy("name")
  );
  const [makeOptions, setMakeOptions] = useState<WithDocRef<VehicleMake>[]>();

  const [selectedYear, setSelectedYear] = useState<string | null>(null);
  const [selectedMakes, setSelectedMakes] = useState<
    WithDocRef<VehicleMake>[] | null
  >(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Set makes
  useEffect(() => {
    setMakeOptions(makes);
  }, [makes]);

  // Filter makes
  useEffect(() => {
    if (!selectedYear || selectedYear.length !== 4) return;

    setMakeOptions(
      makes.filter((v) => v.model_years.includes(parseInt(selectedYear)))
    );
  }, [makes, selectedYear]);

  const onCancel = () => handleClose();
  const onSubmit = async () => {
    setIsSubmitting(true);
    const makesToAdd =
      !selectedMakes || selectedMakes.length === 0 ? makes : selectedMakes;

    const tasks = [];
    for (const make of makesToAdd) {
      tasks.push(
        addVehiclesByMake({
          year: parseInt(selectedYear!),
          make,
          beaconId: beacon.id,
        })
      );
    }

    // Wait until all tasks have completed
    Promise.all<string[]>(tasks)
      .then((results) => {
        const total = results.reduce((a, b) => a + b.length, 0);
        if (total === 0) {
          enqueueSnackbar("Added no new vehicles");
        } else if (total === 1) {
          enqueueSnackbar("Added 1 new vehicle");
        } else {
          enqueueSnackbar(`Added ${total} new vehicles`);
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to add vehicles", { variant: "error" });
      })
      .finally(() => {
        handleClose();
      });
  };

  const thisYear = new Date().getFullYear();
  const nextYear = thisYear + 1;
  const years = range(2011, nextYear)
    .reverse()
    .map((n) => n.toString());

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason === "backdropClick") return;
        handleClose();
      }}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      fullWidth
    >
      <Box>
        <DialogTitle sx={{ padding: "24px 24px 0" }}>Add vehicles</DialogTitle>
        <DialogContent>
          <Stack spacing={4} mt={6}>
            <Autocomplete
              freeSolo
              value={selectedYear}
              options={years}
              onChange={(event, newValue) => setSelectedYear(newValue)}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Year" autoFocus />
              )}
            />
            <Autocomplete
              multiple
              autoHighlight
              disableCloseOnSelect
              limitTags={4}
              value={selectedMakes ?? []}
              options={makeOptions ?? []}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              onChange={(event, newValue) => setSelectedMakes(newValue)}
              fullWidth
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Make"
                  helperText={
                    "Optionally select one or more brands; or leave blank to select all."
                  }
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            {"Cancel"}
          </Button>
          <LoadingButton
            onClick={onSubmit}
            disabled={selectedYear === null}
            loading={isSubmitting}
          >
            {"Add"}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default AddVehicleDialog;
