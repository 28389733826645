import type { SelectChangeEvent } from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import { updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";

import type { WithDocRef } from "../../../hooks/useQuery";
import type { Vehicle } from "../../../types/vehicle.db";
import { updatedAt } from "../../../util/firebase";

type UserVehicleStub = {
  id: string;
  parent: Vehicle;
  photo_url: string | null;
};

function VehicleColorSelect({
  vehicle,
  value,
}: {
  vehicle: WithDocRef<UserVehicleStub>;
  value: string | null;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const onChange = (newValue: string) => {
    updateDoc(vehicle.docRef, { photo_url: newValue, ...updatedAt })
      .then(() => {
        console.log("Update completed.");
      })
      .catch(() => {
        console.log("Update failed.");
        enqueueSnackbar("Failed to update trim color", { variant: "error" });
      });
  };

  return (
    <Select
      fullWidth
      size={"small"}
      variant={"outlined"}
      value={value}
      onChange={(event: SelectChangeEvent<any>) => onChange(event.target.value)}
    >
      {vehicle.parent.trim_colors?.map((v) => (
        <MenuItem key={v.url} value={v.url}>
          {v.color_name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default VehicleColorSelect;
