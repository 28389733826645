import {
  PublishRounded as PublishIcon,
  UploadRounded as UploadIcon,
  DeleteRounded as DeleteIcon,
  LaunchRounded,
  MoreVert as MoreIcon,
  Publish,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  darken,
  IconButton,
  lighten,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import type { DropEvent, FileRejection } from "react-dropzone";
import { useDropzone } from "react-dropzone";

function MenuButton({
  imageUrl,
  onRemove,
}: {
  imageUrl: string;
  onRemove: () => void;
}): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorEl ? "image-menu" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        size="medium"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="image-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
      >
        <MenuItem
          onClick={() => {
            onRemove();
            closeMenu();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

function ManageImageCard({
  title,
  imageUrl,
  onDrop,
  onRemove,
  loading,
  action,
  height = 100,
}: {
  title?: string | null;
  imageUrl?: string | null;
  onDrop: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  onRemove: () => void;
  loading?: boolean;
  action?: React.ReactNode;
  height?: number;
}): JSX.Element {
  const theme = useTheme();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Card>
      {imageUrl && (
        <CardHeader
          title={title}
          action={
            action || (
              <Stack direction={"row"}>
                <Button
                  size={"small"}
                  startIcon={<LaunchRounded />}
                  href={imageUrl}
                  target={"_blank"}
                >
                  View Full
                </Button>
                <MenuButton imageUrl={imageUrl} onRemove={onRemove} />
              </Stack>
            )
          }
        />
      )}
      <Box sx={{ position: "relative", overflow: "auto" }}>
        {loading && (
          <LinearProgress sx={{ position: "absolute", left: 0, right: 0 }} />
        )}
        {imageUrl ? (
          <>
            <PublishIcon
              {...getRootProps({
                sx: {
                  width: "2.5em",
                  height: "2.5em",
                  display: isDragActive ? "block" : "none",
                  position: "absolute",
                  left: "50%",
                  right: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                },
              })}
            />
            <CardMedia
              {...getRootProps({
                sx: {
                  height,
                  margin: 6,
                  backgroundSize: "contain",
                  objectFit: "contain",
                  opacity: isDragActive ? 0.1 : 1,
                  cursor: "pointer",
                },
                image: imageUrl,
                title: imageUrl,
              })}
            />
          </>
        ) : (
          <CardContent
            {...getRootProps({
              sx: {
                padding: 8,
                backgroundColor: isDragActive
                  ? theme.palette.mode === "dark"
                    ? lighten(theme.palette.background.paper, 0.05)
                    : darken(theme.palette.background.paper, 0.05)
                  : "transparent",
                cursor: "pointer",
              },
            })}
          >
            <Stack alignItems={"center"}>
              <UploadIcon
                sx={{ width: "1.5em", height: "1.5em", marginBottom: 2 }}
              />
              <Typography variant={"h6"}>Upload {title}</Typography>
              <Typography
                variant={"body1"}
                color={"text.secondary"}
                marginTop={1}
              >
                Drop here or click to browse
              </Typography>
            </Stack>
          </CardContent>
        )}
        <input {...getInputProps()} />
      </Box>
    </Card>
  );
}

export { ManageImageCard };
