import styled from "@emotion/styled";
import { CircularProgress, Typography } from "@mui/material";
import React from "react";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function NothingHere() {
  return (
    <Root>
      <Typography variant={"body2"} color={"text.secondary"}>
        Nothing Here…
      </Typography>
    </Root>
  );
}

export default NothingHere;
