import {
  Delete as DeleteIcon,
  MoreVert as MoreIcon,
} from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { DeleteDialog } from "../components/dialogs/DeleteDialog";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import StationCard from "../components/StationCard";
import UserCard from "../components/UserCard";
import useDoc from "../hooks/useDoc";
import type { WithDocRef } from "../hooks/useQuery";
import type { StationReview } from "../types/station-review.db";
import { FirestoreMenuItem } from "../util/firebase";

import Page404 from "./auth/Page404";
import GeneralCard from "./station-reviews/GeneralCard";

function MoreOptionsMenu({
  review,
}: {
  review: WithDocRef<StationReview>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  return (
    <React.Fragment>
      {fullScreen ? (
        <Button
          aria-owns={anchorEl ? "menu-review" : undefined}
          aria-haspopup="true"
          variant={"outlined"}
          color={"primary"}
          size={"large"}
          fullWidth
          onClick={toggleMenu}
        >
          Menu
        </Button>
      ) : (
        <IconButton
          aria-owns={anchorEl ? "menu-review" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="medium"
        >
          <MoreIcon />
        </IconButton>
      )}
      <Menu
        id="menu-review"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
      >
        <FirestoreMenuItem docRef={review.docRef} />
        <Divider />
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        docRef={review.docRef}
        displayName={review.comment.substring(0, 100).trim() + "…"}
      />
    </React.Fragment>
  );
}

function ReviewProfile(): JSX.Element {
  const { docId } = useParams();
  const [review, loading] = useDoc<StationReview>("station-reviews", docId!);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : review ? (
        <>
          <PageHeader
            title={"Station Review"}
            action={<MoreOptionsMenu review={review} />}
            sectionIsId={true}
          />

          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} xl={4}>
              <Stack spacing={6}>
                <GeneralCard review={review} />
                <StationCard stationId={review.station_id} />
                <UserCard userId={review.user_id} />
              </Stack>
            </Grid>
          </Grid>
        </>
      ) : (
        <Page404 />
      )}
    </React.Fragment>
  );
}

export default ReviewProfile;
