import styled from "@emotion/styled";
import { Email as EmailIcon, Google as GoogleIcon } from "@mui/icons-material";
import {
  Alert as MuiAlert,
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Link,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import type { SpacingProps } from "@mui/system";
import { spacing } from "@mui/system";
import { formatRelative } from "date-fns";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { PageHeader } from "../../components/PageHeader";
import useAuth from "../../hooks/useAuth";

interface ButtonProps extends SpacingProps {
  component?: string;
}

const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;
const Alert = styled(MuiAlert)(spacing);
const Avatar = styled(MuiAvatar)`
  height: 128px;
  width: 128px;
`;
const Button = styled(MuiButton)<ButtonProps>(spacing);
const Card = styled(MuiCard)(spacing);
const Centered = styled.div`
  text-align: center;
`;
const Divider = styled(MuiDivider)(spacing);
const IconBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    width: `${theme.spacing(8)}`,
    height: `${theme.spacing(8)}`,
    borderRadius: `${theme.spacing(8)}`,
    backgroundColor: `${theme.palette.primary.main}`,
    color: "white",
    boxShadow: `0 0 0 2px ${theme.palette.paper}`,
  },
  "& .MuiBadge-badge svg": {
    padding: "2px",
  },
}));
const Spacer = styled.div(spacing);
const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

function About() {
  const { user } = useAuth();

  const displayName = user?.displayName || "Unknown";
  const photoUrl = user?.photoUrl?.split("=", 1)[0] || "";
  const email = user?.email || "Unknown";

  return (
    <Card mb={6}>
      <CardContent>
        <Centered>
          <IconBadge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              user?.provider === "google.com" ? <GoogleIcon /> : <EmailIcon />
            }
          >
            <Avatar src={photoUrl} />
          </IconBadge>

          <Spacer mb={4} />

          <Typography variant="subtitle1" component="div">
            <Box fontWeight="fontWeightMedium">{displayName}</Box>
            <Box fontWeight="fontWeightRegular">
              <Link href={`mailto:${email}`}>{email}</Link>
            </Box>
          </Typography>

          {user?.creationTime && (
            <Typography variant={"caption"} color={"text.secondary"}>
              {formatRelative(Date.parse(user?.creationTime), new Date())}
            </Typography>
          )}
        </Centered>
      </CardContent>
    </Card>
  );
}

function ChangePassword() {
  const minPasswordLength = 14;

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
    },
  });

  const { user } = useAuth();

  const [errorMessage, setError] = useState("");
  const [successMessage, setSuccess] = useState("");

  const onSubmit = async (data: any) => {
    const currentPassword = data?.currentPassword || "";
    const newPassword = data?.newPassword || "";

    const auth = getAuth();
    const currentUser = auth.currentUser;
    if (!currentUser) throw new Error("No active Firebase user session!");

    const credential = EmailAuthProvider.credential(
      user?.email || "",
      currentPassword
    );

    try {
      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, newPassword);
      setSuccess("Your password was updated");
      setError("");
      reset();
    } catch (err: any) {
      console.error(err);

      if (err.code === "auth/wrong-password") {
        setError("Wrong password");
      } else {
        setError(err.message || "An unknown error occurred");
      }
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Password
        </Typography>

        <Spacer mb={4} />

        {errorMessage && (
          <Alert mb={2} severity="error">
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert mb={2} severity="success">
            {successMessage}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            name="username"
            value={user?.email || ""}
            autoComplete="username"
            style={{ display: "none" }}
            readOnly
          />

          <Controller
            name="currentPassword"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="password"
                label="Current password"
                autoComplete="current-password"
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth
                my={2}
              />
            )}
          />

          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: true,
              minLength: {
                value: minPasswordLength,
                message: `Minimum length of ${minPasswordLength} characters`,
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                type="password"
                label="New password"
                autoComplete="new-password"
                error={Boolean(error)}
                helperText={error?.message}
                fullWidth
                my={2}
              />
            )}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            mt={3}
          >
            Change password
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

function Account() {
  const { user } = useAuth();

  return (
    <React.Fragment>
      <PageHeader title={"Account"} />

      <Grid container spacing={6}>
        <Grid item xs={12} lg={4} xl={3}>
          <About />
          {!!user && user.provider === "password" && <ChangePassword />}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Account;
