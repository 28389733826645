import { FilterList as FilterIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Badge,
  Button,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";

import useFirebase from "../../hooks/useFirebase";
import type { VehicleMake } from "../../types/vehicle.db";
import { range } from "../../util/stdlib";

type FieldFilter = {
  field: string;
  value: string | number;
};

function VehicleFilterPanel({
  onChange,
  value,
}: {
  onChange?: (fields: FieldFilter[]) => void;
  value?: FieldFilter[];
}): JSX.Element {
  const { database } = useFirebase();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [makes, setMakes] = useState<VehicleMake[]>([]);
  const [makeOptions, setMakeOptions] = useState<string[]>([]);
  const [modelOptions, setModelOptions] = useState<string[]>([]);

  const [year, setYear] = useState<string | null>(
    value?.find((v) => v.field === "year")?.value?.toString() ?? null
  );
  const [make, setMake] = useState<string | null>(
    value?.find((v) => v.field === "make")?.value?.toString() ?? null
  );
  const [model, setModel] = useState<string | null>(
    value?.find((v) => v.field === "model")?.value?.toString() ?? null
  );
  const [type, setType] = useState<string | null>(
    value?.find((v) => v.field === "fuel_type")?.value?.toString() ?? null
  );

  // Load makes and models
  useEffect(() => {
    const q = query(collection(database, "vehicle-makes"), orderBy("name"));

    getDocs(q).then((snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      }) as VehicleMake[];
      setMakes(data);
      setMakeOptions(data.map((v) => v.name));
    });
  }, [database]);

  // Filter Model options
  useEffect(() => {
    if (make) {
      const filtered = makes.find((v) => v.name === make)?.models ?? [];
      setModel(null);
      setModelOptions(filtered);
    } else {
      const models = makes
        .flatMap((v) => v.models)
        .sort((a, b) => a.localeCompare(b, "en", { ignorePunctuation: true }));
      setModelOptions(models);
    }
  }, [make, makes]);

  const handleYearChange = useCallback(
    (event, newValue: string | null) => {
      console.debug("Handling year change.");
      onChange?.(
        [
          newValue ? { field: "year", value: parseInt(newValue) } : null,
          make ? { field: "make", value: make } : null,
          model ? { field: "model", value: model } : null,
          type ? { field: "fuel_type", value: type } : null,
        ].filter((v) => !!v) as FieldFilter[]
      );
      setYear(newValue);
    },
    [make, model, type, onChange]
  );
  const handleMakeChange = useCallback(
    (event, newValue: string | null) => {
      console.debug("Handling make change.");
      onChange?.(
        [
          year ? { field: "year", value: parseInt(year) } : null,
          newValue ? { field: "make", value: newValue } : null,
          model ? { field: "model", value: model } : null,
          type ? { field: "fuel_type", value: type } : null,
        ].filter((v) => !!v) as FieldFilter[]
      );
      setMake(newValue);
    },
    [year, model, type, onChange]
  );
  const handleModelChange = useCallback(
    (event, newValue: string | null) => {
      console.debug("Handling model change.");
      onChange?.(
        [
          year ? { field: "year", value: parseInt(year) } : null,
          make ? { field: "make", value: make } : null,
          newValue ? { field: "model", value: newValue } : null,
          type ? { field: "fuel_type", value: type } : null,
        ].filter((v) => !!v) as FieldFilter[]
      );
      setModel(newValue);
    },
    [year, make, type, onChange]
  );
  const handleTypeChange = useCallback(
    (event, newValue: string | null) => {
      console.debug("Handling type change.");
      onChange?.(
        [
          year ? { field: "year", value: parseInt(year) } : null,
          make ? { field: "make", value: make } : null,
          model ? { field: "model", value: model } : null,
          newValue ? { field: "fuel_type", value: newValue } : null,
        ].filter((v) => !!v) as FieldFilter[]
      );
      setType(newValue);
    },
    [year, make, model, onChange]
  );

  const nextYear = new Date().getFullYear() + 1;
  const yearOptions = range(nextYear - 30, nextYear)
    .reverse()
    .map((n) => n.toString());
  const filterCount = [year, make, model, type].filter(
    (v) => v !== null
  ).length;

  return (
    <Stack direction={fullScreen ? "column" : "row"} spacing={4} mb={4}>
      <Autocomplete
        autoHighlight
        autoSelect
        options={yearOptions}
        onChange={handleYearChange}
        value={year}
        size={"small"}
        sx={fullScreen ? {} : { maxWidth: 200 }}
        fullWidth
        renderInput={(params) => <TextField {...params} label="Year" />}
      />
      <Autocomplete
        autoHighlight
        autoSelect
        options={makeOptions}
        onChange={handleMakeChange}
        value={make}
        size={"small"}
        sx={fullScreen ? {} : { maxWidth: 200 }}
        fullWidth
        renderInput={(params) => <TextField {...params} label="Make" />}
      />
      <Autocomplete
        autoHighlight
        autoSelect
        options={modelOptions}
        onChange={handleModelChange}
        value={model}
        size={"small"}
        sx={fullScreen ? {} : { maxWidth: 200 }}
        fullWidth
        renderInput={(params) => <TextField {...params} label="Model" />}
      />
      <Autocomplete
        autoHighlight
        autoSelect
        options={["BEV", "PHEV"]}
        onChange={handleTypeChange}
        value={type}
        size={"small"}
        sx={fullScreen ? {} : { maxWidth: 200 }}
        fullWidth
        renderInput={(params) => <TextField {...params} label="Type" />}
      />
      {!fullScreen && (
        <Badge color={"primary"} badgeContent={filterCount}>
          <Button startIcon={<FilterIcon />} variant={"outlined"} disabled>
            Filters
          </Button>
        </Badge>
      )}
    </Stack>
  );
}

export type { FieldFilter };
export { VehicleFilterPanel as default };
