import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React from "react";

function ConfirmDialog({
  open,
  handleClose,
  handleSubmit,
  cancelButtonText = "Cancel",
  submitButtonText = "OK",
  dialogContent,
}: {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  cancelButtonText?: string;
  submitButtonText?: string;
  dialogContent: string;
}): JSX.Element {
  const onCancel = () => handleClose();
  const onSubmit = () => handleSubmit();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogContent>
        <DialogContentText>{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{cancelButtonText}</Button>
        <Button onClick={onSubmit}>{submitButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
}

export { ConfirmDialog };
