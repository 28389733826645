import {
  Avatar,
  Card,
  CardHeader,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import {
  collection,
  doc,
  getDoc,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useMemo, useReducer } from "react";

import { standardProps } from "../../components/data-grid";
import * as columnTypes from "../../components/data-grid/columnTypes";
import useFirebase from "../../hooks/useFirebase";
import useNavigateOnRowClick from "../../hooks/useNavigateOnRowClick";
import type { WithDocRef } from "../../hooks/useQuery";
import useQuery from "../../hooks/useQuery";
import type { StationReview } from "../../types/station-review.db";
import type { Station } from "../../types/station.db";
import type { User } from "../../types/user.db";

const listReducer = (
  state: User[],
  action: { type: "add"; newItem: User }
): User[] => {
  switch (action.type) {
    case "add":
      return [...state, action.newItem];
  }
  throw Error("Unknown action: " + action.type);
};

function ReviewsCard({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const { database: db } = useFirebase();
  const [items, loading] = useQuery<StationReview>(
    query(
      collection(db, "station-reviews"),
      where("station_id", "==", station.id),
      orderBy("timestamps.created_at", "desc")
    )
  );
  const [users, dispatch] = useReducer(listReducer, []);
  const handleRowClick = useNavigateOnRowClick("/reviews/:id");

  // Load users
  useEffect(() => {
    const unique = [...new Set(items.map((review) => review.user_id))];
    for (const userId of unique) {
      getDoc(doc(db, "users", userId)).then((doc) => {
        if (doc.exists()) {
          const user = { id: doc.id, ...doc.data() } as User;
          dispatch({ type: "add", newItem: user });
        }
      });
    }
  }, [db, items]);

  const columns = useMemo<GridColumns<StationReview>>(
    () => [
      {
        field: "user",
        headerName: "User",
        ...columnTypes.flexDefault,
        minWidth: 200,
        valueGetter: (params) => users.find((v) => v.id === params.row.user_id),
        renderCell: (params) =>
          params.value && (
            <Stack direction={"row"} alignItems={"center"} spacing={2}>
              <Avatar
                src={params.value.photo_url ?? ""}
                sx={{ width: 24, height: 24 }}
              />
              <Typography variant={"subtitle2"}>
                {params.value.name ?? params.value.phone}
              </Typography>
            </Stack>
          ),
      },
      {
        field: "title",
        headerName: "Title",
        ...columnTypes.flexDefault,
        minWidth: 250,
      },
      {
        field: "rating",
        headerName: "Rating",
        ...columnTypes.flexNumber,
        renderCell: (params) => (
          <Rating value={params.value} size="small" readOnly />
        ),
      },
      {
        field: "comment",
        headerName: "Comment",
        ...columnTypes.flexPrimary,
        minWidth: 350,
        renderCell: (params) => (
          <Tooltip title={params.value} enterDelay={500}>
            <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {params.value}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: "created_at",
        headerName: "First created",
        ...columnTypes.createdAt,
      },
    ],
    [users]
  );

  const numberFormatter = Intl.NumberFormat("en-US");

  return (
    <Card>
      <CardHeader
        title={
          station.review_count > 1
            ? `Reviews (${numberFormatter.format(station.review_count ?? 0)})`
            : "Reviews"
        }
      />
      {items.length > 0 && (
        <DataGrid
          rows={items}
          columns={columns}
          {...standardProps}
          pageSize={10}
          sx={{
            ".MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          onRowClick={handleRowClick}
          loading={loading}
        />
      )}
    </Card>
  );
}

export default ReviewsCard;
