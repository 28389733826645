import {
  Card,
  CardContent,
  CardHeader,
  Fade,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

import { hasChildren } from "./index";

function StatCardItem({
  value,
  caption,
  fullWidth,
}: {
  value: React.ReactNode;
  caption?: React.ReactNode;
  fullWidth?: boolean;
}): JSX.Element {
  return (
    <Grid item xs={fullWidth ? 12 : 6} sm={fullWidth ? undefined : true}>
      <Stack>
        <Typography
          component="span"
          variant="h3"
          fontWeight="fontWeightRegular"
        >
          {value}
        </Typography>
        {caption && (
          <Typography variant="caption" color="text.secondary">
            {caption}
          </Typography>
        )}
      </Stack>
    </Grid>
  );
}

function StatCard({
  title,
  subheader,
  action,
  children,
  loading = false,
}: {
  title?: React.ReactNode;
  subheader?: React.ReactNode;
  action?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
  loading?: boolean;
}): JSX.Element {
  return (
    <Card>
      {title && (
        <CardHeader title={title} subheader={subheader} action={action} />
      )}
      {hasChildren(children) && (
        <CardContent>
          <Fade in={!loading} appear={false}>
            <Grid container spacing={6} textAlign="left">
              {children}
            </Grid>
          </Fade>
        </CardContent>
      )}
    </Card>
  );
}

export { StatCard, StatCardItem };
