import { Typography } from "@mui/material";
import React from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Vehicle } from "../../types/vehicle.db";

function VinCard({ vehicle }: { vehicle: WithDocRef<Vehicle> }): JSX.Element {
  return (
    <PartitionedCard title={"VIN"} spacing={4}>
      <PartitionedCardItem title={"Year Code"}>
        <Typography>{vehicle.vin.year_code || "None"}</Typography>
      </PartitionedCardItem>

      <PartitionedCardItem title={"Model Code"}>
        <Typography>
          {vehicle.vin.model_code?.sort()?.join(", ") || "None"}
        </Typography>
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default VinCard;
