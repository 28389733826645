import { Card, CardContent, CardHeader, Chip, Stack } from "@mui/material";
import React from "react";

function ZipcodesCard({
  zips,
  title,
  subheader,
  action,
}: {
  zips: string[];
  title?: string;
  subheader?: string;
  action?: React.ReactNode;
}): JSX.Element {
  return (
    <Card>
      <CardHeader
        title={title || "Zipcodes"}
        subheader={subheader}
        action={action}
      />
      <CardContent>
        <Stack direction="row" flexWrap="wrap" gap={2} spacing={0}>
          {zips?.map((zip, index) => (
            <Chip
              label={zip}
              key={index}
              variant="filled"
              sx={{
                fontFamily: "ui-monospace, Menlo, Monaco, monospace",
              }}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default ZipcodesCard;
