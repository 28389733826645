import { Add as AddIcon } from "@mui/icons-material";
import { Button, Chip, Grid } from "@mui/material";
import type { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { orderBy } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { DataGridCard, standardProps } from "../components/data-grid";
import * as columnTypes from "../components/data-grid/columnTypes";
import QuickSearch from "../components/data-grid/QuickSearch";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import useCollection from "../hooks/useCollection";
import useNavigateOnRowClick from "../hooks/useNavigateOnRowClick";
import type { SavedState } from "../types/navigate";
import type { Network } from "../types/network.db";

import EditNetworkDialog from "./networks/EditNetworkDialog";

const renderStatus = (params: GridRenderCellParams<any, Network>) => {
  if (params.row.is_status_available) {
    return (
      <Chip
        label={"Available"}
        variant={"outlined"}
        color={"success"}
        size={"small"}
      />
    );
  } else {
    return (
      <Chip
        label={"Not Supported"}
        variant={"outlined"}
        color={"info"}
        size={"small"}
      />
    );
  }
};

const renderFeatured = (params: GridRenderCellParams<any, Network>) => {
  if (params.row.is_featured_network) {
    return (
      <Chip
        label={"Featured"}
        variant={"outlined"}
        color={"success"}
        size={"small"}
      />
    );
  } else {
    return (
      <Chip
        label={"Not Featured"}
        variant={"outlined"}
        color={"info"}
        size={"small"}
      />
    );
  }
};

const renderPublished = (params: GridRenderCellParams<any, Network>) => {
  if (params.row.publish) {
    return (
      <Chip
        label={"Published"}
        variant={"outlined"}
        color={"success"}
        size={"small"}
      />
    );
  } else {
    return (
      <Chip
        label={"Not Published"}
        variant={"outlined"}
        color={"info"}
        size={"small"}
      />
    );
  }
};

const columns: GridColumns<Network> = [
  // {
  //   field: "nrel_id",
  //   headerName: "NREL ID",
  //   ...columnTypes.flexDefault,
  // },
  {
    field: "station_count",
    headerName: "Stations",
    ...columnTypes.flexNumber,
    minWidth: 100,
  },
  {
    field: "name",
    headerName: "Name",
    ...columnTypes.flexPrimary,
    minWidth: 200,
  },
  {
    field: "is_status_available",
    headerName: "Status",
    ...columnTypes.flexBoolean,
    renderCell: renderStatus,
  },
  {
    field: "is_featured_network",
    headerName: "Featured",
    ...columnTypes.flexBoolean,
    renderCell: renderFeatured,
  },
  {
    field: "publish",
    headerName: "Published",
    ...columnTypes.flexBoolean,
    renderCell: renderPublished,
  },
  // {
  //   field: "updated_at",
  //   headerName: "Last updated",
  //   ...columnTypes.updatedAt,
  // },
  {
    field: "created_at",
    headerName: "First created",
    ...columnTypes.createdAt,
  },
];

function Networks(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const [savedState, setSavedState] = useState(
    (location?.state as SavedState) || {}
  );
  const [page, setPage] = useState(savedState.page || 0);
  const [items, loading] = useCollection<Network>("networks", orderBy("name"));
  const [filtered, setFiltered] = useState<Network[]>(items);
  const [search, setSearch] = useState<string>(savedState.search || "");
  const [debounced] = useDebounce(search, 200);
  const handleRowClick = useNavigateOnRowClick();

  const [openAddDialog, setOpenAddDialog] = useState(false);

  useEffect(() => {
    navigate("", { replace: true, state: savedState });
  }, [navigate, savedState]);

  // Update saved state
  useEffect(() => {
    setSavedState({ page: page, search: search });
  }, [page, search]);

  // Filter items on quick search input
  useEffect(() => {
    if (debounced) {
      const regex = new RegExp(debounced, "i");
      setFiltered(
        items.filter(
          (item) => regex.test(item.name) || regex.test(item.nrel_id ?? "")
        )
      );
    } else {
      setFiltered(items);
    }
  }, [items, debounced]);

  return (
    <React.Fragment>
      <PageHeader
        title={"Networks"}
        action={
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            fullWidth
            onClick={() => setOpenAddDialog(true)}
          >
            Add
          </Button>
        }
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <QuickSearch value={search} onChange={setSearch} />
              <DataGridCard>
                <DataGrid
                  rows={filtered}
                  columns={columns}
                  {...standardProps}
                  sx={{
                    ".MuiDataGrid-row:hover": {
                      cursor: "pointer",
                    },
                  }}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: "name", sort: "asc" }],
                    },
                  }}
                  page={page}
                  onPageChange={setPage}
                  onRowClick={handleRowClick}
                />
              </DataGridCard>
            </Grid>
          </Grid>

          {openAddDialog && (
            <EditNetworkDialog
              open={true}
              handleClose={() => setOpenAddDialog(false)}
              network={null}
            />
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default Networks;
