import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";

import type { WithDocRef } from "./useQuery";

type DocResult<T> = [data: WithDocRef<T> | null, loading: boolean];

/** Attaches a listener for DocumentSnapshot events. */
function useDoc<T extends { id: string }>(
  collection: string,
  docId: string | null
): DocResult<T> {
  const [data, setData] = useState<WithDocRef<T> | null>(null);
  const [loading, setLoading] = useState(true);

  const docRef = useMemo(
    () => (docId ? doc(getFirestore(), collection, docId) : null),
    [collection, docId]
  );

  useEffect(() => {
    if (docRef !== null) {
      console.debug("Attaching new snapshot listener for %s.", docRef.id);
      setLoading(true);
      return onSnapshot(
        docRef,
        (snapshot) => {
          if (snapshot.exists()) {
            setData({
              ...snapshot.data(),
              id: snapshot.id,
              docRef: snapshot.ref,
            } as WithDocRef<T>);
          }
          setLoading(false);
        },
        (error) => console.warn(error)
      );
    } else {
      setData(null);
      setLoading(false);
    }
  }, [docRef]);

  return [data, loading];
}

export default useDoc;
