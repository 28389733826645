import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Fade,
  Stack,
  Typography,
} from "@mui/material";
import { parsePhoneNumber } from "libphonenumber-js";
import React from "react";

import useDoc from "../hooks/useDoc";
import { cw } from "../theme/variants";
import type { User } from "../types/user.db";
import { formatPostalCode } from "../util/stdlib";

function UserCard({ userId }: { userId: string | null }): JSX.Element {
  const [user, loading] = useDoc<User>("users", userId);
  const phoneNumber = user?.phone ? parsePhoneNumber(user.phone, "US") : null;

  const addressParts = [
    user?.state,
    formatPostalCode(user?.postal_code),
  ].filter((v) => !!v);

  return (
    <Card>
      <CardActionArea href={"/users/" + user?.id} disabled={loading || !user}>
        <CardContent>
          <Fade in={!loading} appear={false}>
            {!user ? (
              <Typography
                variant={"subtitle2"}
                component={"span"}
                color={"text.secondary"}
              >
                Error: User not found
              </Typography>
            ) : (
              <Stack
                direction={"row"}
                spacing={4}
                sx={{
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt={user?.name}
                  src={user?.photo_url ?? ""}
                  sx={{ width: 48, height: 48 }}
                />

                <Stack sx={{ width: "100%" }}>
                  <Stack direction={"row"} alignItems={"baseline"}>
                    <Typography
                      variant={"subtitle1"}
                      component={"span"}
                      sx={{ flexGrow: 1 }}
                    >
                      {user?.name ??
                        user?.email ??
                        phoneNumber?.formatNational()}
                    </Typography>
                    {user?.subscription?.status === "ACTIVE" && (
                      <Typography variant={"caption"} color={cw.brandGreen}>
                        Chargeway+
                      </Typography>
                    )}
                  </Stack>

                  <Typography variant={"body2"} color={"text.secondary"}>
                    {user?.postal_code ? addressParts.join(", ") : "-"}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Fade>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default UserCard;
