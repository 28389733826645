import type { DocumentReference } from "firebase/firestore";
import {
  doc,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";

import type { WithDocRef } from "../../hooks/useQuery";
import type { Vehicle, VehicleMake } from "../../types/vehicle.db";
import {
  adaptersFromConnectors,
  levelsFromConnectors,
} from "../../util/chargeway";
import { convertTimestamps } from "../../util/firebase";

const getNetworks = async () => {
  const db = getFirestore();
  const snapshot = await getDocs(collection(db, "networks"));
  return snapshot.docs;
};

/** Returns a list of new document ids written. */
const addVehiclesByMake = async ({
  year,
  make,
  beaconId,
}: {
  year: number;
  make: WithDocRef<VehicleMake>;
  beaconId: string;
}): Promise<string[]> => {
  const db = getFirestore();
  const snapshot = await getDocs(
    query(
      collection(db, "vehicles"),
      where("year", "==", year),
      where("make", "==", make.name)
    )
  );

  const batch = writeBatch(db);
  const results: string[] = [];
  const networks = await getNetworks();

  // Loop over every vehicle for this make and model year
  for (const d of snapshot.docs) {
    const snapshot = await getDocs(
      query(
        collection(db, "user-vehicles"),
        where("user_id", "==", beaconId),
        where("parent.id", "==", d.id)
      )
    );

    if (snapshot.empty) {
      const parent: Vehicle = { id: d.id, ...convertTimestamps(d.data()) };

      // Important: Make a deep copy of the array.
      let connectors = parent.connectors.map((c) => ({ ...c }));

      // Enable DC fast charging option, where available
      const indexOf = connectors.findIndex(
        (c) => c.is_option && c.type === "DC"
      );
      if (indexOf !== -1) delete connectors[indexOf].is_option;
      connectors = connectors.filter((c) => !c.is_option);

      const data = {
        user_id: beaconId,
        parent,
        name: "My " + parent.make,
        photo_url: parent.photo_url,
        connectors,
        battery: {
          percent_remaining: 50,
          charge_limit: parent.fuel_type === "PHEV" ? 100 : 80,
        },
        range: parent.range[0],
        filters: {
          show_my_vehicle_only: true,
          show_coming_soon: true,
          show_restricted: false,
          show_offline: true,
          power_levels: levelsFromConnectors(connectors),
          adapters: adaptersFromConnectors(connectors),
          show_plug_and_charge_only: false,
          networks: networks.map((doc) => ({ id: doc.id })),
          exclude_networks: [],
        },
        is_selected: false,
        is_used: false,
        timestamps: {
          created_at: serverTimestamp(),
          updated_at: serverTimestamp(),
        },
      };

      // Note: This should be replaced with a POST request to
      // https://api.chargeway.net/admin/beacon/vehicles.
      //
      // Refactoring this call out to the API would help consolidate the
      // vehicle creation logic.
      //
      // This was done hastily due to a major time crunch.
      //
      const docRef = doc(collection(db, "user-vehicles"));
      batch.set(docRef, data);
      results.push(docRef.id);
    } else {
      console.debug("Skipping existing vehicle.", { id: d.id, ...d.data() });
    }
  }
  await batch.commit();
  return results;
};

export default addVehiclesByMake;
