const range = (start: number, stop: number, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const isNumber = (value?: any): boolean => {
  return (value?.toString()?.search(/^[\d.,$]+$/) ?? -1) !== -1;
};

const isNotNumber = (value?: string) => !isNumber(value);

const parseCurrency = (value?: any): number | null => {
  const replaced = value?.toString()?.replaceAll(/[^\d.]/g, "");
  const n = parseFloat(replaced ?? "");
  return Number.isNaN(n) ? null : n;
};

const centsToDollars = (value: number) => {
  return "$" + (value ? value / 100 : 0).toFixed(4);
};

/**
 * @returns the filename (including extension) from the given path.
 */
function filenameFromPath(p: string) {
  return p.split("/").pop();
}

/**
 * @returns the filename and extension (as a tuple) from the given path.
 */
function filenameParts(p: string) {
  const filename = filenameFromPath(p);
  return [filename, filename?.split(".").pop()];
}

/** Matches an app version like 2023.12.5. */
const appVersionRegex = new RegExp(/\d{4}\.\d{1,2}\.\d{1,2}/g);

/** Returns a properly formatted Canadian postal code. */
const formatPostalCode = (value?: string | null) => {
  if (!value) return;
  if (value.length === 6) {
    return (value.substring(0, 3) + " " + value.substring(3)).toUpperCase();
  }
  return value?.toUpperCase();
};

/** Returns a properly formatted Station address. */
const formatStationAddress = (
  station?: {
    address: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
  } | null
): string | null => {
  if (!station) return null;
  const parts = [
    station.address,
    station.city,
    station.state + " " + formatPostalCode(station.postal_code),
    station.country === "US" ? null : station.country,
  ];
  return parts.filter((s) => !!s).join(", ");
};

/**
 * Returns a Lat/Lng tuple from a string. Values may be NaN if parsing failed.
 */
const parseLatLng = (value: string): [number, number] => {
  const [lat, lng] = value.split(",");
  return [Number.parseFloat(lat), Number.parseFloat(lng)];
};

export {
  range,
  isNumber,
  isNotNumber,
  parseCurrency,
  centsToDollars,
  filenameParts,
  filenameFromPath,
  appVersionRegex,
  formatPostalCode,
  formatStationAddress,
  parseLatLng,
};
