import type React from "react";

const hasChildren = (
  children?: React.ReactNode | React.ReactNode[]
): boolean => {
  if (!children) return false;
  if (Array.isArray(children)) {
    return children.filter((value) => Boolean(value)).length > 0;
  }
  return true;
};

export { hasChildren };
