import { Stack, TextField } from "@mui/material";
import { serverTimestamp, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import type { FormEventHandler } from "react";
import { useState } from "react";

import FormDialog from "../../components/dialogs/FormDialog";
import type { WithDocRef } from "../../hooks/useQuery";
import type { InfoSection } from "../../types/chip.db";
import type { Network } from "../../types/network.db";
import type { Station } from "../../types/station.db";

function EditOverviewDialog({
  open,
  handleClose,
  station,
  network,
}: {
  open: boolean;
  handleClose: () => void;
  station: WithDocRef<Station>;
  network: WithDocRef<Network>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const [activate, setActivate] = useState<InfoSection>(() => {
    return (
      station.section_activate ?? { description: station.text_activate ?? "" }
    );
  });
  const [pricing, setPricing] = useState<string | null>(
    station.text_pricing ?? null
  );
  const [hours, setHours] = useState<string | null>(station.text_hours ?? null);
  const [loading, setLoading] = useState(false);

  const updateStation: FormEventHandler = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      await updateDoc(station.docRef, {
        section_activate: activate,
        text_activate: activate.description || null,
        text_pricing: pricing || null,
        text_hours: hours || null,
        "timestamps.updated_at": serverTimestamp(),
      });
      enqueueSnackbar("Updated station");
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update station");
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={"Edit Overview"}
      open={open}
      onCancel={handleClose}
      onSubmit={updateStation}
      isSubmitting={loading}
    >
      <Stack mt={6} spacing={6}>
        <TextField
          label="Activate"
          placeholder={network.text_activate ?? ""}
          defaultValue={activate?.description ?? ""}
          onChange={(params) => {
            setActivate({ ...activate, description: params.target.value });
          }}
          multiline
          maxRows={10}
          fullWidth
        />
        <TextField
          label="Pricing"
          placeholder={network.text_pricing ?? ""}
          defaultValue={pricing}
          onChange={(params) => setPricing(params.target.value)}
          multiline
          maxRows={10}
          fullWidth
        />
        <TextField
          label="Hours"
          placeholder={network.text_hours ?? ""}
          defaultValue={hours}
          onChange={(params) => setHours(params.target.value)}
          multiline
          maxRows={10}
          fullWidth
        />
      </Stack>
    </FormDialog>
  );
}

export default EditOverviewDialog;
