import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import type { DocumentReference, Timestamp } from "firebase/firestore";
import {
  collection,
  doc,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

import useAuth from "../hooks/useAuth";
import useFirebase from "../hooks/useFirebase";
import type { WithDocRef } from "../hooks/useQuery";
import useQuery from "../hooks/useQuery";
import type { AdminNote } from "../types/notes.db";

import { DeleteDialog } from "./dialogs/DeleteDialog";
import { PartitionedCard, PartitionedCardItem } from "./PartitionedCard";

function AddNoteDialog({
  open,
  handleClose,
  docRef,
  note,
}: {
  open: boolean;
  handleClose: () => void;
  docRef: DocumentReference;
  note?: WithDocRef<AdminNote>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  const auth = useAuth();
  const { database: db } = useFirebase();
  const [text, setText] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (open) {
      setText(note?.text ?? ""); // Reset state on open
    }
  }, [open, note]);

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!note) {
        // Create
        const collectionRef = collection(db, "admin-notes");
        await setDoc(doc(collectionRef), {
          parent_id: docRef.id,
          parent_ref: docRef,
          author_id: auth.user!.id,
          author_name: auth.user!.displayName,
          text: text.trim(),
          timestamps: {
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
          },
        });
      } else {
        // Update
        await updateDoc(note.docRef, {
          text: text.trim(),
          "timestamps.updated_at": serverTimestamp(),
        });
      }
      setIsSubmitting(false);
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to add note", {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      fullWidth
    >
      <Box>
        <DialogTitle sx={{ padding: "24px 24px 0" }}>Add a note</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={4} mt={6}>
            <TextField
              placeholder={"Note"}
              multiline={true}
              fullWidth={true}
              value={text}
              onChange={(event) => {
                setText(event.target.value);
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} disabled={isSubmitting}>
            {"Cancel"}
          </Button>
          <Button onClick={onSubmit} disabled={isSubmitting || !text}>
            {"Save"}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

function NoteCardItem({ note }: { note: WithDocRef<AdminNote> }): JSX.Element {
  const auth = useAuth();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  // Note: Timestamps may be null due to pending writes of serverTimestamp.
  const createdAt = (note.timestamps.created_at as Timestamp)?.toDate();

  return (
    <>
      <PartitionedCardItem
        key={note.id}
        title={note.author_name}
        caption={
          createdAt && formatDistanceToNow(createdAt, { addSuffix: true })
        }
      >
        <Stack direction={"column"} spacing={4} flexGrow={1}>
          <Typography flexGrow={1} whiteSpace={"pre-wrap"}>
            {note.text}
          </Typography>
          <Stack direction={"row"} spacing={2}>
            {auth.user?.id === note.author_id && (
              <Button
                startIcon={<EditIcon />}
                color={"secondary"}
                variant={"text"}
                size={"small"}
                onClick={() => setOpenEditDialog(true)}
              >
                Edit
              </Button>
            )}
            <Button
              startIcon={<DeleteIcon />}
              color={"secondary"}
              variant={"text"}
              size={"small"}
              onClick={() => setOpenDeleteDialog(true)}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </PartitionedCardItem>

      <AddNoteDialog
        open={openEditDialog}
        handleClose={() => setOpenEditDialog(false)}
        docRef={note.parent_ref}
        note={note}
      />
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        docRef={note.docRef}
        displayName={note.text.substring(0, 100).trim() + "…"}
        navigateOnClose={false}
      />
    </>
  );
}

function NotesCard({ docRef }: { docRef: DocumentReference }): JSX.Element {
  const { database: db } = useFirebase();
  const [notes, loading] = useQuery<AdminNote>(
    query(
      collection(db, "admin-notes"),
      where("parent_id", "==", docRef.id),
      orderBy("timestamps.created_at", "asc")
    )
  );
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <PartitionedCard
        title={"Notes"}
        loading={loading}
        action={
          <Button
            startIcon={<AddIcon />}
            variant={"text"}
            onClick={() => setOpenDialog(true)}
          >
            Add
          </Button>
        }
      >
        {notes.map((note) => (
          <NoteCardItem key={note.id} note={note} />
        ))}
      </PartitionedCard>

      <AddNoteDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        docRef={docRef}
      />
    </>
  );
}

export default NotesCard;
