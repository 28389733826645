import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { arrayUnion, orderBy, writeBatch } from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import useCollection from "../../hooks/useCollection";
import useFirebase from "../../hooks/useFirebase";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Beacon } from "../../types/beacon.db";
import type { Charger } from "../../types/charger.db";
import { updatedAt } from "../../util/firebase";

function AddChargerDialog({
  open,
  handleClose,
  beacon,
}: {
  open: boolean;
  handleClose: () => void;
  beacon: WithDocRef<Beacon>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  const { database } = useFirebase();
  const [chargers, loading] = useCollection<Charger>(
    "home-chargers",
    orderBy("make"),
    orderBy("model")
  );

  const [makeOptions, setMakeOptions] = useState<string[] | null>();
  const [make, setMake] = useState<string | null>();

  const [results, setResults] = useState<WithDocRef<Charger>[]>();
  const [selected, setSelected] = useState<WithDocRef<Charger>[]>([]);
  const [addMore, setAddMore] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Reset the form when opened
  useEffect(() => {
    if (open) {
      setMake(null);
      setMakeOptions(null);
      setResults([]);
      setSelected([]);
      setAddMore(false);
    }
  }, [open]);

  // Filter models
  useEffect(() => {
    const makeNames = chargers.map((c) => c.make);
    makeNames.sort();

    const unique = [...new Set(makeNames)];
    setMakeOptions(unique);
  }, [chargers]);

  // Filter results
  useEffect(() => {
    setResults(chargers.filter((c) => c.make === make));
  }, [chargers, make]);

  const onCancel = () => handleClose();
  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      const batch = writeBatch(database);
      for (const c of selected) {
        batch.update(c.docRef, { beacons: arrayUnion(beacon.id) });
      }
      batch.update(beacon.docRef, { ...updatedAt });
      await batch.commit();
      if (!addMore) {
        handleClose();
      } else {
        setIsSubmitting(false);
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to add chargers", {
        variant: "error",
      });
    }
  };

  const handleToggle = (charger: any) => {
    const isSelected = !!selected.find((value) => value.id === charger.id);
    if (isSelected) {
      setSelected(selected.filter((value) => value.id !== charger.id));
    } else {
      setSelected([...selected, charger]);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      fullWidth
    >
      <Box>
        <DialogTitle sx={{ padding: "24px 24px 0" }}>Add a charger</DialogTitle>
        <DialogContent>
          <Stack direction="row" spacing={4} mt={6}>
            <Autocomplete
              autoHighlight
              autoSelect
              freeSolo
              options={makeOptions ?? []}
              onChange={(event, newValue) => setMake(newValue)}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Model" autoFocus />
              )}
            />
          </Stack>

          <Collapse in={!!(results && results.length > 0)}>
            {results && results.length > 0 ? (
              <List sx={{ marginTop: 2 }}>
                {results.map((v) => {
                  const isAdded = v.beacons?.includes(beacon.id) ?? false;
                  return (
                    <ListItem
                      key={v.id}
                      secondaryAction={
                        <Checkbox
                          edge="end"
                          onChange={() => handleToggle(v)}
                          checked={
                            !!selected.find((value) => value.id === v.id) ||
                            isAdded
                          }
                          disabled={isAdded}
                          tabIndex={-1}
                          disableRipple
                        />
                      }
                      disablePadding
                    >
                      <ListItemButton
                        role={undefined}
                        onClick={() => handleToggle(v)}
                        disabled={isAdded}
                      >
                        <ListItemText
                          primary={[v.make, v.model].join(" ")}
                          secondary={v.model_variant}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <Typography variant="body1" textAlign="center" mt={6}>
                No Results
              </Typography>
            )}
          </Collapse>
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            label="Add more"
            labelPlacement="end"
            checked={addMore}
            onChange={(event, checked) => setAddMore(checked)}
            control={<Switch size="small" />}
            componentsProps={{
              typography: {
                color: "text.secondary",
              },
            }}
          />
          <Button onClick={onCancel} disabled={isSubmitting}>
            {addMore ? "Done" : "Cancel"}
          </Button>
          <Button onClick={onSubmit} disabled={isSubmitting}>
            {addMore ? "Add" : "Save"}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default AddChargerDialog;
