import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import FormDialog from "../../components/dialogs/FormDialog";
import { Pip } from "../../components/Pip";
import type { WithDocRef } from "../../hooks/useQuery";
import type { ConnectorBadge, Promotion } from "../../types/promotion.db";
import { updatedAt } from "../../util/firebase";

function AddConnectorDialog({
  open,
  handleClose,
  promotion,
}: {
  open: boolean;
  handleClose: () => void;
  promotion: WithDocRef<Promotion>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      reset({
        color: "GREEN",
        level: "7",
      });
    }
  }, [open, reset, promotion]);

  const onSubmit = async (data: any) => {
    try {
      const newConnectors = promotion.connectors ?? [];
      newConnectors.push({ color: data.color, level: parseInt(data.level) });
      newConnectors.sort(
        (a, b) => a.color.localeCompare(b.color) || b.level - a.level
      );
      await updateDoc(promotion.docRef, {
        connectors: newConnectors,
        ...updatedAt,
      });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update promotion", {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={"Add Connector"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Stack direction={"row"} spacing={4}>
          <Controller
            name={"color"}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                options={["BLUE", "GREEN", "RED"]}
                onChange={(event, newValue) => field.onChange(newValue)}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Color" autoFocus />
                )}
              />
            )}
          />
          <Controller
            name={"level"}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <Autocomplete
                {...field}
                options={["1", "2", "3", "4", "5", "6", "7"].reverse()}
                onChange={(event, newValue) => field.onChange(newValue)}
                fullWidth
                renderInput={(params) => (
                  <TextField {...params} label="Level" />
                )}
              />
            )}
          />
        </Stack>
      </Stack>
    </FormDialog>
  );
}

function ConnectorRow({
  connector,
  onDelete,
}: {
  connector: ConnectorBadge;
  onDelete: (connector: ConnectorBadge) => void;
}): JSX.Element {
  const deleteButton = (
    <Button
      size={"small"}
      endIcon={<DeleteIcon fontSize={"inherit"} />}
      onClick={() => onDelete(connector)}
    >
      Delete
    </Button>
  );

  if (connector.color === "BLUE") {
    return (
      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Pip color={"blue"} />
        <Typography variant={"subtitle2"} flexGrow={1}>
          Blue {connector.level}
        </Typography>
        {deleteButton}
      </Stack>
    );
  } else if (connector.color === "GREEN") {
    return (
      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Pip color={"green"} />
        <Typography variant={"subtitle2"} flexGrow={1}>
          Green {connector.level}
        </Typography>
        {deleteButton}
      </Stack>
    );
  } else if (connector.color === "RED") {
    return (
      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Pip color={"red"} />
        <Typography variant={"subtitle2"} flexGrow={1}>
          Red {connector.level}
        </Typography>
        {deleteButton}
      </Stack>
    );
  } else {
    throw new Error("Invalid connector");
  }
}

function ManagePromoConnectorsCard({
  promotion,
}: {
  promotion: WithDocRef<Promotion>;
}): JSX.Element {
  const [open, setOpen] = useState(false);

  const deleteConnector = useCallback(
    async (connector: ConnectorBadge) => {
      const newConnectors = promotion.connectors?.filter(
        (value) =>
          !(value.level === connector.level && value.color === connector.color)
      );
      await updateDoc(promotion.docRef, {
        connectors: newConnectors,
        ...updatedAt,
      });
    },
    [promotion]
  );

  const isEmpty = !promotion.connectors || promotion.connectors.length === 0;

  return (
    <>
      <Card>
        <CardHeader
          title={"Connectors"}
          subheader={"Show connector badges for the promotion."}
          action={
            <Button
              startIcon={<AddIcon fontSize={"inherit"} />}
              fullWidth
              onClick={() => setOpen(true)}
            >
              Add
            </Button>
          }
        />
        {!isEmpty && (
          <CardContent>
            <Stack spacing={2} divider={<Divider />}>
              {promotion.connectors?.map((value) => (
                <ConnectorRow
                  key={`${value.color}:${value.level}`}
                  connector={value}
                  onDelete={deleteConnector}
                />
              ))}
            </Stack>
          </CardContent>
        )}
      </Card>
      {open && (
        <AddConnectorDialog
          open={true}
          handleClose={() => setOpen(false)}
          promotion={promotion}
        />
      )}
    </>
  );
}

export default ManagePromoConnectorsCard;
