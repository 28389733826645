import { Stack, TextField } from "@mui/material";
import {
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import FormDialog from "../../components/dialogs/FormDialog";
import useFirebase from "../../hooks/useFirebase";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Promotion } from "../../types/promotion.db";

function EditPromotionDialog({
  open,
  handleClose,
  promotion,
}: {
  open: boolean;
  handleClose: () => void;
  promotion?: WithDocRef<Promotion>;
}): JSX.Element {
  const { database } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      reset({
        name: promotion?.name ?? "",
        title: promotion?.title ?? "",
        subtitle: promotion?.subtitle ?? "",
        more_info_url: promotion?.more_info_url ?? "",
      });
    }
  }, [open, reset, promotion]);

  const onSubmit = async (data: any) => {
    const collectionRef = collection(database, "promotions");
    const docRef = promotion?.docRef ?? doc(collectionRef);

    try {
      if (promotion) {
        await updateDoc(docRef, {
          name: data.name,
          title: data.title,
          subtitle: data.subtitle,
          more_info_url: data.more_info_url,
          "timestamps.updated_at": serverTimestamp(),
        });
      } else {
        const promotion: Partial<Promotion> = {
          name: data.name,
          title: data.title,
          subtitle: data.subtitle,
          more_info_url: data.more_info_url,
          timestamps: {
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
          },
          active: false,
        };
        await setDoc(docRef, promotion);
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(`Failed to ${promotion ? "update" : "add"} promotion`, {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={promotion ? "Edit Promotion" : "Add Promotion"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Controller
          name={"name"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Name"
              helperText={
                error?.message ?? "A memorable name for your promotion."
              }
              error={Boolean(error)}
              fullWidth
              autoFocus
            />
          )}
        />

        <Controller
          name={"title"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Title"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />

        <Controller
          name={"subtitle"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Subtitle"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />

        <Controller
          name={"more_info_url"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="URL"
              placeholder="https://example.net"
              helperText={
                error?.message ??
                "A link to a website with more details about this promotion."
              }
              error={Boolean(error)}
              inputProps={{ inputMode: "url" }}
              fullWidth
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

export default EditPromotionDialog;
