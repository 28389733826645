import { Card, List, ListItemButton, ListItemText } from "@mui/material";
import React, { useState } from "react";

import { DeleteDialog } from "../../components/dialogs/DeleteDialog";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Network } from "../../types/network.db";

function ManageCard({
  network,
}: {
  network: WithDocRef<Network>;
}): JSX.Element {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  return (
    <>
      <Card>
        <List disablePadding>
          <ListItemButton
            onClick={() => setOpenDeleteDialog(true)}
            sx={{ color: "error.main" }}
          >
            <ListItemText primary={"Delete Network"} />
          </ListItemButton>
        </List>
      </Card>

      {openDeleteDialog && (
        <DeleteDialog
          open={true}
          handleClose={() => setOpenDeleteDialog(false)}
          docRef={network.docRef}
          displayName={network.name}
        />
      )}
    </>
  );
}

export default ManageCard;
