import type { Validate } from "react-hook-form";

import { parseLatLng } from "../util/stdlib";

/** Returns true if the input is a valid ZIP or ZIP+4. */
const isValidZipcode = (value: string) =>
  new RegExp(/^\d{5}(?:-\d{4})?$/gm).test(value);

/** Returns true if the input is a valid Canadian postal code format. */
const isValidCanadianPostalCode = (value: string) =>
  new RegExp(/^\w{3}[-\s]?\w{3}$/gm).test(value);

/** Validates a ZIP, ZIP+5, or Canadian postal code. */
const validatePostalCode: Validate<string> = (value: string) => {
  if (value) {
    if (!(isValidZipcode(value) || isValidCanadianPostalCode(value))) {
      return "Please enter a valid postal code";
    }
  }
  return true;
};

/** Validates a lat/lng coordinate pair separated by a comma. */
const validateCoordinates: Validate<string> = (value: string) => {
  if (value) {
    const [lat, lng] = parseLatLng(value);
    if (Number.isNaN(lat) || Number.isNaN(lng)) {
      return "Please enter a coordinate in the format 45.523064,-122.676483";
    }
  }
  return true;
};

/** Validates an ISO 3166-1 alpha-2 country code. */
const validateCountryCode: Validate<string> = (value: string) => {
  if (value) {
    if (value.length !== 2) {
      return "Please enter a two-letter country code";
    }
  }
  return true;
};

export {
  isValidZipcode,
  isValidCanadianPostalCode,
  validatePostalCode,
  validateCoordinates,
  validateCountryCode,
};
