import styled from "@emotion/styled";
import { Card as MuiCard, LinearProgress, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import type { DataGridProps } from "@mui/x-data-grid";
import React from "react";

import { StandardPagination } from "./pagination";

const standardProps: Partial<DataGridProps> = {
  autoHeight: true,
  disableColumnMenu: true,
  disableColumnFilter: true,
  disableColumnSelector: true,
  disableSelectionOnClick: true,
  hideFooterSelectedRowCount: true,
  pageSize: 10,
  components: {
    Pagination: StandardPagination,
    LoadingOverlay: LinearProgress,
  },
  pagination: true,
};

const Card = styled(MuiCard)(spacing);

function DataGridCard({
  footnote,
  children,
}: {
  footnote?: JSX.Element;
  children: React.ReactNode;
}) {
  return (
    <React.Fragment>
      <Card>{children}</Card>

      {footnote && (
        <Typography variant="body2" mt={4} mb={8} px={2}>
          {footnote}
        </Typography>
      )}
    </React.Fragment>
  );
}

export { standardProps, DataGridCard };
