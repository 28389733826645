import { Grid, Stack, Typography } from "@mui/material";
import React from "react";

import { ConnectorPip } from "../../components/Pip";
import { StatCard } from "../../components/StatCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Vehicle } from "../../types/vehicle.db";

function ChargingCard({
  vehicle,
}: {
  vehicle: WithDocRef<Vehicle>;
}): JSX.Element {
  return (
    <StatCard title={"Charging"}>
      {vehicle.connectors
        .sort(
          (a: any, b: any) =>
            (a.is_adapter ?? false) - (b.is_adapter ?? false) ||
            a.type.localeCompare(b.type) ||
            a.name.localeCompare(b.name)
        )
        .map((c: any, i: number) => (
          <Grid item key={i} xs>
            <Stack direction="row" spacing={3}>
              <ConnectorPip
                connectorType={c.name}
                sx={{
                  position: "relative",
                  top: ".7em",
                }}
              />
              <Stack>
                <Typography
                  component="span"
                  variant="h4"
                  fontWeight="fontWeightRegular"
                >
                  {c.name}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {[c.power / 1000, "kW", c.type].join(" ")}
                </Typography>
                {c.is_adapter && (
                  <Typography variant="caption" color="text.secondary">
                    (adapter)
                  </Typography>
                )}
                {c.is_option && (
                  <Typography variant="caption" color="text.secondary">
                    (option)
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Grid>
        ))}
    </StatCard>
  );
}

export default ChargingCard;
