import { MoreVert as MoreIcon } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Menu,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { updateDoc } from "firebase/firestore";
import { deleteObject, ref, uploadBytes } from "firebase/storage";
import { customAlphabet } from "nanoid";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../components/Loader";
import { ManageImageCard } from "../components/ManageImageCard";
import { PageHeader } from "../components/PageHeader";
import useDoc from "../hooks/useDoc";
import useFirebase from "../hooks/useFirebase";
import type { WithDocRef } from "../hooks/useQuery";
import type { Network } from "../types/network.db";
import {
  FirestoreMenuItem,
  getPathFromUrl,
  getPublicUrl,
  isStorageUrl,
  updatedAt,
} from "../util/firebase";
import { filenameParts } from "../util/stdlib";

import Page404 from "./auth/Page404";
import ManageCard from "./networks/ManageCard";
import GeneralCard from "./networks/GeneralCard";
import OverviewCard from "./networks/OverviewCard";
import SettingsCard from "./networks/SettingsCard";
import SupportCard from "./networks/SupportCard";

function MenuButton({
  network,
}: {
  network: WithDocRef<Network>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  return (
    <React.Fragment>
      {fullScreen ? (
        <Button
          aria-owns={anchorEl ? "menu-network" : undefined}
          aria-haspopup="true"
          variant={"outlined"}
          color={"primary"}
          size={"large"}
          fullWidth
          onClick={toggleMenu}
        >
          Menu
        </Button>
      ) : (
        <IconButton
          aria-owns={anchorEl ? "menu-network" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="medium"
        >
          <MoreIcon />
        </IconButton>
      )}
      <Menu
        id="menu-network"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <FirestoreMenuItem docRef={network.docRef} />
      </Menu>
    </React.Fragment>
  );
}

function NetworkProfile(): JSX.Element {
  const { networkId } = useParams();
  const [network, loading] = useDoc<Network>("networks", networkId!);
  const [uploading, setUploading] = useState(false);

  const { storage } = useFirebase();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  const uploadLogo = useCallback(
    async (files) => {
      try {
        setUploading(true);

        const logoUrl = network?.logo_url;
        if (logoUrl && isStorageUrl(logoUrl)) {
          const storageRef = ref(storage.public, getPathFromUrl(logoUrl));
          await deleteObject(storageRef);
        }

        const first = files[0];
        const [_, ext] = filenameParts(first.name);

        const nanoid = customAlphabet("1234567890abcdef", 10);
        const destFilename = `${nanoid()}.${ext}`;
        const storageRef = ref(
          storage.public,
          ["img", "networks", network!.id, destFilename].join("/")
        );

        const snapshot = await uploadBytes(storageRef, first);
        await updateDoc(network!.docRef, {
          logo_url: getPublicUrl(snapshot),
          ...updatedAt,
        });
      } catch (err) {
        console.error(err);
        enqueueSnackbar("Failed to upload image.", { variant: "error" });
      } finally {
        setUploading(false);
      }
    },
    [storage, network, enqueueSnackbar]
  );

  const removeLogo = useCallback(async () => {
    try {
      setUploading(true);

      const logoUrl = network?.logo_url;
      if (logoUrl && isStorageUrl(logoUrl)) {
        const storageRef = ref(storage.public, getPathFromUrl(logoUrl));
        await deleteObject(storageRef);
      }

      await updateDoc(network!.docRef, {
        logo_url: null,
        ...updatedAt,
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to remove image.", { variant: "error" });
    } finally {
      setUploading(false);
    }
  }, [storage, network, enqueueSnackbar]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : network ? (
        <>
          <PageHeader
            title={network.name}
            href={network.support_website}
            action={<MenuButton network={network} />}
            sectionIsId={true}
          />

          <Grid container spacing={fullScreen ? 4 : 6}>
            <Grid item xs={12} lg={6} xl={4}>
              <Stack spacing={6}>
                <ManageImageCard
                  title={"Logo"}
                  imageUrl={network.logo_url}
                  onDrop={uploadLogo}
                  onRemove={removeLogo}
                  loading={uploading}
                />
                <GeneralCard network={network} />
                <ManageCard network={network} />
                <OverviewCard network={network} />
                <SupportCard network={network} />
              </Stack>
            </Grid>

            <Grid item xs={12} lg={6} xl={8}>
              <Stack spacing={6}>
                <SettingsCard network={network} />
              </Stack>
            </Grid>
          </Grid>
        </>
      ) : (
        <Page404 />
      )}
    </React.Fragment>
  );
}

export { NetworkProfile as default };
