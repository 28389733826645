import { updateDoc } from "firebase/firestore";
import { deleteObject, ref, uploadBytes } from "firebase/storage";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";

import { ManageImageCard } from "../../components/ManageImageCard";
import useFirebase from "../../hooks/useFirebase";
import type { WithDocRef } from "../../hooks/useQuery";
import type { VehicleMake } from "../../types/vehicle.db";
import {
  getPathFromUrl,
  getPublicUrl,
  isStorageUrl,
  updatedAt,
} from "../../util/firebase";

function ManageMakeImageCard({
  make,
}: {
  make: WithDocRef<VehicleMake>;
}): JSX.Element {
  const { storage } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const onUpload = useCallback(
    async (files: File[]) => {
      try {
        setLoading(true);

        const imageUrl = make.logo_url;
        if (imageUrl && isStorageUrl(imageUrl)) {
          const storageRef = ref(storage.public, getPathFromUrl(imageUrl));
          await deleteObject(storageRef);
        }

        const first = files[0];
        const storageRef = ref(
          storage.public,
          ["img", "vehicle-makes", make.id, first.name].join("/")
        );

        const snapshot = await uploadBytes(storageRef, first);
        await updateDoc(make.docRef, {
          logo_url: getPublicUrl(snapshot),
          ...updatedAt,
        });
      } catch (err) {
        console.error(err);
        enqueueSnackbar("Failed to upload image.", { variant: "error" });
      } finally {
        setLoading(false);
      }
    },
    [storage, make, enqueueSnackbar]
  );

  const onRemove = useCallback(async () => {
    try {
      setLoading(true);

      const imageUrl = make.logo_url;
      if (imageUrl && isStorageUrl(imageUrl)) {
        const storageRef = ref(storage.public, getPathFromUrl(imageUrl));
        await deleteObject(storageRef);
      }

      await updateDoc(make.docRef, {
        logo_url: null,
        ...updatedAt,
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to remove image.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  }, [storage, make, enqueueSnackbar]);

  return (
    <ManageImageCard
      title={make.name}
      imageUrl={make.logo_url}
      onDrop={onUpload}
      onRemove={onRemove}
      loading={loading}
    />
  );
}

export default ManageMakeImageCard;
