import { startOfMonth, subMinutes } from "date-fns";
import {
  collection,
  getCountFromServer,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

import { StatCard, StatCardItem } from "../../components/StatCard";
import useLoading from "../../hooks/useLoading";

function MediaCountsCard(): JSX.Element {
  const [totalPhotos, setTotalPhotos] = useState<number | null>();
  const [totalReviews, setTotalReviews] = useState<number | null>();
  const [loading, setLoading] = useLoading();

  useEffect(() => {
    setLoading(true);

    const db = getFirestore();
    const collectionRef = collection(db, "station-photos");

    getCountFromServer(query(collectionRef))
      .then((snapshot) => {
        setTotalPhotos(snapshot.data().count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  useEffect(() => {
    setLoading(true);

    const db = getFirestore();
    const collectionRef = collection(db, "station-reviews");

    getCountFromServer(query(collectionRef))
      .then((snapshot) => {
        setTotalReviews(snapshot.data().count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  const formatter = new Intl.NumberFormat("en-US");

  return (
    <StatCard title={"Photos & Reviews"} loading={loading}>
      <StatCardItem
        value={totalPhotos ? formatter.format(totalPhotos) : ""}
        caption={"Photos"}
      />
      <StatCardItem
        value={totalReviews ? formatter.format(totalReviews) : ""}
        caption={"Reviews"}
      />
    </StatCard>
  );
}

export default MediaCountsCard;
