import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { serverTimestamp, updateDoc } from "firebase/firestore";
import React, { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import useFirebase from "../../hooks/useFirebase";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Beacon } from "../../types/beacon.db";
import { createTrackedUrl } from "../tracked-urls";

function EditRebateOfferDialog({
  open,
  handleClose,
  fullScreen,
  beacon,
}: {
  open: boolean;
  handleClose: () => void;
  fullScreen: boolean;
  beacon: WithDocRef<Beacon>;
}): JSX.Element {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Reset the form each time the dialog is presented
  useEffect(() => {
    if (open) {
      reset({
        rebate_text: beacon.settings.more_info_button_text || "",
        destination_url: beacon.qr_code?.tracked_url?.destination_url || "",
        source: beacon.name,
        medium: "Beacon",
        campaign: beacon.sponsor?.name || "",
      });
    }
  }, [open, reset, beacon]);

  const { database } = useFirebase();
  const onCancel = useCallback(() => handleClose(), [handleClose]);
  const onSubmit = useCallback(
    async (data: any) => {
      if (!beacon.qr_code?.tracked_url) {
        const [tracked, trackedRef] = await createTrackedUrl({
          database,
          destination_url: data.destination_url,
          source: data.source,
          medium: data.medium,
          campaign: data.campaign,
        });

        await updateDoc(beacon.docRef, {
          "settings.more_info_button_text": data.rebate_text,
          qr_code: {
            url: tracked,
            tracked_url: {
              id: trackedRef.id,
              destination_url: data.destination_url,
            },
          },
          "timestamps.updated_at": serverTimestamp(),
        });
      } else {
        await updateDoc(beacon.docRef, {
          "settings.more_info_button_text": data.rebate_text,
          "timestamps.updated_at": serverTimestamp(),
        });
      }
      handleClose();
    },
    [database, handleClose, beacon]
  );

  return (
    <Dialog open={open} fullScreen={fullScreen} fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ padding: "24px 24px 0" }}>
          {`${!beacon.qr_code?.tracked_url ? "Add" : "Edit"} Rebate Offer`}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={4} mt={6}>
            <Controller
              name="rebate_text"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  autoFocus={true}
                  label={"Rebate Text"}
                  error={Boolean(error)}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />

            <Controller
              name="destination_url"
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^https?:\/\//,
                  message: "URL must start with http or https.",
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Destination URL"
                  error={Boolean(error)}
                  helperText={error?.message}
                  disabled={!!beacon.qr_code?.tracked_url}
                  fullWidth
                />
              )}
            />

            <Stack direction={"row"} spacing={4}>
              <Controller
                name="source"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Source"
                    error={Boolean(error)}
                    fullWidth
                    disabled
                  />
                )}
              />
              <Controller
                name="medium"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Medium"
                    error={Boolean(error)}
                    fullWidth
                    disabled
                  />
                )}
              />
              <Controller
                name="campaign"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Campaign"
                    error={Boolean(error)}
                    fullWidth
                    disabled
                  />
                )}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditRebateOfferDialog;
