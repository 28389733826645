import { Grid, Link } from "@mui/material";
import type { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import type { Timestamp } from "firebase/firestore";
import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";

import { DataGridCard, standardProps } from "../components/data-grid";
import * as columnTypes from "../components/data-grid/columnTypes";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import Stats from "../components/Stats";
import useFirebase from "../hooks/useFirebase";

const formatPrice = (params: GridValueFormatterParams<any>) => {
  return "$" + (params.value ? params.value / 100 : 0).toFixed(4);
};

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "State",
    ...columnTypes.flexDefault,
  },
  {
    field: "residential",
    headerName: "Residential",
    ...columnTypes.currency,
    valueGetter: (params) => params.row.prices.residential,
    valueFormatter: formatPrice,
  },
  {
    field: "updated_at",
    headerName: "Last updated",
    ...columnTypes.updatedAt,
  },
];

type Sector = "residential";
type PriceBySector = Record<Sector, number>;

type DataGridItem = {
  id: string;
  name: string;
  prices: PriceBySector;
  timestamps: {
    updated_at: Timestamp;
  };
};

function Footnote(): JSX.Element {
  return (
    <React.Fragment>
      &#42; Electricity rate data sourced from{" "}
      <Link href="https://www.eia.gov/electricity/monthly/epm_table_grapher.php?t=table_5_06_a">
        eia.gov
      </Link>
    </React.Fragment>
  );
}

function ElectricRates(): JSX.Element {
  const { database } = useFirebase();
  const [items, setItems] = useState<DataGridItem[]>([]);
  const [national, setNational] = useState<DataGridItem>();
  const [loading, setLoading] = useState(true);

  const formatPrice = (price: number): string => {
    return (price > 0 ? price / 100 : 0).toFixed(4);
  };

  useEffect(() => {
    const q = collection(database, "electric-rates");
    return onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      const states = data.filter((item) => item.id !== "USA") as DataGridItem[];
      setItems(
        states.sort((a, b) =>
          a.name.localeCompare(b.name, "en", { ignorePunctuation: true })
        )
      );
      setNational(data.find((item) => item.id === "USA") as DataGridItem);
      setLoading(false);
    });
  }, [database]);

  return (
    <React.Fragment>
      <PageHeader title={"Electric Rates"} />

      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Grid container>
            <Grid item xs={12} md={6} lg={3}>
              <Stats
                title="National Average"
                amount={"$ " + formatPrice(national?.prices.residential ?? 0)}
                chip="Today"
              />
            </Grid>
          </Grid>

          <Grid container spacing={6}>
            <Grid item xs={12}>
              <DataGridCard footnote={<Footnote />}>
                <DataGrid rows={items} columns={columns} {...standardProps} />
              </DataGridCard>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default ElectricRates;
