import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import React from "react";

import useLoading from "../../hooks/useLoading";
import type { WithDocRef } from "../../hooks/useQuery";
import type { StationPhoto } from "../../types/station-photo.db";
import { updatedAt } from "../../util/firebase";

function MakeFeaturedDialog({
  open,
  handleClose,
  photo,
}: {
  open: boolean;
  handleClose: () => void;
  photo: WithDocRef<StationPhoto>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useLoading(false);

  const handleMakeFeatured = async () => {
    setLoading(true);
    try {
      const db = getFirestore();
      const collectionRef = collection(db, "station-photos");
      const snapshot = await getDocs(
        query(collectionRef, where("station_id", "==", photo.station_id))
      );
      const batch = writeBatch(db);
      for (const doc of snapshot.docs) {
        console.debug(doc);
        batch.update(doc.ref, {
          featured: doc.id === photo.id,
          sort_order: doc.id === photo.id ? 0 : 99,
          ...updatedAt,
        });
      }
      await batch.commit();
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update station.", { variant: "error" });
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Make this the featured photo?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton onClick={handleMakeFeatured} loading={loading}>
          Make Featured
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export { MakeFeaturedDialog };
