import { Stack } from "@mui/material";
import { serverTimestamp, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import type { FormEventHandler } from "react";
import React, { useCallback, useState } from "react";

import ChipField from "../../components/ChipField";
import FormDialog from "../../components/dialogs/FormDialog";
import type { WithDocRef } from "../../hooks/useQuery";
import type { InfoChip, InfoSection } from "../../types/chip.db";
import type { Network } from "../../types/network.db";
import type { Station } from "../../types/station.db";

function EditChipDialog({
  open,
  handleClose,
  station,
  network,
}: {
  open: boolean;
  handleClose: () => void;
  station: WithDocRef<Station>;
  network: WithDocRef<Network>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const [chip, setChip] = useState<InfoChip | null>();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback<FormEventHandler>(
    async (event) => {
      event.preventDefault();

      const chips: InfoChip[] =
        station.section_activate?.chips ??
        network.section_activate?.chips ??
        [];
      const activate: InfoSection = station.section_activate ?? {
        description: station.text_activate ?? "",
      };
      activate.chips = [...chips];
      if (chip) activate.chips.push(chip);

      try {
        setLoading(true);
        await updateDoc(station.docRef, {
          section_activate: activate,
          "timestamps.updated_at": serverTimestamp(),
        });
        enqueueSnackbar("Updated station");
      } catch (err: any) {
        console.error(err);
        enqueueSnackbar("Failed to update station", { variant: "error" });
      } finally {
        handleClose();
      }
    },
    [chip, enqueueSnackbar, handleClose, station, network]
  );

  const onChange = useCallback((newValue: InfoChip) => {
    setChip(newValue);
  }, []);

  return (
    <FormDialog
      title={"Edit Chip"}
      open={open}
      onCancel={handleClose}
      onSubmit={onSubmit}
      isSubmitting={loading}
    >
      <Stack mt={6} spacing={6}>
        <ChipField onChange={onChange} />
      </Stack>
    </FormDialog>
  );
}

export default EditChipDialog;
