import styled from "@emotion/styled";
import { Launch as LaunchIcon } from "@mui/icons-material";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  IconButton,
  Link,
  Stack,
  Typography as MuiTypography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { spacing } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, useLocation } from "react-router-dom";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

function PageTitle({
  avatar,
  title,
  subtitle,
  overline,
  description,
  action,
  href,
}: {
  avatar?: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  overline?: React.ReactNode;
  description?: React.ReactNode;
  action?: React.ReactNode;
  href?: string | null;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      direction={fullScreen ? "column" : "row"}
      spacing={fullScreen ? 4 : 6}
      sx={{ display: "flex", alignItems: fullScreen ? "left" : "center" }}
    >
      {avatar}
      <Stack
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: "auto",
        }}
      >
        {overline && <Typography variant="h6">{overline}</Typography>}
        <Typography variant="h1">
          {title}{" "}
          {href && (
            <IconButton href={href} target="_blank" rel="noreferrer">
              <LaunchIcon />
            </IconButton>
          )}
        </Typography>
        {subtitle && (
          <Typography
            variant="subtitle1"
            color="text.secondary"
            gutterBottom={!!description}
          >
            {subtitle}
          </Typography>
        )}
        {description && (
          <Typography variant="subtitle1" color="text.secondary">
            {description}
          </Typography>
        )}
      </Stack>

      <Stack
        direction="row"
        spacing={4}
        sx={{
          flexShrink: 0,
          alignItems: "center",
          justifyContent: "end",
        }}
      >
        {action}
      </Stack>
    </Stack>
  );
}

function PageHeader({
  avatar,
  title,
  subtitle,
  overline,
  description,
  action,
  href,
  breadcrumbs = true,
  linkSection = true,
  sectionIsId = false,
  helmet = true,
}: {
  avatar?: React.ReactNode;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  overline?: React.ReactNode;
  description?: React.ReactNode;
  action?: React.ReactNode;
  href?: string | null;
  breadcrumbs?: boolean;
  linkSection?: boolean;
  sectionIsId?: boolean;
  helmet?: boolean;
}): JSX.Element {
  const location = useLocation();
  const pages = location.pathname.split("/").filter((value) => value);

  return (
    <React.Fragment>
      {helmet && <Helmet title={title?.toString()} />}

      <PageTitle
        avatar={avatar}
        title={title}
        subtitle={subtitle}
        overline={overline}
        description={description}
        action={action}
        href={href}
      />

      <Divider my={breadcrumbs ? 4 : 8} />

      {breadcrumbs && (
        <Breadcrumbs aria-label="Breadcrumb" mb={8}>
          {pages.length > 0 && (
            <Link component={NavLink} to="/">
              Home
            </Link>
          )}
          {pages.map((value, index) => {
            const first = index === 0;
            const last = index === pages.length - 1;
            const to = "/" + pages.slice(0, index + 1).join("/");

            const pageTitle = value.replace("-", " ");

            return (
              <Typography
                key={value}
                textTransform={last && sectionIsId ? "none" : "capitalize"}
              >
                {last || (first && !linkSection) ? (
                  pageTitle
                ) : (
                  <Link component={NavLink} to={to}>
                    {pageTitle}
                  </Link>
                )}
              </Typography>
            );
          })}
        </Breadcrumbs>
      )}
    </React.Fragment>
  );
}

export { PageHeader };
