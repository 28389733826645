import { Stack, TextField } from "@mui/material";
import {
  collection,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import FormDialog from "../../components/dialogs/FormDialog";
import useFirebase from "../../hooks/useFirebase";
import type { Sponsor } from "../../types/sponsor.db";

function EditSponsorDialog({
  open,
  handleClose,
  sponsor,
}: {
  open: boolean;
  handleClose: () => void;
  sponsor?: Sponsor;
}): JSX.Element {
  const { database } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      reset({ sponsor_name: sponsor?.name ?? "" });
    }
  }, [open, reset, sponsor]);

  const onSubmit = async (data: any) => {
    const collectionRef = collection(database, "sponsors");

    try {
      if (sponsor) {
        await updateDoc(doc(collectionRef, sponsor.id), {
          name: data.sponsor_name,
          "timestamps.updated_at": serverTimestamp(),
        });
      } else {
        await setDoc(doc(collectionRef), {
          name: data.sponsor_name,
          timestamps: {
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
          },
        });
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(`Failed to ${sponsor ? "update" : "add"} sponsor`, {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={sponsor ? "Edit Sponsor" : "Add Sponsor"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Controller
          name={"sponsor_name"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Name"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

export default EditSponsorDialog;
