import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import useFirebase from "../../hooks/useFirebase";
import type { Incentive } from "../../types/incentive.db";
import { isNumber, parseCurrency } from "../../util/stdlib";

/** Add or edit an incentive details. */
function EditIncentiveDialog({
  open,
  handleClose,
  fullScreen,
  incentive,
}: {
  open: boolean;
  handleClose: () => void;
  fullScreen: boolean;
  incentive?: Incentive;
}): JSX.Element {
  const { database } = useFirebase();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Reset the form each time the dialog is presented
  useEffect(() => {
    if (open) {
      reset({
        name: incentive?.name || "",
        grantor: incentive?.grantor || "",
        grantor_type: incentive?.grantor_type || "",
        amount: incentive?.amount || "",
        typical_amount: incentive?.typical_amount || "",
        description: incentive?.description || "",
        details_url: incentive?.details_url || "",
      });
    }
  }, [open, reset, incentive]);

  const onCancel = () => handleClose();
  const onSubmit = async (data: any) => {
    const collectionRef = collection(database, "vehicle-incentives");
    const incentiveRef = incentive
      ? doc(collectionRef, incentive.id)
      : doc(collectionRef);

    const onCreate = {
      criteria: {},
      income_dependent: false,
      active: false,
      timestamps: {
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      },
    };

    const onUpdate = {
      timestamps: {
        created_at: incentive?.timestamps.created_at ?? serverTimestamp(),
        updated_at: serverTimestamp(),
      },
    };

    const docData = {
      name: data.name,
      grantor: data.grantor,
      grantor_type: data.grantor_type,
      amount: parseCurrency(data.amount),
      typical_amount: data.typical_amount,
      description: data.description,
      details_url: data.details_url,
      ...(!incentive ? onCreate : onUpdate),
    };

    await setDoc(incentiveRef, docData, { merge: true });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ padding: "24px 24px 0" }}>
          {incentive ? "Edit Incentive" : "Add Incentive"}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={4} mt={6}>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Name"
                  error={Boolean(error)}
                  fullWidth
                />
              )}
            />
            <Stack direction="row" spacing={4}>
              <Controller
                name="grantor"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Grantor"
                    placeholder="California"
                    error={Boolean(error)}
                    fullWidth
                  />
                )}
              />

              <Controller
                name="grantor_type"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    label="Type"
                    placeholder="State"
                    error={Boolean(error)}
                    fullWidth
                  />
                )}
              />
            </Stack>

            <Controller
              name="amount"
              control={control}
              rules={{
                validate: (v) => isNumber(v) || "Must be a number.",
                required: true,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Amount"
                  helperText={
                    error?.message || "When variable, enter the maximum amount."
                  }
                  error={Boolean(error)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              )}
            />

            <Controller
              name="typical_amount"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Typical Amount"
                  placeholder="Up to $7,500"
                  helperText="A short description of what an applicant might typically save."
                  error={Boolean(error)}
                  fullWidth
                />
              )}
            />

            <Controller
              name="description"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Description"
                  helperText="An optional description of the incentive and it's criteria."
                  error={Boolean(error)}
                  multiline
                  fullWidth
                />
              )}
            />

            <Controller
              name="details_url"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Reference"
                  placeholder="https://example.net"
                  helperText="An optional link to a website explaining the incentive details."
                  error={Boolean(error)}
                  inputProps={{ inputMode: "url" }}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
export default EditIncentiveDialog;
