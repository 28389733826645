import type { SelectChangeEvent } from "@mui/material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";

import type { InfoChip, InfoChipIcon } from "../types/chip.db";

function ChipIconSelect({
  value,
  onChange,
}: {
  value?: InfoChipIcon;
  onChange: (event: SelectChangeEvent) => void;
}): JSX.Element {
  return (
    <FormControl fullWidth>
      <InputLabel id="chip-icon-select-label">Icon</InputLabel>
      <Select
        labelId="chip-icon-select-label"
        id="chip-icon-select"
        defaultValue={"chip-app"}
        value={value}
        label="Icon"
        onChange={onChange}
      >
        <MenuItem value={"chip-app"}>Mobile App</MenuItem>
        <MenuItem value={"chip-call"}>Call Ahead</MenuItem>
        <MenuItem value={"chip-card"}>Credit/Debit Card</MenuItem>
        <MenuItem value={"chip-free"}>Free</MenuItem>
        <MenuItem value={"chip-rivian"}>Rivian</MenuItem>
        <MenuItem value={"chip-tesla"}>Tesla</MenuItem>
      </Select>
    </FormControl>
  );
}

function ChipField({
  value,
  onChange,
}: {
  value?: InfoChip;
  onChange: (newValue: InfoChip) => void;
}): JSX.Element {
  const [icon, setIcon] = useState<InfoChipIcon>(value?.icon ?? "chip-app");
  const [label, setLabel] = useState(value?.label ?? "");

  useEffect(() => {
    const newValue: InfoChip = { disabled: false, ...value, icon, label };
    onChange(newValue);
  }, [value, onChange, icon, label]);

  const onIconChange = useCallback((event) => {
    setIcon(event.target.value);
  }, []);

  const onLabelChange = useCallback((event) => {
    setLabel(event.target.value);
  }, []);

  return (
    <Stack direction={"row"} spacing={4}>
      <ChipIconSelect value={icon} onChange={onIconChange} />
      <TextField
        label="Label"
        defaultValue={label}
        onChange={onLabelChange}
        fullWidth
      />
    </Stack>
  );
}

export default ChipField;
