import { Stack, Typography } from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import { styled, useTheme } from "@mui/material/styles";
import React, { useMemo } from "react";

import type { Connector } from "../types/station.db";

const StyledPip = styled("div")({
  borderRadius: "50%",
  flexShrink: 0,
  flexGrow: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

function Pip({
  color,
  size = "medium",
  sx = [],
}: {
  color: "green" | "blue" | "red";
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
}): JSX.Element {
  const theme = useTheme();
  return (
    <StyledPip
      sx={[
        {
          width: theme.spacing(size === "small" ? 2 : 3),
          height: theme.spacing(size === "small" ? 2 : 3),
          backgroundColor: theme.palette[color].main,
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
}

function ConnectorPip({
  connectorType,
  size = "medium",
  sx = [],
}: {
  connectorType: string;
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
}): JSX.Element {
  const getColor = (type: string): "red" | "blue" | "green" => {
    switch (type.toUpperCase()) {
      case "NACS":
      case "TESLA":
        return "red";
      case "CHADEMO":
        return "blue";
      default:
        return "green";
    }
  };

  return <Pip color={getColor(connectorType)} size={size} sx={sx} />;
}

function StationConnectorPip({
  connector,
}: {
  connector: Connector;
}): JSX.Element {
  const theme = useTheme();
  const size = theme.spacing(7);
  const backgroundColor = useMemo(() => {
    switch (connector.name) {
      case "CHAdeMO":
        return theme.palette["blue"].main;
      case "CCS":
      case "J1772":
        return theme.palette["green"].main;
      case "NACS":
      case "Tesla":
        return theme.palette["red"].main;
    }
  }, [connector, theme]);
  const borderColor = useMemo(() => {
    switch (connector.name) {
      case "CHAdeMO":
        return theme.palette["blue"].dark;
      case "CCS":
      case "J1772":
        return theme.palette["green"].dark;
      case "NACS":
      case "Tesla":
        return theme.palette["red"].dark;
    }
  }, [connector, theme]);

  return (
    <StyledPip
      sx={[
        {
          width: size,
          height: size,
          backgroundColor,
          borderColor,
          borderWidth: "1.5px",
          borderStyle: "solid",
        },
      ]}
    >
      <Typography
        component={"span"}
        variant={"h5"}
        fontWeight={600}
        color={"white"}
      >
        {connector.level}
      </Typography>
    </StyledPip>
  );
}

const renderPlugType = (params: any) => {
  return (params.value as string).split(",").map((value, i) => (
    <Stack direction="row" alignItems={"center"} key={i}>
      <ConnectorPip
        connectorType={params.value}
        size="small"
        sx={{
          marginRight: 1.5,
        }}
      />
      {params.value}
    </Stack>
  ));
};

export { Pip, ConnectorPip, StationConnectorPip, renderPlugType };
