import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Fade,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";

import useLoading from "../hooks/useLoading";
import type { WithDocRef } from "../hooks/useQuery";
import type { Network } from "../types/network.db";
import type { Station } from "../types/station.db";

function StationCard({ stationId }: { stationId?: string }): JSX.Element {
  const [station, setStation] = useState<WithDocRef<Station> | null>(null);
  const [network, setNetwork] = useState<WithDocRef<Network> | null>(null);
  const [loading, setLoading] = useLoading();

  // Load Station
  useEffect(() => {
    if (!stationId) return;

    const db = getFirestore();
    const collectionRef = collection(db, "stations");

    if (Number.isInteger(Number(stationId))) {
      const q = query(
        collectionRef,
        where("khloud_id", "==", parseInt(stationId))
      );
      setLoading(true);
      getDocs(q)
        .then((snapshot) => {
          if (snapshot.size === 1) {
            const first = snapshot.docs[0];
            setStation({
              id: first.id,
              docRef: first.ref,
              ...first.data(),
            } as WithDocRef<Station>);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(true);
      getDoc(doc(collectionRef, stationId))
        .then((snapshot) => {
          if (snapshot.exists()) {
            setStation({
              id: snapshot.id,
              docRef: snapshot.ref,
              ...snapshot.data(),
            } as WithDocRef<Station>);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [stationId, setLoading]);

  // Load Network
  useEffect(() => {
    if (!station) return;

    const db = getFirestore();
    const collectionRef = collection(db, "networks");

    setLoading(true);
    getDoc(doc(collectionRef, station.network_id))
      .then((snapshot) => {
        if (snapshot.exists()) {
          setNetwork({
            id: snapshot.id,
            docRef: snapshot.ref,
            ...snapshot.data(),
          } as WithDocRef<Network>);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [station, setLoading]);

  return (
    <Card>
      <CardActionArea
        href={"/stations/" + station?.id}
        disabled={loading || !station}
      >
        <CardContent>
          <Fade in={!loading} appear={false}>
            {!station ? (
              <Typography
                variant={"subtitle2"}
                component={"span"}
                color={"text.secondary"}
              >
                Error: Station not found
              </Typography>
            ) : (
              <Stack
                direction={"row"}
                alignItems={"center"}
                sx={{ width: "100%" }}
              >
                <Stack flexGrow={1}>
                  <Typography variant={"subtitle1"} component={"span"}>
                    {station.name}
                  </Typography>
                  <Typography variant={"body2"} color={"text.secondary"}>
                    {station.address}
                  </Typography>
                  <Typography variant={"body2"} color={"text.secondary"}>
                    {station.country === "US"
                      ? `${station.city}, ${station.state} ${station.postal_code}`
                      : `${station.city}, ${station.state} ${station.postal_code}, ${station.country}`}
                  </Typography>

                  <Stack direction={"row"} spacing={1} sx={{ marginTop: 1 }}>
                    <Rating
                      value={station.rating}
                      size="small"
                      precision={0.5}
                      readOnly
                    />
                    <Typography variant={"caption"} color={"text.secondary"}>
                      {station.rating.toFixed(1)}{" "}
                      {station.review_count === 1
                        ? "(1 review)"
                        : `(${station.review_count} reviews)`}
                    </Typography>
                  </Stack>
                </Stack>

                {network && (
                  <Box
                    component="img"
                    sx={{ maxWidth: 150, maxHeight: 37.5 }}
                    alt={network.name}
                    src={network.logo_url ?? ""}
                  />
                )}
              </Stack>
            )}
          </Fade>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default StationCard;
