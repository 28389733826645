import { Button, Link } from "@mui/material";
import React, { useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";
import { formatStationAddress } from "../../util/stdlib";

import EditLocationDialog from "./EditLocationDialog";

function LocationCard({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const addressUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    formatStationAddress(station) ?? ""
  )}`;
  const latLngUrl = `https://www.google.com/maps/search/?api=1&query=${station.latitude}%2C${station.longitude}`;

  return (
    <>
      <PartitionedCard
        title={"Location"}
        spacing={4}
        action={
          <Button
            onClick={() => {
              setOpenEditDialog(true);
            }}
          >
            Edit
          </Button>
        }
      >
        <PartitionedCardItem title={"Address"}>
          <Link href={addressUrl} target={"_blank"}>
            {formatStationAddress(station)}
          </Link>
        </PartitionedCardItem>
        <PartitionedCardItem title={"Coordinates"}>
          <Link href={latLngUrl} target={"_blank"}>
            {station.latitude},{station.longitude}
          </Link>
        </PartitionedCardItem>
        <PartitionedCardItem title={"Time Zone"}>
          {station.time_zone || "-"}
        </PartitionedCardItem>
      </PartitionedCard>
      {openEditDialog && (
        <EditLocationDialog
          open={true}
          handleClose={() => setOpenEditDialog(false)}
          station={station}
        />
      )}
    </>
  );
}

export default LocationCard;
