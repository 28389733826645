import { Clear as ClearIcon, Search as SearchIcon } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";

function QuickSearch({
  placeholder = "Search\u2026",
  value,
  onChange,
}: {
  placeholder?: string;
  value: string;
  onChange: (newValue: string) => void;
}): JSX.Element {
  return (
    <TextField
      autoComplete={"off"}
      variant={"outlined"}
      placeholder={placeholder}
      sx={{ marginBottom: 4 }}
      inputProps={{ tabIndex: 1, inputMode: "search" }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon color={"info"} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => onChange("")} disabled={!value}>
              <ClearIcon fontSize={"small"} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      fullWidth
    />
  );
}

export default QuickSearch;
