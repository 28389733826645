import { Stack, Typography } from "@mui/material";
import { formatDistanceToNowStrict } from "date-fns";
import { orderBy } from "firebase/firestore";
import type { Timestamp } from "firebase/firestore";
import React from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import useCollection from "../../hooks/useCollection";
import type { AppVersion } from "../../types/app.db";

function AppVersionsCard(): JSX.Element {
  const [versions, loading] = useCollection<AppVersion>(
    "app-versions",
    orderBy("title")
  );

  return (
    <PartitionedCard title={"App Versions"} spacing={2} loading={loading}>
      {versions.map((v) => (
        <PartitionedCardItem key={v.id} title={v.title}>
          <Stack>
            <Typography variant={"subtitle2"}>{v.latest_version}</Typography>
            <Typography variant={"caption"} color={"text.secondary"}>
              Last updated{" "}
              {formatDistanceToNowStrict(
                (v.timestamps.updated_at as Timestamp).toDate(),
                { addSuffix: true }
              )}
            </Typography>
          </Stack>
        </PartitionedCardItem>
      ))}
    </PartitionedCard>
  );
}

export default AppVersionsCard;
