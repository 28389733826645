import styled from "@emotion/styled";
import { Button as MuiButton, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet-async";

import useAuth from "../../hooks/useAuth";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const Button = styled(MuiButton)(spacing);

function Page403() {
  const auth = useAuth();
  return (
    <Wrapper>
      <Helmet title="Forbidden" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        Forbidden
      </Typography>
      <Typography component="h2" variant="subtitle1" align="center">
        You must be an administrator to access this content.
      </Typography>

      <Button
        onClick={() => auth.signOut()}
        variant="contained"
        color="secondary"
        mt={6}
      >
        Sign out
      </Button>
    </Wrapper>
  );
}

export default Page403;
