import {
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { deleteField, serverTimestamp, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useState } from "react";

import { ConfirmDialog } from "../../components/dialogs/ConfirmDialog";
import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import useDoc from "../../hooks/useDoc";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Beacon } from "../../types/beacon.db";
import type { TrackedUrl } from "../../types/tracked.db";

import EditRebateOfferDialog from "./EditRebateOfferDialog";

function RebateOfferCard({
  beacon,
}: {
  beacon: WithDocRef<Beacon>;
}): JSX.Element {
  const [trackedUrl, loading] = useDoc<TrackedUrl>(
    "tracked-urls",
    beacon.qr_code?.tracked_url?.id ?? null
  );

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();

  const showConfirmDialog = () => setOpenConfirm(true);
  const hideConfirmDialog = () => setOpenConfirm(false);
  const showAddDialog = () => setOpenAdd(true);
  const hideAddDialog = () => setOpenAdd(false);

  const onRemove = async () => {
    try {
      await updateDoc(beacon.docRef, {
        qr_code: deleteField(),
        "settings.more_info_button_text": "",
        "timestamps.updated_at": serverTimestamp(),
      });
      enqueueSnackbar("Removed QR Code");
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to remove QR Code.", { variant: "error" });
    } finally {
      hideConfirmDialog();
    }
  };

  return (
    <>
      <PartitionedCard
        title={"Rebate Offer (QR Code)"}
        subheader={"Home Chargers"}
        action={
          <>
            <Button variant={"text"} onClick={showAddDialog}>
              Edit
            </Button>
            {beacon.qr_code && (
              <Button
                variant={"text"}
                color={"error"}
                onClick={showConfirmDialog}
              >
                Remove
              </Button>
            )}
          </>
        }
        spacing={4}
      >
        <PartitionedCardItem title={"Rebate Text"}>
          <Typography>
            {beacon.settings.more_info_button_text || "None"}
          </Typography>
        </PartitionedCardItem>

        <PartitionedCardItem title={"Destination"}>
          {trackedUrl ? (
            <Link href={trackedUrl.destination_url} target={"_blank"}>
              {trackedUrl.destination_url}
            </Link>
          ) : (
            <Typography>None</Typography>
          )}
        </PartitionedCardItem>

        <PartitionedCardItem title={"Visits"}>
          <Typography>{trackedUrl?.visits || "None"}</Typography>
        </PartitionedCardItem>
      </PartitionedCard>

      <EditRebateOfferDialog
        open={openAdd}
        handleClose={hideAddDialog}
        fullScreen={fullScreen}
        beacon={beacon}
      />
      <ConfirmDialog
        open={openConfirm}
        handleClose={hideConfirmDialog}
        handleSubmit={onRemove}
        submitButtonText={"Remove"}
        dialogContent={"Remove Rebate Offer?"}
      />
    </>
  );
}

export default RebateOfferCard;
