import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  MoreVert as MoreIcon,
} from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { DeleteDialog } from "../components/dialogs/DeleteDialog";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import useDoc from "../hooks/useDoc";
import type { WithDocRef } from "../hooks/useQuery";
import type { Sponsor } from "../types/sponsor.db";
import { FirestoreMenuItem } from "../util/firebase";

import Page404 from "./auth/Page404";
import BeaconList from "./sponsors/BeaconList";
import EditSponsorDialog from "./sponsors/EditSponsorDialog";
import ManageAttractBackgroundCard from "./sponsors/ManageAttractBackgroundCard";
import ManageAttractLogoCard from "./sponsors/ManageAttractLogoCard";
import ManageIconCard from "./sponsors/ManageIconCard";
import ManageLargePromoCard from "./sponsors/ManageLargePromoCard";

function MoreOptionsMenu({
  sponsor,
}: {
  sponsor: WithDocRef<Sponsor>;
}): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <IconButton
        aria-owns={anchorEl ? "menu-sponsor" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        size="medium"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="menu-sponsor"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
      >
        <FirestoreMenuItem docRef={sponsor.docRef} />
        <Divider />
        <MenuItem
          onClick={() => {
            setOpenEditDialog(true);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <EditSponsorDialog
        open={openEditDialog}
        handleClose={() => setOpenEditDialog(false)}
        sponsor={sponsor}
      />
      <DeleteDialog
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        docRef={sponsor.docRef}
        displayName={sponsor.name}
      />
    </React.Fragment>
  );
}

function SponsorProfile(): JSX.Element {
  const { sponsorId } = useParams();
  const [sponsor, loading] = useDoc<Sponsor>("sponsors", sponsorId!);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : sponsor ? (
        <>
          <PageHeader
            title={sponsor.name}
            action={<MoreOptionsMenu sponsor={sponsor} />}
            sectionIsId={true}
          />

          <Grid container spacing={6}>
            <Grid item xs={12}>
              <BeaconList sponsor={sponsor} />
            </Grid>

            <Grid item xs={12}>
              <ManageIconCard sponsor={sponsor} />
            </Grid>

            <Grid item xs={12}>
              <ManageAttractLogoCard sponsor={sponsor} />
            </Grid>

            <Grid item xs={12}>
              <ManageAttractBackgroundCard sponsor={sponsor} />
            </Grid>

            <Grid item xs={12}>
              <ManageLargePromoCard sponsor={sponsor} />
            </Grid>
          </Grid>
        </>
      ) : (
        <Page404 />
      )}
    </React.Fragment>
  );
}

export default SponsorProfile;
