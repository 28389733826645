import {
  AdsClick as PromotionsIcon,
  Badge as SponsorsIcon,
  Dock as BeaconsIcon,
  ElectricalServices as ChargersIcon,
  ElectricBolt as UtilitiesIcon,
  ElectricCar as VehiclesIcon,
  EvStation as StationsIcon,
  Home as HomeIcon,
  Link as LinksIcon,
  People as UsersIcon,
  PersonAddAlt1 as SubscriptionsIcon,
  PhotoLibrary as PhotosIcon,
  Reviews as ReviewsIcon,
  Sell as IncentivesIcon,
  Spoke as NetworksIcon,
  Storefront as DealersIcon,
  TrendingUp as TrendingUpIcon,
} from "@mui/icons-material";

import type { SidebarItemsType } from "../../types/sidebar";

/** Note: Routes are set in routes.tsx. */

const main = {
  title: "Main",
  pages: [
    {
      title: "Home",
      icon: HomeIcon,
      href: "/",
    },
    {
      title: "Networks",
      icon: NetworksIcon,
      href: "/networks",
    },
    {
      title: "Photos",
      icon: PhotosIcon,
      href: "/photos",
    },
    {
      title: "Reviews",
      icon: ReviewsIcon,
      href: "/reviews",
    },
    {
      title: "Stations",
      icon: StationsIcon,
      href: "/stations",
    },
    {
      title: "Subscriptions",
      icon: SubscriptionsIcon,
      href: "/subscriptions",
    },
    {
      title: "Users",
      icon: UsersIcon,
      href: "/users",
    },
    {
      title: "Vehicles",
      icon: VehiclesIcon,
      href: "/vehicles",
    },
  ] as SidebarItemsType[],
};

const beacons = {
  title: "Beacon",
  pages: [
    {
      title: "Beacons",
      icon: BeaconsIcon,
      href: "/beacons",
    },
    {
      title: "Chargers",
      icon: ChargersIcon,
      href: "/chargers",
    },
    {
      title: "Incentives",
      icon: IncentivesIcon,
      href: "/incentives",
    },
    {
      title: "Rates",
      icon: TrendingUpIcon,
      href: "/rates",
      children: [
        {
          title: "Electric Rates",
          href: "/rates/electric-rates",
        },
        {
          title: "Fuel Prices",
          href: "/rates/fuel-prices",
        },
      ],
    },
    {
      title: "Sponsors",
      icon: SponsorsIcon,
      href: "/sponsors",
    },
    {
      title: "Utilities",
      icon: UtilitiesIcon,
      href: "/utilities",
    },
  ],
};

const other = {
  title: "Other",
  pages: [
    {
      title: "Dealers",
      icon: DealersIcon,
      href: "/dealers",
    },
    {
      title: "Promotions",
      icon: PromotionsIcon,
      href: "/promotions",
    },
    {
      title: "Tracked Links",
      icon: LinksIcon,
      href: "/tracked-links",
    },
  ],
};

const navItems = [main, beacons, other];

export default navItems;
