import { Tooltip, Typography } from "@mui/material";
import { format, formatDistanceToNowStrict } from "date-fns";
import type { Timestamp } from "firebase/firestore";
import React from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { StationPhoto } from "../../types/station-photo.db";

function GeneralCard({
  photo,
}: {
  photo: WithDocRef<StationPhoto>;
}): JSX.Element {
  // Note: Timestamps may be null due to pending writes of serverTimestamp.
  const createdAt = (photo.timestamps.created_at as Timestamp)?.toDate();
  const updatedAt = (photo.timestamps.updated_at as Timestamp)?.toDate();

  return (
    <>
      <PartitionedCard title={"General"} spacing={4}>
        <PartitionedCardItem title={"First created"}>
          {createdAt ? (
            <Tooltip title={format(createdAt, "PPPPpp")} enterDelay={500}>
              <Typography>
                {formatDistanceToNowStrict(createdAt, { addSuffix: true })}
              </Typography>
            </Tooltip>
          ) : (
            <Typography>None</Typography>
          )}
        </PartitionedCardItem>

        <PartitionedCardItem title={"Last updated"}>
          {updatedAt ? (
            <Tooltip title={format(updatedAt, "PPPPpp")} enterDelay={500}>
              <Typography>
                {formatDistanceToNowStrict(updatedAt, { addSuffix: true })}
              </Typography>
            </Tooltip>
          ) : (
            <Typography>None</Typography>
          )}
        </PartitionedCardItem>
      </PartitionedCard>
    </>
  );
}

export default GeneralCard;
