import { LaunchRounded, MoreVert as MoreIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import Loader from "../components/Loader";
import NotesCard from "../components/NotesCard";
import { PageHeader } from "../components/PageHeader";
import useLoading from "../hooks/useLoading";
import type { WithDocRef } from "../hooks/useQuery";
import type { Network } from "../types/network.db";
import type { Station } from "../types/station.db";
import { FirestoreMenuItem } from "../util/firebase";
import { formatStationAddress } from "../util/stdlib";

import Page404 from "./auth/Page404";
import AdvancedCard from "./stations/AdvancedCard";
import ConnectorsCard from "./stations/ConnectorsCard";
import GeneralCard from "./stations/GeneralCard";
import LocationCard from "./stations/LocationCard";
import ManageCard from "./stations/ManageCard";
import OverviewCard from "./stations/OverviewCard";
import PhotoMosaicCard from "./stations/PhotoMosaicCard";
import ReviewsCard from "./stations/ReviewsCard";
import SettingsCard from "./stations/SettingsCard";
import SupportCard from "./stations/SupportCard";

function MenuButton({
  station,
}: {
  station: WithDocRef<Station>;
}): JSX.Element {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState<any>(null);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);

  return (
    <React.Fragment>
      {fullScreen ? (
        <Button
          aria-owns={anchorEl ? "menu-station" : undefined}
          aria-haspopup="true"
          variant={"outlined"}
          color={"primary"}
          size={"large"}
          fullWidth
          onClick={toggleMenu}
        >
          Menu
        </Button>
      ) : (
        <IconButton
          aria-owns={anchorEl ? "menu-station" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="medium"
        >
          <MoreIcon />
        </IconButton>
      )}
      <Menu
        id="menu-station"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{
          sx: { width: 240, maxWidth: "100%" },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem
          component={Link}
          href={`https://afdc.energy.gov/stations#/station/${station.nrel_id}`}
          target={"_blank"}
          disabled={!station.nrel_id}
        >
          <ListItemIcon>
            <LaunchRounded fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={"View in AFDC"} secondary={station.nrel_id} />
        </MenuItem>
        <FirestoreMenuItem docRef={station.docRef} />
      </Menu>
    </React.Fragment>
  );
}

function StationProfile(): JSX.Element {
  const { docId } = useParams();
  const [station, setStation] = useState<WithDocRef<Station>>();
  const networkId = useMemo(() => station?.network_id ?? null, [station]);
  const [network, setNetwork] = useState<WithDocRef<Network>>();
  const [loading, setLoading] = useLoading();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Debug
  useEffect(() => {
    if (station) {
      console.debug("Station did update.", station);
    }
  }, [station]);

  // Load Station
  useEffect(() => {
    if (!docId) return;

    const db = getFirestore();
    const docRef = doc(db, "stations", docId);

    setLoading(true);
    return onSnapshot(
      docRef,
      (doc) => {
        if (doc.exists()) {
          setStation({
            id: doc.id,
            docRef: doc.ref,
            ...doc.data(),
          } as WithDocRef<Station>);
        }
        setLoading(false);
      },
      (err) => {
        console.error(err);
        setLoading(false);
      }
    );
  }, [docId, setLoading]);

  // Load Network
  useEffect(() => {
    if (!networkId) return;

    const db = getFirestore();
    const docRef = doc(db, "networks", networkId);

    setLoading(true);
    return onSnapshot(
      docRef,
      (doc) => {
        if (doc.exists()) {
          setNetwork({
            id: doc.id,
            docRef: doc.ref,
            ...doc.data(),
          } as WithDocRef<Network>);
        }
        setLoading(false);
      },
      (err) => {
        console.error(err);
        setLoading(false);
      }
    );
  }, [networkId, setLoading]);

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : station && network ? (
        <>
          <PageHeader
            overline={
              <Link href={`/networks/${network.id}`}>{network.name}</Link>
            }
            title={station.name}
            subtitle={formatStationAddress(station)}
            action={
              <>
                {!fullScreen && (
                  <Box
                    component="img"
                    sx={{ maxWidth: 150, maxHeight: 37.5 }}
                    alt={network.name}
                    src={network.logo_url ?? ""}
                  />
                )}
                <MenuButton station={station} />
              </>
            }
            sectionIsId={true}
          />

          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} xl={4}>
              <Stack spacing={6}>
                <GeneralCard station={station} />
                <ManageCard station={station} />
                <LocationCard station={station} />
                <OverviewCard station={station} network={network} />
                <SupportCard station={station} network={network} />
              </Stack>
            </Grid>

            <Grid item xs={12} lg={6} xl={8}>
              <Stack spacing={6}>
                <SettingsCard station={station} />
                <AdvancedCard station={station} />
                <NotesCard docRef={station.docRef} />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack spacing={6}>
                <ConnectorsCard station={station} />
                <ReviewsCard station={station} />
                <PhotoMosaicCard station={station} />
              </Stack>
            </Grid>
          </Grid>
        </>
      ) : (
        <Page404
          subtitle={
            !station
              ? "We couldn't find that station."
              : "We couldn't find the network for this station."
          }
        />
      )}
    </React.Fragment>
  );
}

export { StationProfile as default };
