import { searchClient } from "@algolia/client-search";
import { Avatar, Grid, Stack, Typography } from "@mui/material";
import type { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { parsePhoneNumber } from "libphonenumber-js";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import {
  highlightResultGetter,
  renderHighlightResult,
} from "../algolia/helpers";
import type { UserHit } from "../algolia/types";
import { DataGridCard, standardProps } from "../components/data-grid";
import * as columnTypes from "../components/data-grid/columnTypes";
import QuickSearch from "../components/data-grid/QuickSearch";
import { PageHeader } from "../components/PageHeader";
import useAuth from "../hooks/useAuth";
import useLoading from "../hooks/useLoading";
import useNavigateOnRowClick from "../hooks/useNavigateOnRowClick";
import type { SavedState } from "../types/navigate";
import { formatPostalCode } from "../util/stdlib";

const indexName = "users";
const algoliaAppId = process.env.REACT_APP_ALGOLIA_APP_ID!;

const renderUserName = (params: GridRenderCellParams<any, UserHit>) => {
  const row = params.row;
  const name = row._highlightResult?.name?.value ?? row.name ?? "-";
  return (
    <Stack direction={"row"} alignItems={"center"} spacing={2}>
      <Avatar src={params.row.photo_url ?? ""} sx={{ width: 24, height: 24 }} />
      <Typography
        dangerouslySetInnerHTML={{ __html: name }}
        variant={"subtitle2"}
      ></Typography>
    </Stack>
  );
};

const columns: GridColumns<UserHit> = [
  {
    field: "name",
    headerName: "User",
    ...columnTypes.flexPrimary,
    minWidth: 200,
    renderCell: renderUserName,
    sortable: false,
  },
  {
    field: "email",
    headerName: "Email",
    ...columnTypes.flexDefault,
    minWidth: 250,
    valueGetter: highlightResultGetter,
    renderCell: renderHighlightResult,
    sortable: false,
  },
  {
    field: "phone",
    headerName: "Phone",
    ...columnTypes.flexDefault,
    minWidth: 250,
    valueFormatter: (params) => {
      const phoneNumber = parsePhoneNumber(params.value, "US");
      return phoneNumber.formatNational();
    },
    sortable: false,
  },
  {
    field: "state",
    headerName: "State",
    ...columnTypes.flexDefault,
    valueGetter: highlightResultGetter,
    renderCell: renderHighlightResult,
    sortable: false,
  },
  {
    field: "postal_code",
    headerName: "Postal code",
    ...columnTypes.flexDefault,
    valueFormatter: (params) => {
      return formatPostalCode(params.value);
    },
    sortable: false,
  },
  {
    field: "created_at",
    headerName: "First created",
    ...columnTypes.createdAt,
    valueGetter: (params) => new Date(params.row.timestamps.created_at * 1000),
    sortable: false,
  },
];

function Users(): JSX.Element {
  const { searchApiKey } = useAuth();
  const client = useMemo(
    () => (searchApiKey ? searchClient(algoliaAppId, searchApiKey) : null),
    [searchApiKey]
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [savedState, setSavedState] = useState(
    (location?.state as SavedState) || {}
  );
  const [page, setPage] = useState(0);
  const [hits, setHits] = useState<any[]>([]);
  const [search, setSearch] = useState<string>(savedState?.search || "");
  const [debounced] = useDebounce(search, 200);
  const [loading, setLoading] = useLoading();
  const handleRowClick = useNavigateOnRowClick();

  useEffect(() => {
    navigate("", { replace: true, state: savedState });
  }, [navigate, savedState]);

  // Update saved state
  useEffect(() => {
    setSavedState({ page: page, search: search });
  }, [page, search]);

  // Update search results on quick search input
  useEffect(() => {
    if (!debounced) {
      return setHits([]);
    }
    if (!client) {
      return console.warn("SearchClient not initialized");
    }

    setLoading(true);
    client
      .searchSingleIndex({
        indexName,
        searchParams: {
          query: debounced,
          hitsPerPage: 20,
        },
      })
      .then((results) => {
        setHits(results.hits);
      })
      .catch((error) => {
        console.warn(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [debounced, client, setLoading]);

  return (
    <React.Fragment>
      <PageHeader title={"Users"} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <QuickSearch value={search} onChange={setSearch} />
          <DataGridCard>
            <DataGrid
              rows={hits}
              columns={columns}
              {...standardProps}
              sx={{
                ".MuiDataGrid-cell em": {
                  // color: "secondary.contrastText",
                  // backgroundColor: "secondary.main",
                  fontStyle: "normal",
                  // fontWeight: "bold",
                },
                ".MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
              page={page}
              onPageChange={setPage}
              onRowClick={handleRowClick}
              getRowId={(row) => row.objectID}
              loading={loading}
            />
          </DataGridCard>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Users;
