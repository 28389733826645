import { Stack, TextField, Typography } from "@mui/material";
import { collection, doc, getDoc, getFirestore } from "firebase/firestore";
import { useSnackbar } from "notistack";
import type { FormEventHandler } from "react";
import React, { useCallback, useEffect, useState } from "react";

import FormDialog from "../../components/dialogs/FormDialog";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";
import { formatStationAddress } from "../../util/stdlib";

import movePhotosReviews from "./firebase/movePhotosReviews";

function MovePhotosReviewsDialog({
  open,
  handleClose,
  station,
}: {
  open: boolean;
  handleClose: () => void;
  station: WithDocRef<Station>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const [target, setTarget] = useState<WithDocRef<Station> | null>(null);
  const [targetId, setTargetId] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // Lookup target
  useEffect(() => {
    if (targetId.length !== 20) {
      setTarget(null);
      setError(null);
      return;
    }

    const db = getFirestore();
    const docRef = doc(collection(db, "stations"), targetId);
    getDoc(docRef).then((doc) => {
      if (doc.exists()) {
        const station = {
          id: doc.id,
          docRef: doc.ref,
          ...doc.data(),
        } as WithDocRef<Station>;
        setTarget(station);
        setError(null);
      } else {
        setTarget(null);
        setError("No station found with this ID.");
      }
    });
  }, [targetId]);

  const onSubmit = useCallback<FormEventHandler>(
    async (event) => {
      event.preventDefault();

      // Validation
      if (target === null) return;

      try {
        setLoading(true);
        await movePhotosReviews({ from: station.docRef, to: target.docRef });
        enqueueSnackbar("Success", { variant: "success" });
      } catch (err: any) {
        console.error(err);
        enqueueSnackbar("Failed to update station", { variant: "error" });
      } finally {
        handleClose();
      }
    },
    [handleClose, station, enqueueSnackbar, target]
  );

  return (
    <FormDialog
      title={"Move Photos & Reviews"}
      open={open}
      onCancel={handleClose}
      onSubmit={onSubmit}
      isSubmitting={loading}
      submitButtonLabel={"Move"}
      readyToSubmit={target !== null && !error}
    >
      <Stack spacing={2} mt={2}>
        <Typography variant={"subtitle2"} color={"text.secondary"}>
          Enter the 20-character identifier of the target station.
        </Typography>
        <TextField
          value={targetId}
          onChange={(event) => setTargetId(event.target.value)}
          placeholder={"TARGET"}
          helperText={
            error ||
            (target
              ? [target.name, formatStationAddress(target)].join(", ")
              : "")
          }
          error={Boolean(error)}
          size={"small"}
          fullWidth
        />
      </Stack>
    </FormDialog>
  );
}

export default MovePhotosReviewsDialog;
