import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import type { DocumentReference } from "firebase/firestore";
import { deleteDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function DeleteDialog({
  open,
  handleClose,
  docRef,
  displayName,
  text,
  navigateOnClose = true,
}: {
  open: boolean;
  handleClose: () => void;
  docRef: DocumentReference;
  displayName: string;
  text?: string | null;
  navigateOnClose?: boolean;
}): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmText, setConfirmText] = useState<string>();

  const handleDelete = async () => {
    try {
      await deleteDoc(docRef);
      if (navigateOnClose) {
        if (location.key !== "default") {
          navigate(-1); // Navigate Back
        } else {
          navigate("..", { replace: true }); // Navigate Up
        }
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(`Failed to delete ${displayName}.`, { variant: "error" });
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">
        Delete "{displayName}"?
      </DialogTitle>

      <DialogContent>
        {text && (
          <DialogContentText id="alert-dialog-description" mb={4}>
            {text}
          </DialogContentText>
        )}

        <Stack spacing={2} mt={2}>
          <Typography variant={"subtitle2"} color={"text.secondary"}>
            To continue, type "DELETE"
          </Typography>
          <TextField
            value={confirmText}
            onChange={(event) => setConfirmText(event.target.value)}
            placeholder={"DELETE"}
            size={"small"}
            fullWidth
          />
          <Typography variant={"caption"} color={"text.secondary"}>
            {docRef.path}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={!(confirmText === "DELETE")} onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { DeleteDialog };
