import { Add as AddIcon } from "@mui/icons-material";
import { Button, Chip, Stack, TextField, Typography } from "@mui/material";
import { serverTimestamp, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import type { FormEventHandler } from "react";
import React, { useCallback, useState } from "react";

import ChipField from "../../components/ChipField";
import FormDialog from "../../components/dialogs/FormDialog";
import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { InfoChip, InfoSection } from "../../types/chip.db";
import type { Network } from "../../types/network.db";

function EditOverviewDialog({
  open,
  handleClose,
  network,
}: {
  open: boolean;
  handleClose: () => void;
  network: WithDocRef<Network>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const [activate, setActivate] = useState<InfoSection>(() => {
    return (
      network.section_activate ?? { description: network.text_activate ?? "" }
    );
  });
  const [pricing, setPricing] = useState<string | null>(network.text_pricing);
  const [hours, setHours] = useState<string | null>(network.text_hours);
  const [loading, setLoading] = useState(false);

  const updateNetwork: FormEventHandler = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      await updateDoc(network.docRef, {
        section_activate: activate,
        text_activate: activate.description || null,
        text_pricing: pricing || null,
        text_hours: hours || null,
        "timestamps.updated_at": serverTimestamp(),
      });
      enqueueSnackbar("Updated network");
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update network");
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={"Edit Overview"}
      open={open}
      onCancel={handleClose}
      onSubmit={updateNetwork}
      isSubmitting={loading}
    >
      <Stack mt={6} spacing={6}>
        <TextField
          label="Activate"
          defaultValue={activate?.description ?? ""}
          onChange={(params) => {
            setActivate({ ...activate, description: params.target.value });
          }}
          multiline
          maxRows={10}
          fullWidth
        />
        <TextField
          label="Pricing"
          defaultValue={pricing}
          onChange={(params) => setPricing(params.target.value)}
          multiline
          maxRows={10}
          fullWidth
        />
        <TextField
          label="Hours"
          defaultValue={hours}
          onChange={(params) => setHours(params.target.value)}
          multiline
          maxRows={10}
          fullWidth
        />
      </Stack>
    </FormDialog>
  );
}

function EditChipDialog({
  open,
  handleClose,
  network,
}: {
  open: boolean;
  handleClose: () => void;
  network: WithDocRef<Network>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const [chip, setChip] = useState<InfoChip | null>();
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback<FormEventHandler>(
    async (event) => {
      event.preventDefault();

      const chips: InfoChip[] = network.section_activate?.chips ?? [];
      const activate: InfoSection = network.section_activate ?? {
        description: network.text_activate ?? "",
      };
      activate.chips = [...chips];
      if (chip) activate.chips.push(chip);

      try {
        setLoading(true);
        await updateDoc(network.docRef, {
          section_activate: activate,
          "timestamps.updated_at": serverTimestamp(),
        });
        enqueueSnackbar("Updated network");
      } catch (err: any) {
        console.error(err);
        enqueueSnackbar("Failed to update network", { variant: "error" });
      } finally {
        handleClose();
      }
    },
    [chip, enqueueSnackbar, handleClose, network]
  );

  const onChange = useCallback((newValue: InfoChip) => {
    setChip(newValue);
  }, []);

  return (
    <FormDialog
      title={"Edit Chip"}
      open={open}
      onCancel={handleClose}
      onSubmit={onSubmit}
      isSubmitting={loading}
    >
      <Stack mt={6} spacing={6}>
        <ChipField onChange={onChange} />
      </Stack>
    </FormDialog>
  );
}

function OverviewCard({
  network,
}: {
  network: WithDocRef<Network>;
}): JSX.Element {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openChipDialog, setOpenChipDialog] = useState(false);
  const chips = network.section_activate?.chips ?? [];

  const deleteChip = useCallback(
    async (chip: InfoChip) => {
      const chips: InfoChip[] = network.section_activate?.chips ?? [];
      const filtered = chips.filter(
        (value) => !(value.icon === chip.icon && value.label === chip.label)
      );

      await updateDoc(network.docRef, {
        "section_activate.chips": filtered,
        "timestamps.updated_at": serverTimestamp(),
      });
    },
    [network]
  );

  return (
    <>
      <PartitionedCard
        title={"Overview"}
        action={
          <Button
            onClick={() => {
              setOpenEditDialog(true);
            }}
          >
            Edit
          </Button>
        }
      >
        <PartitionedCardItem title={"Activate"}>
          <Stack spacing={6}>
            <Typography whiteSpace={"pre-wrap"}>
              {network.section_activate
                ? network.section_activate.description
                : network.text_activate ?? "-"}
            </Typography>

            <Stack spacing={2} alignItems={"flex-start"}>
              {chips.map((chip) => (
                <Chip
                  key={chip.label}
                  variant={"filled"}
                  label={chip.label}
                  onDelete={() => deleteChip(chip)}
                />
              ))}
              {chips.length < 5 && (
                <Chip
                  variant={"outlined"}
                  label={"Add Chip"}
                  icon={<AddIcon />}
                  onClick={() => {
                    setOpenChipDialog(true);
                  }}
                />
              )}
            </Stack>
          </Stack>
        </PartitionedCardItem>
        <PartitionedCardItem title={"Pricing"}>
          <Typography whiteSpace={"pre-wrap"}>
            {network.text_pricing ?? "-"}
          </Typography>
        </PartitionedCardItem>
        <PartitionedCardItem title={"Hours"}>
          <Typography whiteSpace={"pre-wrap"}>
            {network.text_hours ?? "-"}
          </Typography>
        </PartitionedCardItem>
      </PartitionedCard>
      {openEditDialog && (
        <EditOverviewDialog
          open={true}
          handleClose={() => setOpenEditDialog(false)}
          network={network}
        />
      )}
      {openChipDialog && (
        <EditChipDialog
          open={true}
          handleClose={() => setOpenChipDialog(false)}
          network={network}
        />
      )}
    </>
  );
}

export default OverviewCard;
