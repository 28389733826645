import { Add as AddIcon } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { orderBy } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { DataGridCard, standardProps } from "../components/data-grid";
import * as columnTypes from "../components/data-grid/columnTypes";
import QuickSearch from "../components/data-grid/QuickSearch";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import { renderPlugType } from "../components/Pip";
import useCollection from "../hooks/useCollection";
import useNavigateOnRowClick from "../hooks/useNavigateOnRowClick";
import type { Charger } from "../types/charger.db";
import type { SavedState } from "../types/navigate";

import EditChargerDialog from "./chargers/EditChargerDialog";

const columns: GridColumns<Charger> = [
  {
    field: "make",
    headerName: "Make",
    ...columnTypes.flexDefault,
  },
  {
    field: "model",
    headerName: "Model",
    ...columnTypes.flexDefault,
  },
  {
    field: "model_variant",
    headerName: "Variant",
    ...columnTypes.flexDefault,
  },
  {
    field: "amps",
    headerName: "Amps",
    ...columnTypes.flexNumber,
  },
  {
    field: "plug_types",
    headerName: "Plug Type",
    ...columnTypes.flexDefault,
    renderCell: renderPlugType,
  },
  {
    field: "price",
    headerName: "Price",
    ...columnTypes.currency,
  },
  {
    field: "updated_at",
    headerName: "Last updated",
    ...columnTypes.updatedAt,
  },
  {
    field: "created_at",
    headerName: "First created",
    ...columnTypes.createdAt,
  },
];

function Chargers(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const [savedState, setSavedState] = useState(
    (location?.state as SavedState) || {}
  );
  const [page, setPage] = useState(savedState.page || 0);
  const [items, loading] = useCollection<Charger>(
    "home-chargers",
    orderBy("make"),
    orderBy("model")
  );
  const [filtered, setFiltered] = useState<Charger[]>(items);
  const [search, setSearch] = useState<string>(savedState.search || "");
  const [debounced] = useDebounce(search, 200);
  const [open, setOpen] = useState(false);
  const handleRowClick = useNavigateOnRowClick();

  useEffect(() => {
    navigate("", { replace: true, state: savedState });
  }, [navigate, savedState]);

  // Update saved state
  useEffect(() => {
    setSavedState({ page: page, search: search });
  }, [page, search]);

  // Filter items on quick search input
  useEffect(() => {
    if (debounced) {
      const regex = new RegExp(debounced, "i");
      setFiltered(
        items.filter(
          (item) =>
            regex.test(item.make) ||
            regex.test(item.model) ||
            regex.test(item.model_variant ?? "")
        )
      );
    } else {
      setFiltered(items);
    }
  }, [items, debounced]);

  return (
    <React.Fragment>
      <PageHeader
        title={"Chargers"}
        action={
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            fullWidth
            onClick={() => setOpen(true)}
          >
            Add
          </Button>
        }
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <QuickSearch value={search} onChange={setSearch} />
              <DataGridCard>
                <DataGrid
                  rows={filtered}
                  columns={columns}
                  {...standardProps}
                  sx={{
                    ".MuiDataGrid-row:hover": {
                      cursor: "pointer",
                    },
                  }}
                  page={page}
                  onPageChange={setPage}
                  onRowClick={handleRowClick}
                />
              </DataGridCard>
            </Grid>
          </Grid>

          <EditChargerDialog open={open} handleClose={() => setOpen(false)} />
        </>
      )}
    </React.Fragment>
  );
}

export default Chargers;
