import { Card, CardHeader } from "@mui/material";
import type {
  GridColumns,
  GridEventListener,
  GridRowParams,
} from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { standardProps } from "../../components/data-grid";
import * as columnTypes from "../../components/data-grid/columnTypes";
import { StandardPagination } from "../../components/data-grid/pagination";
import useFirebase from "../../hooks/useFirebase";
import type { Incentive } from "../../types/incentive.db";
import type { Vehicle } from "../../types/vehicle.db";

function RelatedVehiclesList({ vehicle }: { vehicle: Vehicle }): JSX.Element {
  const navigate = useNavigate();
  const { database } = useFirebase();

  const make = useMemo(() => vehicle.make, [vehicle]);
  const model = useMemo(() => vehicle.model, [vehicle]);

  const [vehicles, setVehicles] = useState<Vehicle[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const collectionRef = collection(database, "vehicles");

    const q = query(
      collectionRef,
      where("make", "==", make),
      where("model", "==", model)
    );

    return onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      }) as Vehicle[];

      setVehicles(
        data.length === 1
          ? []
          : data.sort(
              (a, b) =>
                (b.year - a.year ||
                  a.trim?.localeCompare(b?.trim ?? "", "en", {
                    ignorePunctuation: true,
                  })) ??
                0
            )
      );
      setLoading(false);
    });
  }, [make, model, database]);

  const columns = useMemo<GridColumns<Vehicle>>(
    () => [
      {
        field: "year",
        headerName: "Year",
        ...columnTypes.flexDefault,
        minWidth: 100,
      },
      {
        field: "make",
        headerName: "Make",
        ...columnTypes.flexDefault,
      },
      {
        field: "model",
        headerName: "Model",
        ...columnTypes.flexDefault,
      },
      {
        field: "trim",
        headerName: "Trim",
        ...columnTypes.flexDefault,
        minWidth: 250,
      },
      {
        field: "updated_at",
        headerName: "Last updated",
        ...columnTypes.updatedAt,
      },
      {
        field: "created_at",
        headerName: "First created",
        ...columnTypes.createdAt,
      },
    ],
    []
  );

  const handleRowClick = useCallback<GridEventListener<"rowClick">>(
    (params: GridRowParams<Incentive>) => {
      navigate(`../${params.id}`);
    },
    [navigate]
  );

  return (
    <Card>
      <CardHeader title="Related Vehicles" />
      <DataGrid
        rows={vehicles || []}
        columns={columns}
        {...standardProps}
        pageSize={20}
        getRowClassName={(params) =>
          params.id === vehicle.id ? "Mui-selected" : ""
        }
        sx={{
          ".MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
        onRowClick={handleRowClick}
        loading={loading}
      />
    </Card>
  );
}

export default RelatedVehiclesList;
