import { Autocomplete, Stack, TextField } from "@mui/material";
import { addMonths, format, isDate, parse } from "date-fns";
import {
  Timestamp,
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import FormDialog from "../../components/dialogs/FormDialog";
import useFirebase from "../../hooks/useFirebase";
import type { Beacon } from "../../types/beacon.db";
import type { Sponsor } from "../../types/sponsor.db";

import generateBeaconKey from "./generateBeaconKey";

const expiresAtFormat = "MM/dd/yyyy";

const validateExpiresAt = (value: string) => {
  const date = parse(value, expiresAtFormat, new Date());
  if (!isDate(date) || isNaN(date.getTime())) {
    return `Must be in the format ${expiresAtFormat}`;
  }
  return true;
};

function EditBeaconDialog({
  open,
  handleClose,
  beacon,
  nextBeaconName,
}: {
  open: boolean;
  handleClose: () => void;
  beacon?: Beacon;
  nextBeaconName?: string;
}): JSX.Element {
  const { database } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      const createMode = !beacon;
      const expiresAt =
        (beacon?.timestamps.expires_at as Timestamp)?.toDate() ?? null;
      reset({
        beacon_name: beacon?.name ?? nextBeaconName,
        beacon_title: beacon?.title ?? "",
        sponsor_name: beacon?.sponsor?.name ?? null,
        expires_at: expiresAt
          ? format(expiresAt, expiresAtFormat)
          : createMode
          ? format(addMonths(new Date(), 12), expiresAtFormat)
          : "",
      });
    }
  }, [open, reset, beacon, nextBeaconName]);

  // Load sponsors
  useEffect(() => {
    if (open) {
      const q = query(collection(database, "sponsors"), orderBy("name"));

      getDocs(q).then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { id: doc.id, ...doc.data() };
        }) as Sponsor[];
        setSponsors(data);
      });
    }
  }, [open, database]);

  const onSubmit = async (data: any) => {
    const collectionRef = collection(database, "beacons");

    try {
      const sponsor = sponsors.find((s) => s.name === data.sponsor_name);
      const expiresAt = parse(data.expires_at, expiresAtFormat, new Date());
      const isExpiresAtValid = isDate(expiresAt) && !isNaN(expiresAt.getTime());

      if (beacon) {
        await updateDoc(doc(collectionRef, beacon.id), {
          name: data.beacon_name,
          title: data.beacon_title,
          sponsor: sponsor ? { id: sponsor.id, name: sponsor.name } : null,
          "timestamps.updated_at": serverTimestamp(),
          "timestamps.expires_at": isExpiresAtValid
            ? Timestamp.fromDate(expiresAt)
            : null,
        });
      } else {
        // Load existing beacons
        const snapshot = await getDocs(collectionRef);
        const existing: any[] = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Generate a new license key for this beacon
        const keyExists = (key: string) => existing.some((b) => b.key === key);
        let uniqueKey = generateBeaconKey();

        // The key must be unique (it's used as both username and password)
        while (keyExists(uniqueKey)) {
          uniqueKey = generateBeaconKey();
        }

        const beacon: Omit<Beacon, "id"> = {
          key: uniqueKey,
          name: data.beacon_name,
          title: data.beacon_title,
          sponsor: sponsor ? { id: sponsor.id, name: sponsor.name } : null,
          location: null,
          postal_code: null,
          settings: {
            analytics_enabled: true,
            attract_loop_timeout: 600, // in seconds
            show_add_new_vehicle: false,
            show_add_used_vehicle: false,
            show_vehicle_brand_filters: false,
            more_info_button_enabled: false, // Deprecated
            more_info_button_text: "", // Deprecated
          },
          timestamps: {
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
            ...(isExpiresAtValid
              ? { expires_at: Timestamp.fromDate(expiresAt) }
              : {}),
          },
        };

        await setDoc(doc(collectionRef), beacon);
      }
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(`Failed to ${beacon ? "update" : "add"} beacon`, {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={beacon ? "Edit Beacon" : "Add Beacon"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Controller
          name={"beacon_name"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Name"
              placeholder="CWB-000009"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
              autoFocus
            />
          )}
        />

        <Controller
          name={"beacon_title"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Title"
              placeholder="Audi Wilsonville"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />

        <Controller
          name={"sponsor_name"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              autoHighlight
              autoSelect
              options={sponsors.map((s) => s.name)}
              onChange={(event, newValue) => field.onChange(newValue)}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Sponsor" />
              )}
            />
          )}
        />

        <Controller
          name={"expires_at"}
          control={control}
          rules={{
            validate: (v) => !v || validateExpiresAt(v),
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Expires At"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

export default EditBeaconDialog;
