import { useCallback, useEffect, useState } from "react";

const incrementCount = (currentValue: number) => ++currentValue;
const decrementCount = (currentValue: number) => Math.max(--currentValue, 0);

/**
 * `useLoading` returns a `boolean` value and a function to update it.
 *
 * Multiple calls to `setLoading(true)` will increment an internal counter so
 * that `loading` will only equal `false` when an equal number of calls have
 * been made to `setLoading(false)`.
 *
 * This behavior allows you to synchronize loading state across multiple async
 * tasks.
 *
 * @param {boolean} [initialState=true] - An optional default value
 */
function useLoading(
  initialState = true
): [boolean, (loading: boolean) => void] {
  const [count, setCount] = useState<number>(initialState ? 1 : 0);

  useEffect(() => {
    if (initialState) setCount(decrementCount);
  }, [initialState]);

  const setLoading = useCallback((loading: boolean) => {
    setCount(loading ? incrementCount : decrementCount);
  }, []);

  return [!!count, setLoading];
}

export default useLoading;
