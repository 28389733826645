import {
  Autocomplete,
  CircularProgress,
  Divider,
  Stack,
  TextField,
} from "@mui/material";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import FormDialog from "../../components/dialogs/FormDialog";
import {
  validateCoordinates,
  validateCountryCode,
  validatePostalCode,
} from "../../forms/validation";
import useLoading from "../../hooks/useLoading";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Network } from "../../types/network.db";
import { parseLatLng } from "../../util/stdlib";

import addStation from "./firebase/addStation";

function AddStationDialog({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}): JSX.Element {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [networks, setNetworks] = useState<WithDocRef<Network>[]>([]);
  const [loading, setLoading] = useLoading();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onBlur",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      reset({
        network: null,
        name: "",
        address: "",
        city: "",
        state: "",
        postal_code: "",
        country: "",
        coordinates: "",
      });
    }
  }, [open, reset]);

  // Load networks
  useEffect(() => {
    if (open) {
      const db = getFirestore();
      const q = query(collection(db, "networks"), where("publish", "==", true));

      setLoading(true);
      getDocs(q)
        .then((snapshot) => {
          const data = snapshot.docs.map((doc) => {
            return { id: doc.id, docRef: doc.ref, ...doc.data() };
          }) as WithDocRef<Network>[];
          data.sort((a, b) => a.name.localeCompare(b.name));
          setNetworks(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open, setLoading]);

  const onSubmit = async (data: any) => {
    try {
      const [latitude, longitude] = parseLatLng(data.coordinates);

      const docRef = await addStation({
        network: data.network,
        name: data.name.trim(),
        address: data.address.trim(),
        city: data.city.trim(),
        state: data.state.trim(),
        postal_code: data.postal_code.trim(),
        country: data.country.trim(),
        latitude,
        longitude,
      });

      // Finish
      navigate(`/stations/${docRef.id}`);
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to add station", {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={"Add Station"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Controller
          name={"network"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              autoHighlight
              autoSelect
              options={networks}
              getOptionKey={(value) => value.id}
              getOptionLabel={(value) => value.name}
              isOptionEqualToValue={(a, b) => a.id === b.id}
              onChange={(event, newValue) => field.onChange(newValue)}
              fullWidth
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Network"
                  helperText={error?.message}
                  error={Boolean(error)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <CircularProgress color="primary" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          )}
        />
        <Divider />
        <Controller
          name={"name"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Name"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
        <Controller
          name={"address"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Address"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
        <Controller
          name={"city"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="City"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
        <Stack direction={"row"} spacing={2}>
          <Controller
            name={"state"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="State"
                helperText={error?.message}
                error={Boolean(error)}
                fullWidth
              />
            )}
          />
          <Controller
            name={"postal_code"}
            control={control}
            rules={{ required: true, validate: validatePostalCode }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Postal Code"
                placeholder={"97227"}
                helperText={error?.message}
                error={Boolean(error)}
                fullWidth
              />
            )}
          />
          <Controller
            name={"country"}
            control={control}
            rules={{ required: true, validate: validateCountryCode }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Country"
                helperText={error?.message}
                error={Boolean(error)}
                fullWidth
              />
            )}
          />
        </Stack>

        <Controller
          name={"coordinates"}
          control={control}
          rules={{ required: true, validate: validateCoordinates }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Coordinates"
              helperText={
                error?.message ||
                "A latitude and longitude pair separated by a comma"
              }
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

export default AddStationDialog;
