import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Launch as LaunchIcon,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { deleteField, updateDoc } from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import { standardProps } from "../../components/data-grid";
import * as columnTypes from "../../components/data-grid/columnTypes";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Promotion, VehicleDataGridStub } from "../../types/promotion.db";
import { compareVehicles } from "../../util/comparators";
import { updatedAt } from "../../util/firebase";

import AddVehicleDialog from "./AddVehicleDialog";

function RemoveVehicleDialog({
  open,
  handleClose,
  promotion,
  selected,
}: {
  open: boolean;
  handleClose: () => void;
  promotion: WithDocRef<Promotion>;
  selected: VehicleDataGridStub[];
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const first = selected[0];

  const deleteVehicle = async () => {
    setIsSubmitting(true);
    const total = selected.length;

    try {
      const data: any = {};
      for (const s of selected) {
        data[`criteria.vehicles.${s.id}`] = deleteField();
      }
      await updateDoc(promotion.docRef, { ...data, ...updatedAt });

      enqueueSnackbar(
        total > 1
          ? `Removed ${total} vehicles`
          : `Removed "${first?.display_name}"`
      );
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(
        total > 1
          ? "Failed to remove vehicles"
          : `Failed to remove "${first?.display_name}"`
      );
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {selected.length > 1
            ? `Delete ${selected.length} vehicles?`
            : `Delete "${first?.display_name}"?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <LoadingButton onClick={deleteVehicle} loading={isSubmitting}>
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

const columns: GridColumns<VehicleDataGridStub> = [
  {
    field: "year",
    headerName: "Year",
    ...columnTypes.flexDefault,
    minWidth: 100,
    valueGetter: (params) => params.row.year,
  },
  {
    field: "make",
    headerName: "Make",
    ...columnTypes.flexDefault,
    valueGetter: (params) => params.row.make,
  },
  {
    field: "model",
    headerName: "Model",
    ...columnTypes.flexDefault,
    valueGetter: (params) => params.row.model,
  },
  {
    field: "trim",
    headerName: "Trim",
    ...columnTypes.flexDefault,
    minWidth: 250,
    valueGetter: (params) => params.row.trim,
  },
  {
    field: "fuel_type",
    headerName: "Type",
    ...columnTypes.flexDefault,
    minWidth: 100,
    valueGetter: (params) => params.row.fuel_type,
    renderCell: (params) => (
      <Chip label={params.value} variant="outlined" size="small" />
    ),
  },
  {
    field: "actions",
    headerName: "",
    ...columnTypes.flexActions,
    renderCell: (params) => (
      <Button
        size={"small"}
        endIcon={<LaunchIcon />}
        onClick={() => {
          window.open(`/vehicles/${params.row.id}`, "_blank");
        }}
      >
        View detail
      </Button>
    ),
  },
];

function VehicleList({
  promotion,
}: {
  promotion: WithDocRef<Promotion>;
}): JSX.Element {
  const [vehicles, setVehicles] = useState<VehicleDataGridStub[]>([]);

  useEffect(() => {
    const vehicles = promotion.criteria?.vehicles
      ? Object.values(promotion.criteria?.vehicles)
      : [];
    setVehicles(vehicles.sort(compareVehicles));
  }, [promotion]);

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  const isEmpty = vehicles.length === 0;

  return (
    <Card>
      <CardHeader
        title={"Vehicles"}
        subheader={"Active for all makes and models unless one is added."}
        action={
          <Stack direction="row" spacing={2}>
            <Button
              startIcon={<AddIcon />}
              variant="text"
              onClick={() => setOpenAddDialog(true)}
            >
              Add
            </Button>
            <Button
              startIcon={<DeleteIcon />}
              variant="text"
              onClick={() => setOpenRemoveDialog(true)}
              disabled={selected.length === 0}
            >
              Remove
            </Button>
          </Stack>
        }
      />
      {!isEmpty && (
        <DataGrid
          rows={vehicles}
          columns={columns}
          {...standardProps}
          pageSize={20}
          sx={{
            ".MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          checkboxSelection
          disableSelectionOnClick={false}
          onSelectionModelChange={(selectionModel, details) =>
            setSelected(selectionModel as string[])
          }
          selectionModel={selected}
        />
      )}
      {openAddDialog && (
        <AddVehicleDialog
          open={true}
          handleClose={() => setOpenAddDialog(false)}
          promotion={promotion}
        />
      )}
      {openRemoveDialog && (
        <RemoveVehicleDialog
          open={true}
          handleClose={() => setOpenRemoveDialog(false)}
          promotion={promotion}
          selected={vehicles.filter((v) => selected.includes(v.id))}
        />
      )}
    </Card>
  );
}

export default VehicleList;
