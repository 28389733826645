import type { Query, DocumentReference } from "firebase/firestore";
import { onSnapshot, queryEqual } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";

type WithDocRef<T> = T & { docRef: DocumentReference };
type QueryResult<T> = [
  data: WithDocRef<T>[],
  loading: boolean,
  setQuery: (q: Query) => void
];

/** Attaches a listener for QuerySnapshot events. */
function useQuery<T extends { id: string }>(
  initialQuery: Query | (() => Query)
): QueryResult<T> {
  const [data, setData] = useState<WithDocRef<T>[]>([]);
  const [loading, setLoading] = useState(true);
  const [_query, _setQuery] = useState(initialQuery);

  // Use deep equality to update the internal _query reference only when the query has
  // actually changed.
  const setQuery = useCallback((q: Query) => {
    _setQuery((prevQuery) => (!queryEqual(q, prevQuery) ? q : prevQuery));
  }, []);

  useEffect(() => {
    console.debug("Attaching new snapshot listener.");
    setLoading(true);
    return onSnapshot(
      _query,
      (querySnapshot) => {
        setData(
          querySnapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
              docRef: doc.ref,
            } as WithDocRef<T>;
          })
        );
        setLoading(false);
      },
      (error) => console.warn(error)
    );
  }, [_query]);

  return [data, loading, setQuery];
}

export default useQuery;
export type { WithDocRef };
