import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { collection, doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import useFirebase from "../../hooks/useFirebase";
import type { Utility } from "../../types/utility.db";

/** Add or edit a utility. */
function EditUtilityDialog({
  open,
  handleClose,
  fullScreen,
  utility,
}: {
  open: boolean;
  handleClose: () => void;
  fullScreen: boolean;
  utility?: Utility;
}): JSX.Element {
  const { database } = useFirebase();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Reset the form each time the dialog is presented
  useEffect(() => {
    if (open)
      reset({
        name: utility?.name || "",
        website_url: utility?.website_url || "",
      });
  }, [open, reset, utility]);

  const onCancel = () => handleClose();
  const onSubmit = async (data: any) => {
    const collectionRef = collection(database, "electric-utilities");
    const docRef = utility
      ? doc(collectionRef, utility.id)
      : doc(collectionRef);

    const onCreate = {
      logo_url: null,
      zipcodes: [],
      timestamps: {
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
      },
    };

    const onUpdate = {
      timestamps: {
        updated_at: serverTimestamp(),
      },
    };

    const docData = {
      name: data.name,
      website_url: data.website_url || null,
      ...(!utility ? onCreate : onUpdate),
    };

    await setDoc(docRef, docData, { merge: true });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ padding: "24px 24px 0" }}>
          {utility ? "Edit Utility" : "Add Utility"}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={4} mt={6}>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Name"
                  error={Boolean(error)}
                  fullWidth
                />
              )}
            />

            <Controller
              name="website_url"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Website"
                  placeholder="https://example.net"
                  error={Boolean(error)}
                  inputProps={{ inputMode: "url" }}
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
export default EditUtilityDialog;
