import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  Stack,
  Typography,
} from "@mui/material";
import type { ResponsiveStyleValue } from "@mui/system";
import React from "react";

function PartitionedCardItem({
  title,
  caption,
  children,
  alignItems,
}: {
  title: string;
  caption?: string;
  children: React.ReactNode | React.ReactNode[];
  alignItems?: "baseline" | "center";
}): JSX.Element {
  return (
    <Stack direction={"row"} alignItems={alignItems ?? "baseline"} gap={4}>
      <Stack flexGrow={1} flexBasis={1}>
        <Typography
          variant={"subtitle2"}
          color={"text.secondary"}
          fontWeight={"bold"}
        >
          {title}
        </Typography>
        {caption && (
          <Typography variant={"caption"} color={"text.secondary"}>
            {caption}
          </Typography>
        )}
      </Stack>
      <Box
        flexGrow={2}
        flexBasis={0}
        alignSelf={alignItems ?? "baseline"}
        sx={{ display: "inline-flex", overflowWrap: "anywhere" }}
      >
        {children}
      </Box>
    </Stack>
  );
}

function PartitionedCard({
  title,
  subheader,
  action,
  spacing = 6,
  children,
  loading = false,
}: {
  title?: React.ReactNode;
  subheader?: React.ReactNode;
  action?: React.ReactNode | React.ReactNode[];
  spacing?: ResponsiveStyleValue<number | string>;
  children?: React.ReactNode | React.ReactNode[];
  loading?: boolean;
}): JSX.Element {
  const hasChildren = React.Children.count(children) > 0;
  return (
    <Card>
      <CardHeader title={title} subheader={subheader} action={action} />
      {hasChildren && (
        <CardContent>
          <Fade in={!loading} appear={false}>
            <Stack spacing={spacing} divider={<Divider />}>
              {children}
            </Stack>
          </Fade>
        </CardContent>
      )}
    </Card>
  );
}

export { PartitionedCard, PartitionedCardItem };
