import { customAlphabet } from "nanoid";

const numeric = customAlphabet("0123456789");

// [Z,S,O,I] are intentionally omitted as potential lookalikes.
const alphaNumeric = customAlphabet("0123456789ABCDEFGHJKLMNPQRTUVWXY");

/** Returns a random six-digit alphanumeric string. */
const generateBeaconKey = () => numeric(6);

export default generateBeaconKey;
