import { ContentCopy as CopyIcon } from "@mui/icons-material";
import { IconButton, Link, Tooltip, Typography } from "@mui/material";
import type { GridColTypeDef } from "@mui/x-data-grid";
import { format, formatDistanceToNowStrict } from "date-fns";
import { parsePhoneNumber } from "libphonenumber-js";
import React from "react";

/** Formats numbers. */
const numberFormatter = new Intl.NumberFormat("en-US");

/** Formats currency in USD. */
const usdFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

/** Flex default for string values. */
const flexDefault: GridColTypeDef = {
  flex: 1,
  minWidth: 150,
};

/** Large flex value for the primary column in a data-grid. */
const flexPrimary: GridColTypeDef = {
  flex: 100,
  minWidth: 150,
};

/** Flex default for actions column. */
const flexActions: GridColTypeDef = {
  ...flexDefault,
  align: "right",
  sortable: false,
};

/** Flex default for boolean values. */
const flexBoolean: GridColTypeDef = {
  ...flexDefault,
  type: "boolean",
  align: "left",
  headerAlign: "left",
};

/** Flex default for numeric values. */
const flexNumber: GridColTypeDef = {
  ...flexDefault,
  type: "number",
  align: "left",
  headerAlign: "left",
  valueGetter: (params) => params.value ?? 0,
  valueFormatter: (params) => numberFormatter.format(params.value),
};

/** Flex default for currency values. */
const currency: GridColTypeDef = {
  ...flexNumber,
  valueFormatter: (params) =>
    params.value !== null && params.value !== undefined
      ? usdFormatter.format(params.value)
      : "",
};

/** Flex default for dateTime values. */
const flexDateTime: GridColTypeDef = {
  ...flexDefault,
  type: "dateTime",
};

/**
 * Flex default for Timestamp values.
 *
 * Note: You'll need to provide a valueGetter that returns a valid Date object.
 */
const timestamp: GridColTypeDef = {
  ...flexDefault,
  type: "dateTime",
  minWidth: 200,
  valueFormatter: (params) =>
    params.value
      ? formatDistanceToNowStrict(params.value, { addSuffix: true })
      : "",
  renderCell: (params) =>
    params.value && (
      <Tooltip title={format(params.value, "PPPPpp")} enterDelay={500}>
        <Typography>{params.formattedValue}</Typography>
      </Tooltip>
    ),
};

const createdAt: GridColTypeDef = {
  ...timestamp,
  valueGetter: (params) => params.row.timestamps?.created_at?.toDate?.(),
};

const updatedAt: GridColTypeDef = {
  ...timestamp,
  valueGetter: (params) => params.row.timestamps?.updated_at?.toDate?.(),
};

const phoneNumber: GridColTypeDef = {
  ...flexDefault,
  valueFormatter: (params) =>
    params.value ? parsePhoneNumber(params.value).formatNational() : "",
};

const website: GridColTypeDef = {
  ...flexDefault,
  minWidth: 250,
  renderCell: (params) => {
    return (
      <Link
        href={params.value}
        target={"_blank"}
        onClick={(event) => event.stopPropagation()}
      >
        {params.value}
      </Link>
    );
  },
};

const copyMe = (onCopy: (text: string) => void): GridColTypeDef => {
  return {
    ...flexDefault,
    renderCell: (params) => {
      return (
        params.value && (
          <Typography noWrap={true} display={"flex"} alignItems={"center"}>
            {params.value}
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                onCopy(params.value);
              }}
              sx={{ marginLeft: 1 }}
            >
              <CopyIcon fontSize={"small"} />
            </IconButton>
          </Typography>
        )
      );
    },
  };
};

export {
  usdFormatter,
  flexDefault,
  flexPrimary,
  flexActions,
  flexBoolean,
  flexNumber,
  currency,
  flexDateTime,
  timestamp,
  createdAt,
  updatedAt,
  phoneNumber,
  website,
  copyMe,
};
