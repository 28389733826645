import { Typography } from "@mui/material";
import React from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Vehicle } from "../../types/vehicle.db";

function BatteryCard({
  vehicle,
}: {
  vehicle: WithDocRef<Vehicle>;
}): JSX.Element {
  return (
    <PartitionedCard title={"Battery & Range"} spacing={4}>
      <PartitionedCardItem title={"Capacity"}>
        <Typography>{`${vehicle.battery.capacity / 1000} kWh`}</Typography>
      </PartitionedCardItem>

      <PartitionedCardItem title={"Voltage"}>
        <Typography>{`${vehicle.battery.voltage} V`}</Typography>
      </PartitionedCardItem>

      {vehicle.fuel_type !== "BEV" && (
        <PartitionedCardItem title={"Electric Range"}>
          <Typography>{vehicle.range[0].electric + " miles"}</Typography>
        </PartitionedCardItem>
      )}

      <PartitionedCardItem title={"Total Range"}>
        <Typography>{vehicle.range[0].total + " miles"}</Typography>
      </PartitionedCardItem>
    </PartitionedCard>
  );
}

export default BatteryCard;
