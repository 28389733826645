import type { QueryOrderByConstraint } from "@firebase/firestore";
import { Chip, Grid } from "@mui/material";
import type { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import type { CollectionReference } from "firebase/firestore";
import { collection, orderBy, query, where } from "firebase/firestore";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { DataGridCard, standardProps } from "../components/data-grid";
import * as columnTypes from "../components/data-grid/columnTypes";
import { PageHeader } from "../components/PageHeader";
import useCollection from "../hooks/useCollection";
import useFirebase from "../hooks/useFirebase";
import useNavigateOnRowClick from "../hooks/useNavigateOnRowClick";
import useQuery from "../hooks/useQuery";
import type { Vehicle, VehicleMake } from "../types/vehicle.db";

import ManageMakeImageCard from "./vehicles/ManageMakeImageCard";
import type { FieldFilter } from "./vehicles/VehicleFilterPanel";
import VehicleFilterPanel from "./vehicles/VehicleFilterPanel";

const renderPhotoChip = (params: GridRenderCellParams<any, Vehicle>) => {
  if (params.row.photo_url?.includes("fuelapi.com")) {
    return (
      <Chip
        label={"FUEL"}
        variant={"outlined"}
        color={"success"}
        size={"small"}
      />
    );
  }

  if (params.row.photo_url) {
    return (
      <Chip
        label={"Custom"}
        variant={"outlined"}
        color={"warning"}
        size={"small"}
      />
    );
  }

  return (
    <Chip label={"None"} variant={"outlined"} color={"info"} size={"small"} />
  );
};

const columns: GridColumns<Vehicle> = [
  {
    field: "year",
    headerName: "Year",
    ...columnTypes.flexDefault,
    minWidth: 100,
  },
  {
    field: "make",
    headerName: "Make",
    ...columnTypes.flexDefault,
  },
  {
    field: "model",
    headerName: "Model",
    ...columnTypes.flexDefault,
  },
  {
    field: "trim",
    headerName: "Trim",
    ...columnTypes.flexDefault,
    minWidth: 250,
  },
  {
    field: "fuel_type",
    headerName: "Type",
    ...columnTypes.flexDefault,
    minWidth: 100,
    renderCell: (params: any) => (
      <Chip label={params.value} variant="outlined" size="small" />
    ),
  },
  {
    field: "photo_url",
    headerName: "Photo",
    ...columnTypes.flexDefault,
    minWidth: 100,
    renderCell: renderPhotoChip,
  },
  {
    field: "updated_at",
    headerName: "Last updated",
    ...columnTypes.updatedAt,
  },
  {
    field: "created_at",
    headerName: "First created",
    ...columnTypes.createdAt,
  },
];

const thisYear = new Date().getFullYear();
const initialFields = [{ field: "year", value: thisYear }];
const initialOrderBy = [
  ["year", orderBy("year", "desc")],
  ["make", orderBy("make")],
  ["model", orderBy("model")],
  ["trim", orderBy("trim")],
];

const buildQuery = (
  collectionRef: CollectionReference,
  fields: FieldFilter[]
) => {
  const constraints = fields.map((v) => where(v.field, "==", v.value));
  const orderBy = initialOrderBy
    .filter(([key, value]) => !fields.find((v) => v.field === key))
    .map(([key, value]) => value as QueryOrderByConstraint);
  return query(collectionRef, ...constraints, ...orderBy);
};

type SavedState = {
  fields?: FieldFilter[];
  page?: number;
};

function Vehicles(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const [savedState, setSavedState] = useState(
    (location?.state as SavedState) || {}
  );
  const deferredPage = useRef<number | null>(null);
  const [page, setPage] = useState(savedState.page || 0);

  const { database } = useFirebase();
  const collectionRef = useMemo(
    () => collection(database, "vehicles"),
    [database]
  );
  const [items, loading, setQuery] = useQuery<Vehicle>(
    buildQuery(
      collectionRef,
      savedState.fields?.length ? savedState.fields : initialFields
    )
  );
  const [makes] = useCollection<VehicleMake>("vehicle-makes", orderBy("name"));

  useEffect(() => {
    navigate("", { replace: true, state: savedState });
  }, [navigate, savedState]);

  useEffect(() => {
    if (deferredPage.current === null) return;
    setPage(deferredPage.current);
  }, [items]);

  const handleFieldChange = useCallback(
    (newFields: FieldFilter[]) => {
      console.debug("Updating query");
      setSavedState({ page: 0, fields: newFields });
      setQuery(buildQuery(collectionRef, newFields));
      deferredPage.current = 0;
    },
    [collectionRef, setQuery]
  );

  const handlePageChange = useCallback((newPage: number) => {
    setSavedState((prevSavedState) => {
      return { ...prevSavedState, page: newPage };
    });
    setPage(newPage);
  }, []);

  const handleRowClick = useNavigateOnRowClick();

  return (
    <React.Fragment>
      <PageHeader title={"Vehicles"} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <VehicleFilterPanel
            onChange={handleFieldChange}
            value={
              savedState.fields?.length ? savedState.fields : initialFields
            }
          />
          <DataGridCard>
            <DataGrid
              rows={items}
              columns={columns}
              {...standardProps}
              sx={{
                ".MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
              page={page}
              onPageChange={handlePageChange}
              onRowClick={handleRowClick}
              loading={loading}
            />
          </DataGridCard>
        </Grid>

        {!loading &&
          makes.map((v) => (
            <Grid item key={v.id} xs={12} md={4}>
              <ManageMakeImageCard make={v} />
            </Grid>
          ))}
      </Grid>
    </React.Fragment>
  );
}

export default Vehicles;
