import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Launch as LaunchIcon,
} from "@mui/icons-material";
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
} from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import {
  arrayRemove,
  collection as firestoreCollection,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { useSnackbar } from "notistack";
import { useState } from "react";

import { standardProps } from "../../components/data-grid";
import * as columnTypes from "../../components/data-grid/columnTypes";
import { renderPlugType } from "../../components/Pip";
import useFirebase from "../../hooks/useFirebase";
import type { WithDocRef } from "../../hooks/useQuery";
import useQuery from "../../hooks/useQuery";
import type { Beacon } from "../../types/beacon.db";
import type { Charger } from "../../types/charger.db";
import { updatedAt } from "../../util/firebase";

import AddChargerDialog from "./AddChargerDialog";

function RemoveChargerDialog({
  open,
  handleClose,
  beacon,
  selected,
}: {
  open: boolean;
  handleClose: () => void;
  beacon: WithDocRef<Beacon>;
  selected: WithDocRef<Charger>[];
}): JSX.Element {
  const { database } = useFirebase();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const first = selected[0] ? `${selected[0].make} ${selected[0].model}` : "";

  const deleteCharger = async () => {
    setIsSubmitting(true);
    try {
      const batch = writeBatch(database);
      for (const c of selected) {
        batch.update(c.docRef, { beacons: arrayRemove(beacon.id) });
      }
      batch.update(beacon.docRef, { ...updatedAt });
      await batch.commit();
      enqueueSnackbar(
        selected.length > 1
          ? `Removed ${selected.length} chargers`
          : `Removed "${first}"`
      );
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar(
        selected.length > 1
          ? "Failed to remove chargers"
          : `Failed to remove "${first}"`
      );
    } finally {
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {selected.length > 1
            ? `Delete ${selected.length} chargers?`
            : `Delete "${first}"?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button onClick={deleteCharger} disabled={isSubmitting}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const columns: GridColumns<Charger> = [
  {
    field: "make",
    headerName: "Make",
    ...columnTypes.flexDefault,
  },
  {
    field: "model",
    headerName: "Model",
    ...columnTypes.flexDefault,
  },
  {
    field: "model_variant",
    headerName: "Variant",
    ...columnTypes.flexDefault,
  },
  {
    field: "amps",
    headerName: "Amps",
    ...columnTypes.flexNumber,
  },
  {
    field: "plug_types",
    headerName: "Plug Type",
    ...columnTypes.flexDefault,
    renderCell: renderPlugType,
  },
  {
    field: "price",
    headerName: "Price",
    ...columnTypes.currency,
  },
  {
    field: "actions",
    headerName: "",
    ...columnTypes.flexActions,
    renderCell: (params) => (
      <Button
        size={"small"}
        endIcon={<LaunchIcon />}
        onClick={() => {
          window.open(`/chargers/${params.id}`, "_blank");
        }}
      >
        View detail
      </Button>
    ),
  },
];

function ChargerList({ beacon }: { beacon: WithDocRef<Beacon> }): JSX.Element {
  const { database } = useFirebase();
  const collectionRef = firestoreCollection(database, "home-chargers");
  const [chargers, loading] = useQuery<Charger>(
    query(
      collectionRef,
      where("beacons", "array-contains", beacon.id),
      orderBy("make"),
      orderBy("model")
    )
  );

  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);

  const isEmpty = chargers.length === 0;

  return (
    <Card>
      <CardHeader
        title={"Home Chargers"}
        action={
          <Stack direction="row" spacing={2}>
            <Button
              startIcon={<AddIcon />}
              variant="text"
              onClick={() => setOpenAddDialog(true)}
            >
              Add
            </Button>
            <Button
              startIcon={<DeleteIcon />}
              variant="text"
              onClick={() => setOpenRemoveDialog(true)}
              disabled={isEmpty || selected.length === 0}
            >
              Remove
            </Button>
          </Stack>
        }
      />
      {!isEmpty && (
        <DataGrid
          rows={chargers}
          columns={columns}
          {...standardProps}
          pageSize={20}
          sx={{
            ".MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          checkboxSelection
          disableSelectionOnClick={false}
          onSelectionModelChange={(selectionModel, details) =>
            setSelected(selectionModel as string[])
          }
          selectionModel={selected}
          loading={loading}
        />
      )}
      {openAddDialog && (
        <AddChargerDialog
          open={true}
          handleClose={() => setOpenAddDialog(false)}
          beacon={beacon}
        />
      )}
      {openRemoveDialog && (
        <RemoveChargerDialog
          open={true}
          handleClose={() => setOpenRemoveDialog(false)}
          beacon={beacon}
          selected={chargers.filter((c) => selected.includes(c.id))}
        />
      )}
    </Card>
  );
}

export default ChargerList;
