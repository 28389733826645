import { Add as AddIcon, Replay as ReplayIcon } from "@mui/icons-material";
import { Button, Chip, Stack, Typography } from "@mui/material";
import { deleteField, serverTimestamp, updateDoc } from "firebase/firestore";
import React, { useCallback, useMemo, useState } from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { InfoChip } from "../../types/chip.db";
import type { Network } from "../../types/network.db";
import type { Station } from "../../types/station.db";

import EditChipDialog from "./EditChipDialog";
import EditOverviewDialog from "./EditOverviewDialog";

function OverviewCard({
  station,
  network,
}: {
  station: WithDocRef<Station>;
  network: WithDocRef<Network>;
}): JSX.Element {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openChipDialog, setOpenChipDialog] = useState(false);
  const chips =
    station.section_activate?.chips ?? network.section_activate?.chips ?? [];

  const deleteChip = useCallback(
    async (chip: InfoChip) => {
      const chips =
        station.section_activate?.chips ??
        network.section_activate?.chips ??
        [];
      const filtered = chips.filter(
        (value) => !(value.icon === chip.icon && value.label === chip.label)
      );

      await updateDoc(station.docRef, {
        "section_activate.chips": filtered,
        "timestamps.updated_at": serverTimestamp(),
      });
    },
    [station, network]
  );

  const resetChips = useCallback(async () => {
    await updateDoc(station.docRef, {
      "section_activate.chips": deleteField(),
      "timestamps.updated_at": serverTimestamp(),
    });
  }, [station]);

  const textActivate =
    (station.section_activate
      ? station.section_activate.description
      : station.text_activate) ||
    (network.section_activate
      ? network.section_activate.description
      : network.text_activate);
  const textPricing = station.text_pricing ?? network.text_pricing;
  const textHours = station.text_hours ?? network.text_hours;

  return (
    <>
      <PartitionedCard
        title={"Overview"}
        action={
          <Button
            onClick={() => {
              setOpenEditDialog(true);
            }}
          >
            Edit
          </Button>
        }
      >
        <PartitionedCardItem
          title={"Activate"}
          caption={
            !(station.section_activate?.description || station.text_activate)
              ? "From Network"
              : ""
          }
        >
          <Stack spacing={6}>
            <Typography whiteSpace={"pre-wrap"}>
              {textActivate ?? "-"}
            </Typography>

            <Stack spacing={2} alignItems={"flex-start"}>
              {chips.map((chip) => (
                <Chip
                  key={chip.label}
                  variant={"filled"}
                  label={chip.label}
                  onDelete={() => deleteChip(chip)}
                />
              ))}

              <Stack direction={"row"} spacing={2}>
                {chips.length < 5 && (
                  <Chip
                    variant={"outlined"}
                    label={"Add Chip"}
                    icon={<AddIcon />}
                    onClick={() => {
                      setOpenChipDialog(true);
                    }}
                  />
                )}
                {station.section_activate?.chips && (
                  <Chip
                    variant={"outlined"}
                    label={"Reset Chips"}
                    icon={<ReplayIcon />}
                    onClick={() => resetChips()}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </PartitionedCardItem>
        <PartitionedCardItem
          title={"Pricing"}
          caption={!station.text_pricing ? "From Network" : ""}
        >
          <Typography whiteSpace={"pre-wrap"}>{textPricing ?? "-"}</Typography>
        </PartitionedCardItem>
        <PartitionedCardItem
          title={"Hours"}
          caption={!station.text_hours ? "From Network" : ""}
        >
          <Typography whiteSpace={"pre-wrap"}>{textHours ?? "-"}</Typography>
        </PartitionedCardItem>
      </PartitionedCard>
      {openEditDialog && (
        <EditOverviewDialog
          open={true}
          handleClose={() => setOpenEditDialog(false)}
          station={station}
          network={network}
        />
      )}
      {openChipDialog && (
        <EditChipDialog
          open={true}
          handleClose={() => setOpenChipDialog(false)}
          station={station}
          network={network}
        />
      )}
    </>
  );
}

export default OverviewCard;
