import { Add as AddIcon } from "@mui/icons-material";
import { Button, Chip, Grid, useMediaQuery, useTheme } from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { orderBy } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { DataGridCard, standardProps } from "../components/data-grid";
import * as columnTypes from "../components/data-grid/columnTypes";
import QuickSearch from "../components/data-grid/QuickSearch";
import Loader from "../components/Loader";
import { PageHeader } from "../components/PageHeader";
import useCollection from "../hooks/useCollection";
import useNavigateOnRowClick from "../hooks/useNavigateOnRowClick";
import type { Incentive } from "../types/incentive.db";
import type { SavedState } from "../types/navigate";

import EditIncentiveDialog from "./incentives/EditIncentiveDialog";

const columns: GridColumns<Incentive> = [
  {
    field: "grantor",
    headerName: "Grantor",
    ...columnTypes.flexDefault,
  },
  {
    field: "name",
    headerName: "Incentive",
    ...columnTypes.flexPrimary,
    minWidth: 250,
  },
  {
    field: "vehicles",
    headerName: "Vehicles",
    ...columnTypes.flexNumber,
    valueGetter: (params) =>
      Object.keys(params.row.criteria?.vehicles ?? {}).length || "",
  },
  {
    field: "active",
    headerName: "Status",
    ...columnTypes.flexBoolean,
    renderCell: (params) => (
      <Chip
        label={params.value ? "Active" : "Not Active"}
        variant="outlined"
        color={params.value ? "success" : "info"}
        size="small"
      />
    ),
  },
  {
    field: "updated_at",
    headerName: "Last updated",
    ...columnTypes.updatedAt,
  },
  {
    field: "created_at",
    headerName: "First created",
    ...columnTypes.createdAt,
  },
];

function Incentives(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [savedState, setSavedState] = useState(
    (location?.state as SavedState) || {}
  );
  const [page, setPage] = useState(savedState.page || 0);
  const [items, loading] = useCollection<Incentive>(
    "vehicle-incentives",
    orderBy("grantor"),
    orderBy("name")
  );
  const [filtered, setFiltered] = useState<Incentive[]>(items);
  const [search, setSearch] = useState<string>(savedState.search || "");
  const [debounced] = useDebounce(search, 200);
  const [open, setOpen] = useState(false);
  const handleRowClick = useNavigateOnRowClick();

  useEffect(() => {
    navigate("", { replace: true, state: savedState });
  }, [navigate, savedState]);

  // Update saved state
  useEffect(() => {
    setSavedState({ page: page, search: search });
  }, [page, search]);

  // Filter items on quick search input
  useEffect(() => {
    if (debounced) {
      const regex = new RegExp(debounced, "i");
      setFiltered(
        items.filter(
          (item) => regex.test(item.grantor) || regex.test(item.name)
        )
      );
    } else {
      setFiltered(items);
    }
  }, [items, debounced]);

  return (
    <React.Fragment>
      <PageHeader
        title="Incentives"
        action={
          <>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              fullWidth
              onClick={() => setOpen(true)}
            >
              Add
            </Button>
          </>
        }
      />

      {loading ? (
        <Loader />
      ) : (
        <>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <QuickSearch value={search} onChange={setSearch} />
              <DataGridCard>
                <DataGrid
                  rows={filtered}
                  columns={columns}
                  {...standardProps}
                  sx={{
                    ".MuiDataGrid-row:hover": {
                      cursor: "pointer",
                    },
                  }}
                  page={page}
                  onPageChange={setPage}
                  onRowClick={handleRowClick}
                />
              </DataGridCard>
            </Grid>
          </Grid>

          <EditIncentiveDialog
            open={open}
            handleClose={() => setOpen(false)}
            fullScreen={fullScreen}
          />
        </>
      )}
    </React.Fragment>
  );
}

export default Incentives;
