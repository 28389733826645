import { Typography } from "@mui/material";
import React from "react";

import {
  PartitionedCard,
  PartitionedCardItem,
} from "../../components/PartitionedCard";
import type { WithDocRef } from "../../hooks/useQuery";
import type { User } from "../../types/user.db";

function QuestionnaireCard({ user }: { user: WithDocRef<User> }): JSX.Element {
  if (!user.questionnaire) return <></>;
  return (
    <PartitionedCard title={"Questionnaire"} spacing={4}>
      <PartitionedCardItem title={"Persona"}>
        <Typography>{user.persona ?? "None"}</Typography>
      </PartitionedCardItem>

      {user.questionnaire?.version && (
        <PartitionedCardItem title={"Version"}>
          <Typography>{user.questionnaire?.version}</Typography>
        </PartitionedCardItem>
      )}
    </PartitionedCard>
  );
}

export default QuestionnaireCard;
