import { Card, CardHeader, Chip } from "@mui/material";
import type {
  GridColumns,
  GridEventListener,
  GridRowParams,
} from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { standardProps } from "../../components/data-grid";
import * as columnTypes from "../../components/data-grid/columnTypes";
import { StandardPagination } from "../../components/data-grid/pagination";
import useCollection from "../../hooks/useCollection";
import type { Incentive } from "../../types/incentive.db";
import type { Vehicle } from "../../types/vehicle.db";

function IncentivesList({ vehicle }: { vehicle: Vehicle }): JSX.Element {
  const [incentives, loading] = useCollection<Incentive>("vehicle-incentives");
  const filtered = useMemo(
    () =>
      incentives
        .filter(
          (incentive) => incentive.criteria.vehicles?.[vehicle.id] !== undefined
        )
        .sort(
          (a, b) =>
            a.grantor.localeCompare(b.grantor) || a.name.localeCompare(b.name)
        ),
    [incentives, vehicle.id]
  );

  const navigate = useNavigate();

  const columns = useMemo<GridColumns<Incentive>>(
    () => [
      {
        field: "grantor",
        headerName: "Grantor",
        ...columnTypes.flexDefault,
      },
      {
        field: "name",
        headerName: "Incentive",
        ...columnTypes.flexPrimary,
        minWidth: 250,
      },
      {
        field: "vehicle_amount",
        headerName: "Amount",
        ...columnTypes.currency,
        valueGetter: (params) =>
          params.row.criteria?.vehicles?.[vehicle.id]?.amount ??
          params.row.amount,
      },
      {
        field: "active",
        headerName: "Status",
        ...columnTypes.flexBoolean,
        renderCell: (params) => (
          <Chip
            label={params.value ? "Active" : "Not Active"}
            variant="outlined"
            color={params.value ? "success" : "info"}
            size="small"
          />
        ),
      },
      {
        field: "updated_at",
        headerName: "Last updated",
        ...columnTypes.updatedAt,
      },
    ],
    [vehicle]
  );

  const handleRowClick = useCallback<GridEventListener<"rowClick">>(
    (params: GridRowParams<Incentive>) => {
      navigate(`/incentives/${params.id}`);
    },
    [navigate]
  );

  return (
    <Card>
      <CardHeader title="Incentives" />
      <DataGrid
        rows={filtered}
        columns={columns}
        {...standardProps}
        pageSize={20}
        sx={{
          ".MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
        onRowClick={handleRowClick}
        loading={loading}
      />
    </Card>
  );
}

export default IncentivesList;
