import { Autocomplete, Stack, TextField } from "@mui/material";
import { updateDoc } from "firebase/firestore";
import { geohashForLocation } from "geofire-common";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import FormDialog from "../../components/dialogs/FormDialog";
import {
  validateCoordinates,
  validateCountryCode,
  validatePostalCode,
} from "../../forms/validation";
import type { WithDocRef } from "../../hooks/useQuery";
import type { Station } from "../../types/station.db";
import { updatedAt } from "../../util/firebase";
import { parseLatLng } from "../../util/stdlib";

function EditLocationDialog({
  open,
  handleClose,
  station,
}: {
  open: boolean;
  handleClose: () => void;
  station: WithDocRef<Station>;
}): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    reValidateMode: "onBlur",
  });

  // Initialize the fields
  useEffect(() => {
    if (open) {
      reset({
        address: station.address,
        city: station.city,
        state: station.state,
        postal_code: station.postal_code,
        country: station.country,
        coordinates: [station.latitude, station.longitude].join(","),
        time_zone: station.time_zone || null,
      });
    }
  }, [open, reset, station]);

  const onSubmit = async (data: any) => {
    try {
      const [latitude, longitude] = parseLatLng(data.coordinates);

      await updateDoc(station.docRef, {
        address: data.address.trim(),
        city: data.city.trim(),
        state: data.state.trim(),
        postal_code: data.postal_code.trim(),
        country: data.country.trim(),
        latitude,
        longitude,
        geohash: geohashForLocation([latitude, longitude]),
        time_zone: data.time_zone || null,
        ...updatedAt,
      });
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar("Failed to update station", {
        variant: "error",
      });
    } finally {
      handleClose();
    }
  };

  return (
    <FormDialog
      title={"Edit Location"}
      open={open}
      onCancel={handleClose}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
    >
      <Stack mt={6} spacing={6}>
        <Controller
          name={"address"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Address"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
        <Controller
          name={"city"}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="City"
              helperText={error?.message}
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
        <Stack direction={"row"} spacing={2}>
          <Controller
            name={"state"}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="State"
                helperText={error?.message}
                error={Boolean(error)}
                fullWidth
              />
            )}
          />
          <Controller
            name={"postal_code"}
            control={control}
            rules={{ required: true, validate: validatePostalCode }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Postal Code"
                placeholder={"97227"}
                helperText={error?.message}
                error={Boolean(error)}
                fullWidth
              />
            )}
          />
          <Controller
            name={"country"}
            control={control}
            rules={{ required: true, validate: validateCountryCode }}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                label="Country"
                helperText={error?.message}
                error={Boolean(error)}
                fullWidth
              />
            )}
          />
        </Stack>

        <Controller
          name={"coordinates"}
          control={control}
          rules={{ required: true, validate: validateCoordinates }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              label="Coordinates"
              helperText={
                error?.message ||
                "A latitude and longitude pair separated by a comma"
              }
              error={Boolean(error)}
              fullWidth
            />
          )}
        />
        <Controller
          name={"time_zone"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              autoHighlight
              autoSelect
              options={(Intl as any).supportedValuesOf("timeZone")}
              onChange={(event, newValue) => field.onChange(newValue)}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Time Zone"
                  helperText={error?.message}
                  error={Boolean(error)}
                />
              )}
            />
          )}
        />
      </Stack>
    </FormDialog>
  );
}

export default EditLocationDialog;
