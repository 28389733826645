import {
  Card,
  CardHeader,
  Rating,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import type { GridColumns } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid";
import {
  collection,
  doc,
  getDoc,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useMemo, useReducer } from "react";

import { standardProps } from "../../components/data-grid";
import * as columnTypes from "../../components/data-grid/columnTypes";
import useFirebase from "../../hooks/useFirebase";
import useNavigateOnRowClick from "../../hooks/useNavigateOnRowClick";
import type { WithDocRef } from "../../hooks/useQuery";
import useQuery from "../../hooks/useQuery";
import type { StationReview } from "../../types/station-review.db";
import type { Station } from "../../types/station.db";
import type { User } from "../../types/user.db";
import { formatStationAddress } from "../../util/stdlib";

const listReducer = (
  state: Station[],
  action: { type: "add"; newItem: Station }
): Station[] => {
  switch (action.type) {
    case "add":
      return [...state, action.newItem];
  }
  throw Error("Unknown action: " + action.type);
};

function ReviewsCard({ user }: { user: WithDocRef<User> }): JSX.Element {
  const { database: db } = useFirebase();
  const [items, loading] = useQuery<StationReview>(
    query(
      collection(db, "station-reviews"),
      where("user_id", "==", user.id),
      orderBy("timestamps.created_at", "desc"),
      limit(100) // Temporary until Firestore pagination is implemented
    )
  );
  const [stations, dispatch] = useReducer(listReducer, []);
  const handleRowClick = useNavigateOnRowClick("/reviews/:id");

  // Load stations
  useEffect(() => {
    const unique = [...new Set(items.map((review) => review.station_id))];
    for (const stationId of unique) {
      getDoc(doc(db, "stations", stationId)).then((doc) => {
        if (doc.exists()) {
          const station = { id: doc.id, ...doc.data() } as Station;
          dispatch({ type: "add", newItem: station });
        }
      });
    }
  }, [db, items]);

  const columns = useMemo<GridColumns<StationReview>>(
    () => [
      {
        field: "station",
        headerName: "Station",
        ...columnTypes.flexPrimary,
        minWidth: 200,
        valueGetter: (params) =>
          stations.find((v) => v.id === params.row.station_id),
        renderCell: (params) =>
          params.value && (
            <Stack>
              <Typography>{params.value.name}</Typography>
              <Typography variant={"caption"} color={"text.secondary"}>
                {formatStationAddress(params.value)}
              </Typography>
            </Stack>
          ),
      },
      {
        field: "title",
        headerName: "Title",
        ...columnTypes.flexDefault,
        minWidth: 250,
      },
      {
        field: "rating",
        headerName: "Rating",
        ...columnTypes.flexNumber,
        renderCell: (params) => (
          <Rating value={params.value} size="small" readOnly />
        ),
      },
      {
        field: "comment",
        headerName: "Comment",
        ...columnTypes.flexPrimary,
        minWidth: 350,
        renderCell: (params) => (
          <Tooltip title={params.value} enterDelay={500}>
            <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {params.value}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: "created_at",
        headerName: "First created",
        ...columnTypes.createdAt,
      },
    ],
    [stations]
  );

  const numberFormatter = Intl.NumberFormat("en-US");

  return (
    <Card>
      <CardHeader
        title={
          (user.review_count ?? 0) > 1
            ? `Reviews (${numberFormatter.format(user.review_count ?? 0)})`
            : "Reviews"
        }
      />
      {items.length > 0 && (
        <DataGrid
          rows={items}
          columns={columns}
          {...standardProps}
          pageSize={10}
          sx={{
            ".MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
          }}
          onRowClick={handleRowClick}
          loading={loading}
        />
      )}
    </Card>
  );
}

export default ReviewsCard;
