import { Chip } from "@mui/material";
import React from "react";

import type { UserSubscription } from "../../types/user.db";

function StatusChip({
  subscription,
}: {
  subscription: UserSubscription;
}): JSX.Element {
  if (subscription.expired) {
    return (
      <Chip
        label={"Expired"}
        variant={"outlined"}
        color={"error"}
        size={"small"}
      />
    );
  } else if (!subscription.auto_renew_enabled) {
    return (
      <Chip
        label={"Not Renewing"}
        variant={"outlined"}
        color={"warning"}
        size={"small"}
      />
    );
  } else {
    return (
      <Chip
        label={"Active"}
        variant={"outlined"}
        color={"success"}
        size={"small"}
      />
    );
  }
}

export default StatusChip;
