import type { QueryConstraint } from "firebase/firestore";
import {
  collection as firestoreCollection,
  getFirestore,
  query,
} from "firebase/firestore";

import type { WithDocRef } from "./useQuery";
import useQuery from "./useQuery";

type CollectionResult<T> = [data: WithDocRef<T>[], loading: boolean];

/** Attaches a listener for QuerySnapshot events. */
function useCollection<T extends { id: string }>(
  collection: string,
  ...queryConstraints: QueryConstraint[]
): CollectionResult<T> {
  const [data, loading] = useQuery<T>(
    query(firestoreCollection(getFirestore(), collection), ...queryConstraints)
  );
  return [data, loading];
}

export default useCollection;
