import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { range } from "../../util/stdlib";

/**
 * Process a comma-separated list of zipcodes and return the result as a string array.
 *   - Whitespace is trimmed.
 *   - ZIP+4 is converted to the base zipcode.
 *   - Ranges are expanded.
 *   - Blank and duplicate results are removed.
 *
 * Throws an Error if an invalid entry is found.
 */
const process = (data: string, separator = ","): string[] => {
  const split = data.split(separator);

  const zipPlus4 = /^\d{5}-\d{4}$/;
  const zipRange1 = /^\d{3}-\d{3}$/;
  const zipRange2 = /^\d{5}-\d{5}$/;
  const zipOrPrefix = /^\d{3,5}$/;

  const processed = split
    // Trim whitespace
    .map((entry: string) => entry.trim())
    // Trim ZIP+4
    .map((entry: string) => {
      if (entry.match(zipPlus4)) {
        return entry.substring(0, 5);
      }
      return entry;
    })
    // Expand ranges
    .flatMap((entry: string) => {
      if (entry.match(zipRange1) || entry.match(zipRange2)) {
        const [start, end] = entry.split("-", 2);
        return range(parseInt(start), parseInt(end)).map((value) =>
          value.toString()
        );
      }
      return entry;
    })
    // Remove blank entries
    .filter((entry: string) => !!entry);

  // Remove duplicates
  const unique = [...new Set(processed)] as string[];

  // Validate
  unique.forEach((entry: string) => {
    if (!entry.match(zipOrPrefix)) {
      throw new Error(`"${entry || "blank"}" is not a valid ZIP`);
    }
  });

  return unique.sort(
    (a, b) => a.length - b.length && parseInt(a) - parseInt(b)
  );
};

function EditZipcodesDialog({
  open,
  handleClose,
  handleSave,
  fullScreen,
  zipcodes,
}: {
  open: boolean;
  handleClose: () => void;
  handleSave: (zipcodes: string[]) => Promise<void>;
  fullScreen: boolean;
  zipcodes?: string[];
}): JSX.Element {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
    setError,
  } = useForm({
    reValidateMode: "onSubmit",
  });

  // Reset form each time the dialog is presented
  useEffect(() => {
    if (open) {
      reset({ zipcodes: zipcodes?.join(", ") ?? "" });
    }
  }, [open, reset, zipcodes]);

  const onCancel = () => handleClose();
  const onSubmit = async (data: any) => {
    try {
      const unique = process(data.zipcodes);
      await handleSave(unique);
      handleClose();
    } catch (err: any) {
      setError("zipcodes", { type: "custom", message: err.message });
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      fullScreen={fullScreen}
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ padding: "24px 24px 0" }}>Edit zipcodes</DialogTitle>
        <DialogContent>
          <Stack spacing={4} mt={6}>
            <Controller
              name="zipcodes"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Zipcodes"
                  helperText={
                    error?.message ||
                    "A comma separated list of zipcodes or 3-digit zip prefixes. A range can be specified with a hyphen (e.g. 900-961)."
                  }
                  error={Boolean(error)}
                  multiline
                  fullWidth
                />
              )}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button type="submit" disabled={isSubmitting}>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export { EditZipcodesDialog };
